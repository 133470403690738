import React, { useRef, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import './highlight-markdown.scss';
// import Prism from 'prismjs';
import 'prismjs/plugins/line-numbers/prism-line-numbers';
import ClipboardJS from 'clipboard';
interface HighlightedMarkdownProps { 
  children: string;
}

export function HighlightedMarkdown({ children }: HighlightedMarkdownProps) {
  const rootRef = useRef<any>();

  useEffect(() => {
    // Prism.highlightAll();
    rootRef.current?.querySelectorAll('pre').forEach((block: any, index: number) => {
      const wrapperDiv = document.createElement('div');
      const wrapperOuterDiv = document.createElement('div');
      const wrapperCodeDiv = document.createElement('div');
      wrapperDiv.classList.add('simple-code-codePart');
      wrapperOuterDiv.classList.add('simple-code','markdown');
      wrapperCodeDiv.classList.add('sample-code-single-wrapper');
      block.parentNode.insertBefore(wrapperDiv, block);
      wrapperDiv.appendChild(block);
      wrapperDiv.parentNode?.insertBefore(wrapperOuterDiv, wrapperDiv);
      wrapperOuterDiv.appendChild(wrapperDiv);
      block.parentNode.insertBefore(wrapperCodeDiv, block);
      wrapperCodeDiv.appendChild(block);
      const codeElement = block.querySelector('code');
      const codeText = codeElement.textContent || codeElement.innerText;
      const lines = codeText.split('\n');
      const newHtml = '<span aria-hidden="true" class="line-numbers-rows">'+
      lines.map((line: any) => `<span></span>`).join('\n').replace(/\n/g,'')
      +'</span>';
      block.querySelector('code').insertAdjacentHTML('beforeend', newHtml);
      //Optional copy icon
      // const copyDiv = document.createElement('div');
      // copyDiv.classList.add('simple-code-codePart-cpIcon-part');
      // wrapperCodeDiv.parentNode?.insertBefore(copyDiv, wrapperCodeDiv);
      // const spanDiv = document.createElement('span');
      // spanDiv.classList.add('Appkit4-icon','icon-copy-outline','sample-code-copy-icon', `icon-${index}`);
      // copyDiv.appendChild(spanDiv);

      if (!block.classList.contains('sample-code-wrapper', 'language-js', 'line-numbers')) {
        block.classList.add('sample-code-wrapper', 'language-js', 'line-numbers');
      }

      // const codeElement = block?.querySelector('code');
      // if(codeElement) {
        //clipboard
        // let clipboard: any;
        // if (codeElement) {
        //   clipboard = new ClipboardJS(`.sample-code-copy-icon.icon-${index}`, {
        //     text: () => codeElement.textContent,
        //   });
  
        //   clipboard.on('success', function (e: any) {
        //     const icon = rootRef.current?.querySelector(`.sample-code-copy-icon.icon-${index}`);
        //     // Change the CSS variable value
        //     icon.style.setProperty('--copy-code-text', '"Copied"');
        //     e.clearSelection();
        //   });
  
        //   clipboard.on('error', function (e: any) {
        //     console.error('Action:', e.action);
        //     console.error('Trigger:', e.trigger);
        //   });

        //   const icon = rootRef.current?.querySelector(`.sample-code-copy-icon.icon-${index}`);
        //   icon.addEventListener('mouseleave', function() {
        //     icon.style.setProperty('--copy-code-text', '"Copy code"');
        //   });
        // }
      // }
    });
    
  }, [children]);

  return (
    <div ref={rootRef}>
      <Markdown>{children}</Markdown>
    </div>
  );
}