import { ProductExperienceByUser, UserInputType, UserType } from '@models';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import { apiService } from './rxjs.service';

const checkAuthToken = (): Observable<{
  status: number;
}> => {
  return apiService.get(`/user/info/checkAuthToken`);
};

const getUser = (email: string): Observable<{ data: UserType; status: number; message: string }> => {
  return apiService.get(`/user/info?email=${email}`);
};

const getMembership = (): Observable<any> => {
  return apiService.get(`/product/info/getMemberShips`);
};

const createUser = (user: UserInputType): Observable<any> => {
  return apiService.post(`/user/create`, user);
};

const searchUser = (name: string): Observable<any> => {
  return apiService.post(`/user/search`, {
    page: 1,
    size: 10,
    query: name,
    type: 'FULL',
  });
};

const checkProductExperienceByUser = (
  appId: number,
): Observable<{
  experience: ProductExperienceByUser;
}> => {
  return apiService.get(`/product/info/getProductExperienceByUser?appId=${appId}`);
};

const changeEmailNotificationStatus = (email: string, notificationStatus: number): Observable<any> => {
  return apiService.get(`/user/emailNotification?email=${email}&notification=${notificationStatus}`);
};

const checkProductOwnerStatus = (userId: string) => {
  return apiService.get(`/user/info/isProductOwner?userId=${userId}`);
};

const sendAccessCryptoRewards = (email: string) => {
  return apiService.get(`/user/cryptoReward/sendAccessPostBids?email=${email}`);
};

const checkUserRole = (userId: string): Observable<{ role: string; status: number }> => {
  return apiService.get(`/user/info/checkUserRole?userId=${userId}`);
};

const getMember = (productId: number): Observable<any> => {
  return apiService.post(`/product/v2/getMember`, {
    productId,
  });
};

const getSubscription = (email: string): Observable<any> => {
  return apiService.get(`user/subscription/getSubscriptionFlag?user_id=${email}`);
};

const setSubscription = (email: string, subscriptionFlag: string): Observable<any> => {
  return apiService.post(`user/subscription/setSubscriptionFlag?user_id=${email}`, {
    subscriptionFlag
  });
};

export const userService = {
  checkAuthToken,
  getUser,
  getMembership,
  createUser,
  searchUser,
  checkProductExperienceByUser,
  changeEmailNotificationStatus,
  checkProductOwnerStatus,
  sendAccessCryptoRewards,
  checkUserRole,
  getMember,
  getSubscription,
  setSubscription
};
