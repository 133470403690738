import React, { FC, useState, memo } from 'react';
import { IssueFiltersDashboardType, ItemType } from './types';
import './issueFiltersDashboard.scss';
import { CheckboxGroup, Checkbox } from '@appkit4/react-components/checkbox';

const IssueFiltersDashboard: FC<IssueFiltersDashboardType> = memo((props) => {
  const {
    title,
    items = [],
    tabActiveIndex,
    allIssueCnt,
    setFiltersItemsPartData,
    setNarrowScreenSelectedItems,
    setFilter,
    narrowScreenSelectedItems,
    isManualReviewStart,
    searchUsrInput,
    pageNumHandler
  } = props;

  const [rotate, setRotate] = useState(false);
  const cbxRef = React.useRef<any>(null);

  const setIconRotate = () => {
    if (allIssueCnt === 0) return;
    setRotate((pre) => (pre === false ? true : false));
  };

  const getNum = React.useCallback(
    (item: ItemType) => {
      if (allIssueCnt !== 0 && tabActiveIndex === 0 && item.value === 'closeissue') return 0;
      return item.cnt;
    },
    [allIssueCnt, tabActiveIndex],
  );

  // const getNumDisabledStyle = React.useCallback(
  //   (item: ItemType) => {
  //     // if (tabActiveIndex === 1) {
  //     //   if (isManualReviewStart === false) return 'cntDisabled';
  //     // }
  //     // if (allIssueCnt === 0 && narrowScreenSelectedItems.length === 0 && !searchUsrInput) return 'cntDisabled';
  //     // if (allIssueCnt !== 0 && tabActiveIndex === 0 && item.value === 'close') return 'cntDisabled';
  //     return '';
  //   },
  //   [allIssueCnt, tabActiveIndex, narrowScreenSelectedItems],
  // );

  const cbxChange = (checked: boolean, event: React.SyntheticEvent, target: ItemType) => {
    pageNumHandler && pageNumHandler();
    if (checked) {
      setNarrowScreenSelectedItems((prev: ItemType[]) => [...prev, target]);
      setFilter?.((prev: ItemType[]) => [...prev, target]);
    } else {
      setNarrowScreenSelectedItems((prev: ItemType[]) => prev.filter((itm: ItemType) => itm.value !== target.value));
      setFilter?.((prev: ItemType[]) => prev.filter((itm: ItemType) => itm.value !== target.value));
    }

    setFiltersItemsPartData(
      items.map((item: ItemType) => {
        if (item.value === target.value) {
          return { ...item, checked: checked };
        }
        return { ...item, checked: item.checked };
      }),
    );
  };

  const getNoIssueTitlePartStyle = () => {
    // if (tabActiveIndex === 1) {
    //   if (isManualReviewStart === false) return 'noIssueTitlePart';
    // }
    if (allIssueCnt === 0 && narrowScreenSelectedItems.length === 0) {
      return 'noIssueTitlePart';
    }
    return 'titlePart';
  };

  const getCntDisabledStyle = () => {
    // if (tabActiveIndex === 1) {
    //   if (isManualReviewStart === false) return 'cntDisabled';
    // }
    // if (allIssueCnt === 0 && narrowScreenSelectedItems.length === 0 && !searchUsrInput) {
    //   return 'cntDisabled';
    // }
    return '';
  };

  const handleKeydown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      setIconRotate();
    }
  };

  return (
    <div className="issueFiltersDashboardContainer">
      <div className={getNoIssueTitlePartStyle()} onClick={setIconRotate}>
        <span className={getCntDisabledStyle()}>{title}</span>
        <span tabIndex={0} onKeyDown={handleKeydown} 
        // aria-label='Collapsible button'
          className={`Appkit4-icon icon-down-chevron-outline arrowIcon ${rotate ? '' : 'iconRote'} ${getCntDisabledStyle()}`}
        >
          {/* <span className="visually-hidden">Collapsible button</span> */}
          </span>
      </div>
      <div className={`issuesPart ${rotate ? 'toggleSssuesPart' : ''}`}>
        {items.map((item, idx) => (
          <div key={`${idx}${item.name}`} className="issueArea">
            <Checkbox
              checked={item.checked}
              onChange={(checked: boolean, event: React.SyntheticEvent) => cbxChange(checked, event, item)}
              disabled={item.disabled}
              inputRef={cbxRef}
            >
              <span className="nameText">{item.name}</span>
            </Checkbox>
            {/* <span className={`countPart ${getNumDisabledStyle(item)}`}>{getNum(item)}</span> */}
          </div>
        ))}
      </div>
    </div>
  );
});
export default IssueFiltersDashboard;
