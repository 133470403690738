import React, { useEffect, useRef } from 'react';
import { Accordion, AccordionItem, Avatar, Breadcrumb, BreadcrumbItem, Header, HeaderOptionItem, HeaderProps, NavigationProps, Panel } from '@appkit4/react-components';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import './gettingStarted.scss';
import { addSideAnchorClass, formatUrl, removeHighlight } from '@utils/common';

export interface FAQProps {
    headerProps?: HeaderProps;
    navProps?: NavigationProps;
}
export const headerTitle = () => "Appkit";
export const headerContent = () => <HeaderOptionItem iconName="search-outline" label="Search"></HeaderOptionItem>;
export const headerUser = () => (<Avatar label="CD" role="button" disabled={false}></Avatar>);
export const navigationUser = () => <Avatar className="keyboard-focus" label="VR" disabled={false} role="button"></Avatar>;

let anchorArray = [
    {
        value: 'Automated assessment',
        selected: true,
        linkName: 'automatedAssessment'
    },
    {
        value: "Setup your first scan",
        linkName: "setupYourFirstScan"
    },
    {
        value: 'Choose the access requirements',
        selected: true,
        linkName: 'chooseTheAccessRequirements'
    },
    {
        value: 'Review the details and start the scan',
        selected: true,
        linkName: 'reviewTheDetailsAndStartTheScan'
    }
];;
const AutomatedAssessmentPage = React.forwardRef<HTMLElement, FAQProps>((props: FAQProps, ref) => {
    const {
        headerProps,
        navProps
    } = props;
    const navigate = useNavigate();

    const anchorRef = useRef(null);
    const faqRef = useRef(null);
    const clickRef = useRef<boolean>(false);
    const itemListRef = React.useRef<{ name: string, top: number, target: HTMLElement }[]>([]);
    const [anchorList, setAnchorList] = React.useState(anchorArray.map((item, index) => {
        return { ...item, selected: index === 0 ? true : false };
    }));
    const pageContent = React.useRef<HTMLDivElement>(null);
    const [collapsedVal, setCollapsedVal] = React.useState(false);
    const [showAnchors, setShowAnchors] = React.useState(false);
    const [anchorLeft, setAnchorLeft] = React.useState(0);
    const anchorEvent = (fragment?: string) => {
        clickRef.current = true;
        let str = (fragment ? formatUrl(decodeURI(fragment)) : '');
        let indexStr = anchorArray.findIndex(item => formatUrl(item.linkName) === str);
        if (str === '') indexStr = 0;
        removeHighlight(anchorRef, indexStr);
        const elementTop = fragment ? (document.getElementById(str)?.offsetTop || 0) : 0;
        setTimeout(function () {
            window.scrollTo({
                top: elementTop - 64
            });
        }, 0);
        setAnchorList(anchorArray.map((item, index) => {
            if (formatUrl(item.linkName?.toLowerCase()) === str?.toLowerCase())
                return { ...item, selected: true };
            else if (!fragment && index === indexStr)
                return { ...item, selected: true };
            else return { ...item, selected: false }
        }));
        if ((!fragment && indexStr === 0) ||
            (fragment && indexStr === -1)) (anchorRef.current! as HTMLElement).querySelectorAll(`[id^=anchor-]`)[0].classList.add("selected");
        if (indexStr === -1)
            setTimeout(function () {
                window.scrollTo({
                    top: - 64
                });
            }, 0);
        setTimeout(() => {
            clickRef.current = false;
            if(!(anchorRef.current! as HTMLElement)?.querySelector(`#anchor-${fragment}`)?.classList.contains("selected"))
            (anchorRef.current! as HTMLElement)?.querySelector(`#anchor-${fragment}`)?.classList.add("selected");
        }, 300);
    }

    const hashChangeEvent = () => {
        anchorEvent(window.location.hash.slice(1));
    }

    const setAnchorsEvent = () => {
        const pageContentElement = (pageContent.current as HTMLElement);
        setAnchorLeft(pageContentElement.getBoundingClientRect().left + pageContentElement.getBoundingClientRect().width + 32);
    }

    const isInViewport = (element: HTMLElement) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    const scrollEvent = () => {
        if(clickRef.current) return;
        setTimeout(() => {
            const itemList = itemListRef.current;
            let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop) + 28;
            for (let index = 0; index < itemList.length; ++index) {
                const trigger = (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index]?.name.toLowerCase()}]`) as HTMLElement;
                if (itemList[index + 1] && scrollTop >= itemList[index].top - 64 && scrollTop <= itemList[index + 1].top - 64) {
                    addSideAnchorClass(anchorRef, trigger);
                    if (window.innerHeight + window.scrollY >= document.body.offsetHeight)
                        addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index + 1]?.name.toLowerCase()}]`) as HTMLElement);
                    break;
                } else if (index === itemList.length - 1 && scrollTop >= itemList[index].top - 64) {
                    addSideAnchorClass(anchorRef, trigger);
                    break;
                }
            }
        }, 300);
    }

    React.useEffect(() => {
        setTimeout(() => {
            if (pageContent.current) {
                removeHighlight(anchorRef);
                setAnchorsEvent();
                setShowAnchors(true);
            }
            updateItemListRef();
            hashChangeEvent();
        }, 100);
        window.addEventListener('hashchange', hashChangeEvent);
        window.addEventListener('resize', setAnchorsEvent);
        window.addEventListener('scroll', scrollEvent);
        window.addEventListener('keyup', keyupEvent);
        return () => {
            window.removeEventListener('hashchange', hashChangeEvent);
            window.removeEventListener('scroll', scrollEvent);
            window.removeEventListener('resize', setAnchorsEvent);
        }
    }, [])

    const keyupEvent = (event: KeyboardEvent) => {
        if (event.keyCode === 9) {
            const accordionList = (faqRef.current! as HTMLElement).querySelectorAll('.ap-accordion-toggle');
            if(!accordionList) return;
            if (Array.from(accordionList).indexOf(document.activeElement!) === accordionList.length - 1)
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[itemListRef.current.length - 1]?.name.toLowerCase()}]`) as HTMLElement);
            if (document.activeElement?.textContent === 'Getting started')
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[0]?.name.toLowerCase()}]`) as HTMLElement);
        }
    }

    const updateItemListRef = () => {
        setTimeout(() => {
            let intersectItem = (pageContent.current! as HTMLElement).querySelectorAll('.anchor-target');
            let tempList = [];
            for (let i = 0; i < intersectItem.length; ++i) {
                const item = (intersectItem[i] as HTMLElement);
                tempList.push({ name: item.id, top: item.offsetTop, target: item });
            }
            itemListRef.current = tempList;
            setAnchorsEvent();
        }, 300);
    }

    const onAnchorClick = (value?: string) => {
        anchorEvent(value);
    }

    const onClickStart = () => {
        navigate('/v2/getting-started');
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>, value?: string) => {
        const { key } = event;
        if (key === 'Enter') {
            onAnchorClick?.(value)
        }
    }

    return (
        <div className="ap-page-get-started">
            <div className="pattern-content faq-content">
                <div className={classNames("ap-page has-anchor", {
                    'faq-page-collapse': collapsedVal
                })}>
                    {/* <PageAnchors showAnchors={showAnchors} className="anchor-list" ref={anchorRef} anchorList={anchorList} onAnchorClick={onAnchorClick}></PageAnchors> */}

      <div className="ap-page-content has-anchor" id="pageContent" ref={pageContent}>
      <Breadcrumb>
                            <BreadcrumbItem>
                                <span onClick={onClickStart} tabIndex={0}>Getting started</span>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <span>Automated assessment</span>
                            </BreadcrumbItem>
                        </Breadcrumb>
        <div className="ap-component-wrapper no-top-bottom">
          <div className="ap-component-name ap-mt-spacing-7 anchor-target" id="automatedassessment">
          Automated assessment
          </div>
          <div className="ap-page-section-desc">
          The automated assessment is a portion of the accessibility assessment process that is often completed before the manual assessment that uses a tool to scan the application’s code to identify code-level accessibility issues and provides a detailed report on each issue. Automated assessments account for an average of 57% of WCAG issues and may vary based on the design of your application. 
          </div>
          <div className="ap-component-name ap-mt-spacing-7 anchor-target" id="setupyourfirstscan">Setup your first scan</div>
            <Panel bordered={true} className='ap-mt-spacing-6'>
                <div className="ap-panel-content step-content">
                    <ul className='no-space-list'>
                        <li>1. Go to the <span className="text-bold">Dashboard</span> page and select <span className="text-bold">Set up automated scan</span>.</li>

                        <li>2. Enter the URL(s) of the page(s) you want to scan.</li>

                        <li>3. Click on <span className="text-bold">Add URL</span>.</li>

                        <li>4. Optionally, add a page title for each link.</li>

                        <li>5. Click on <span className="text-bold">Next</span> to proceed.</li>
                    </ul>
                </div>
            </Panel>
            <div className="ap-component-name ap-mt-spacing-7 anchor-target" id="choosetheaccessrequirements">Choose the access requirements</div>
            <Panel bordered={true} className='ap-mt-spacing-6'>
                <div className="ap-panel-content step-content">
                    <ul className='no-space-list'>
                        <li>1. Select between password protected access or unrestricted access.</li>
                        <ul>
                            <li>a. For <span className="text-bold">password protected access</span>, users must log in or sign up to access the application.</li>
                            <li className="point-b">b. For <span className="text-bold">unrestricted access</span>, anyone can access the application without logging in or signing up.</li> 
                        </ul>
                        <li>2. Click on <span className="text-bold">Next</span>.</li>
                    </ul>
                </div>
            </Panel>
            <div className="ap-component-name ap-mt-spacing-7 anchor-target" id="reviewthedetailsandstartthescan">Review the details and start the scan</div>
            <Panel bordered={true} className='ap-mt-spacing-6'>
                <div className="ap-panel-content step-content">
                    <ul className='no-space-list'>
                        <li>1. Review the details presented.</li>

                        <li>2. Click on <span className="text-bold">Start scan</span> to begin the scanning process.</li>

                        <div className="ap-page-section-desc ap-mt-spacing-3 notification-panel">
                            <span className="Appkit4-icon icon-information-outline"></span>
                            <div>The scanning duration depends on the number of pages being scanned, with each page taking up to 10 seconds. </div>
                        </div>
                    </ul>
                </div>
            </Panel>
            <div className="ap-page-section-desc ap-mt-spacing-4 ap-mb-spacing-5">
            The scan is completed when the <span className="text-bold">Dashboard</span> page loads and a percentage appears under the <span className="text-bold">WCAG 2.2 conformance</span> section. 
            </div>
        </div>
      </div>

    <nav ref={anchorRef} className={classNames('ap-pattern-page-anchor', "anchor-list")} style={{ left: `${anchorLeft}px` }}>
        <ul>
            {
                anchorList?.map(
                    (anchor: any, index: number) => {
                        const hrefValue = window.location.href.indexOf("#") > -1 ? window.location.href.substring(0, window.location.href.indexOf("#")) : window.location.href;
                        const hrefProp = true ? { href: encodeURI(hrefValue + '#' + formatUrl(anchor.linkName!)) } : null;
                        return <li key={index} onClick={(event) => onAnchorClick?.(anchor.linkName)} onKeyDown={(event) => onKeyDown(event, anchor.linkName)}>
                            <a tabIndex={0} role="link" aria-current={anchor.selected ? 'page' : undefined} {...hrefProp} id={'anchor-' + formatUrl(anchor.linkName!)}
                                className={classNames("anchor-trigger", { 'selected': anchor.selected })}>
                                {anchor.externalLink && <span className={anchor.externalLinkIcon || "Appkit4-icon icon-open-in-new-outline"}></span>}
                                {anchor.value}
                            </a>
                        </li>
                    }
                )
            }
        </ul>
    </nav>
                </div>
            </div>
        </div>
    )
})

export default AutomatedAssessmentPage