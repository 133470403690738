import React, { useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import './index.scss';
import CommonModal from '@components/modals/CommonModal';
import {
  Accordion,
  AccordionItem,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Input,
  Modal,
  Radio,
  RadioGroup,
  Search,
  TextArea,
  toaster,
  Tooltip,
  Upload,
  Notification,
  Table,
  Column,
  Panel,
  DropdownButton,
  Tabs,
  Tab,
  Tag,
  Checkbox,
  Drawer,
  Select
} from '@appkit4/react-components';
import { issueService } from '@services/issue.service';
import { switchMap, take } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { productService } from '@services';
import { axios } from '@services/axios.service';
import _, { isEqual, set, throttle } from 'lodash';
import NoResultsSvg from '@assets/icons/noSearchResults.svg';
import LoadingComponent from '@components/Loading';
import { useLocation, useNavigate } from 'react-router-dom';
import { getProductInfoCookie, safelyParseJSON, urlValidator } from '../../utils';
import { EntryContext } from '@layout/Entry';
import classNames from 'classnames';
import { NoResultsPage } from '@components/Home';
import { RadioValueType } from '@appkit4/react-components/esm/radio/Radio';
import { SectionListPart } from './SectionListPart';
import FooterPage from '@components/Footer';
import { DeleteIssueConfirmation } from './DeleteIssueConfirmation';

const Manualpage = React.forwardRef<any, any>(({ forShowingEditModal }, ref) => {
  const location = useLocation();
  const [selectedId, setSelectedId] = React.useState(0);
  const [activeKeys, setActiveKeys] = React.useState<string[]>([]);
  const [showOpenedIssue, setShowOpenedIssue] = useState(true);
  const [showCreateIssueModal, setShowCreateIssueModal] = useState(false);
  const isEditRef = useRef<boolean>(false);
  const isGuidelineRef = useRef<boolean>(false);
  const radioValueRef = useRef('');
  const radioValuePreRef = useRef('');
  const radioItemRef = useRef<any>();
  const isTriggerByAddIssue = useRef(true);
  const [originalIssueData, setOriginalIssueData] = useState<any[]>([]);
  const [sectionNameList, setSectionNameListData] = useState<any[]>([]);
  const [sectionIssuesCompletedList, setSectionIssuesCompletedList] = useState<any[]>([]);
  const [issueListData, setIssueListData] = useState<any[]>([]);
  const [issueData, setIssueData] = useState<any[]>([]);
  const [auditOpenIssueListData, setAuditOpenIssueListData] = useState<any[]>([]);
  const [auditResolvedIssueListData, setAuditResolvedIssueListData] = useState<any[]>([]);
  const [showIssueListContent, setShowIssueListContent] = useState(false);
  const [issueSummary, setIssueSummary] = useState('');
  const [snippetValue, setSnippetValue] = useState('');
  const [issueDescValue, setIssueDescValue] = useState('');
  const [urlSearchListData, setUrlSearchListData] = useState<any[]>([]);
  const [userSelectedData, setUserSelectedData] = useState<any>();
  const [userSelectedUrlValue, setUserSelectedUrlValue] = useState<any>();
  const [urlAddedListData, setUrlAddedListData] = useState<any[]>([]);
  const [editIssueStatus, seteditIssueStatus] = useState(false);
  const [currentEditIssue, setCurrentEditIssue] = useState<any>();
  const [selectedType, setSelectedType] = useState('categories');
  const [uploadFileList, setUploadFileList] = useState<any[]>([]);
  const [uploadImageValue, setUploadImageValue] = useState('');
  const [showUploadErrorMsg, setShowUploadErrorMsg] = useState(false);
  const [modalMappedIssue, setModalMappedIssue] = useState<any>();
  const [level1YesClicked, setLevel1YesClicked] = useState<any>(false);
  const [level1NoClicked, setLevel1NoClicked] = useState<any>(false);
  const [doesNotApplyClicked, setDoesNotApplyClicked] = useState<any>(false);
  const [level2YesClicked, setLevel2YesClicked] = useState<any>(false);
  const [level2NoClicked, setLevel2NoClicked] = useState<any>(false);
  const [level2IsGobalClicked, setLevel2IsGlobalClicked] = useState<any>(false);
  const [showAllLoading, setShowAllLoading] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [showLoadingSection, setShowLoadingSection] = useState(false);
  const [summaryList, setSummaryList] = useState<any[]>([]);
  const [savePageIssuesBtnLoading, setSavePageIssuesBtnLoading] = useState(false);
  const [showIssueCategoryLoading, setShowIssueCategoryLoading] = useState(false);
  const [activeIndex, setactiveIndex] = React.useState(0);
  const [hasExecuted, setHasExecuted] = useState(false);
  const [uploadMessage, setUploadMessage] = useState('');
  const openIssueCount = useRef(0);
  const countNotApplicable = useRef(0);
  const manulaDatatStatus = useRef<any>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const btnRef = useRef<any>(null);
  const snippetCreateRef = useRef<any>(null);
  const manualPageContainerRef = useRef<any>(null);
  const shouldCallRef = useRef(false);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState<Array<string>>([]);
  const [sectionName, setSectionName] = useState('');
  const [sectionDescription, setSectionDescription] = useState('');
  const [tags, setTags] = React.useState<string[]>([]);
  const [guidelineList, setGuidelineList] = useState<any[]>([]);
  const [issueMode, setIssueMode] = useState('global');
  const [resultFilterBySeverity, setResultFilterBySeverity] = useState<any>(null);
  const [visibleConfirmation, setVisibleConfirmation] = useState(false);
  const [visibleDeleteConfirmation, setVisibleDeleteConfirmation] = useState(false);
  const [visibleResetConfirmation, setVisibleResetConfirmation] = useState(false);
  const [auditItemCurrent, setAuditItemCurrent] = useState<any>();
  const [notApplicableAll, setNotApplicableAll] = useState(false);
  const [triggerSource, setTriggerSource] = useState<'checkbox' | 'radio'>('radio');
  const [reopenModalVisible, setReopenModalVisible] = useState(false);
  const [reopenModalCommentVal, setReopenModalCommentVal] = useState('');
  const [openOrCloseBtnLoading, setOpenOrCloseBtnLoading] = useState(false);
  const [openUrl, setOpenUrl] = useState('');
  const [uniqueKey, setUniqueKey] = useState(0);
  const hasMounted = useRef(false);
  const [filterList, setFilterList] = useState<any>([
    { id: "0", filterLevel: "Critical", count: 0 },
    { id: "1", filterLevel: "Serious", count: 0 },
    { id: "2", filterLevel: "Moderate", count: 0 },
    { id: "3", filterLevel: "Minor", count: 0 }
  ]);
  const [childAccoringActiveKeys, setChildAccoringActiveKeys] = React.useState<string[]>([]);
  const v2CreateOrUpdateManualRef = useRef<Subscription>();
  const updateManualIssueStatusRef = useRef<Subscription>();
  const getAuditItemIssueListRef = useRef<Subscription>();
  const updateAduitItemStatusRef = useRef<Subscription>();
  const v2SetManualStatusRef = useRef<any>();
  const orginalFilterRef = useRef<any>();
  const anchorRef = useRef<HTMLAnchorElement>(null);
  const navigate = useNavigate();
  const productId = (getProductInfoCookie() && safelyParseJSON(getProductInfoCookie()!)?.id) || sessionStorage.getItem('productId');
  const getDropDownContent = (isClose = false): any => {
    return [
      {
        value: 'mcplink',
        label: 'Copy link',
        iconName: 'icon-link-outline',
      },
      {
        value: 'editissue',
        label: 'Edit issue',
        iconName: 'icon-edit-outline'
      },
      {
        value: isClose ? 'reopenissue' : 'closeissue',
        label: isClose ? 'Reopen issue' : 'Close issue',
        iconName: isClose ? 'icon-refresh-outline' : 'icon-circle-checkmark-outline'
      },
      {
        value: 'deleteissue',
        label: 'Delete issue',
        iconName: 'icon-delete-outline'
      }
    ];
  };
  const onReopenIssueModalTextChange = (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReopenModalCommentVal(value);
  };
  const transformIssueData = (originalIssueData: any[]) => {
    let guidelineArr: any[] = [];
    let guidelines: any[] = [];
    let i = 0;
    originalIssueData?.map((item: any, idx: number) => {
      guidelines.push(item?.category);
      item?.guidelines?.map((guideline: any, idNumber: number) => {
        let tempSection = { id: `${i}`, category: item.category, ...guideline };
        guidelineArr.push(tempSection);
        i++;
      })
    });
    setGuidelineList(guidelines);
    return guidelineArr;
  }
  const currentSectionData = (sectionName: any, originalIssueData: any[]) => {
    if(isGuidelineRef.current) {
      if(originalIssueData?.[0]?.guidelines) {
        originalIssueData = transformIssueData(originalIssueData);
      } 
    }
    return originalIssueData?.find((item: any) => {
    if (item?.sectionName?.toLowerCase() === sectionName?.toLowerCase()) {
      return item;
    }
  })};
  
  const updateFilterCount = (sectionName: string, originalIssueData: any) => {
    const resultDetail = questionnaireItemsHandler(currentSectionData(sectionName, originalIssueData)?.questionnaireItems);
    setFilterList(filterList.map((item: any) => {
      item.count = resultDetail.filter((dataItem: any) => dataItem.impact === item.filterLevel)?.length||0;
      return item;
    }));
  }
  useEffect(() => {
    if (manualPageContainerRef?.current) {
        const customNodes = manualPageContainerRef?.current?.querySelectorAll('.issue-status-update .custom-node');
        customNodes.forEach((node: any) => {
          node.setAttribute('aria-label', 'Options');
        });
    }
  }, [showOpenedIssue, auditOpenIssueListData, auditResolvedIssueListData]);
  const {
    setSaveIssueClicked
    // setMessageResult
  } = useContext(EntryContext);

  const renderDeleteCell = (item: any) =>  <span className="Appkit4-icon icon-delete-outline" aria-label='delete url' role='button' tabIndex={0} onClick={(event) => currentDelUrl(event, item)}
    onKeyDown={(event) => {
      if (event.key === 'Enter') {
        currentDelUrl(event, item);
      }
    }}
  ></span>;

  const issueActionHandler = () => {
    setOpenOrCloseBtnLoading(true);
    const issueType = auditItemCurrent?.status;
    if (issueType === 1) {
      onChangeToResolved(auditItemCurrent); 
    } else {
      onReopenIssue(auditItemCurrent);
    }
  };

  const reopenModalInfo = useMemo(() => {
    const issue: any = auditItemCurrent;
    return {
      title: issue?.status === 1 ? 'Close issue' : 'Reopen issue',
      body: (
        <div className="modalBody">
          <span className="modalDescription">
            Please provide a brief explanation if necessary for {issue?.status === 1 ? 'closing' : 'reopening'} the
            issue.
          </span>
          <div className="textAreaPart">
            <TextArea
              title="Comment"
              hideTitleOnInput={true}
              value={reopenModalCommentVal}
              onChange={onReopenIssueModalTextChange}
            ></TextArea>
          </div>
        </div>
      ),
      footer: (
        <>
          <Button onClick={() => setReopenModalVisible(false)} kind="secondary">
            Cancel
          </Button>
          <Button
            loading={openOrCloseBtnLoading}
            onClick={issueActionHandler}
          >
            {issue?.status === 1 ? 'Close issue' : 'Reopen issue'}
          </Button>
        </>
      ),
    }
  }, [auditItemCurrent, reopenModalCommentVal, openOrCloseBtnLoading]);

  // useEffect(() => {
  //   const pathName = location.pathname;
  //   const titleEle = document.querySelector('title')!;
  //   if (pathName.includes('/manualIssue')) {
  //     titleEle.innerText = 'PwC Accessibility Checker';
  //     return;
  //   }
  // }, [location]);

  useEffect(() => {
    if (!hasMounted.current) {
      hasMounted.current = true;
      return;
    }
    // if (forShowingEditModal) return;
    const curProductId = productId;
    const issueUrlList = issueService
      .getIssueUrlListData(curProductId)
      .pipe(take(1))
      .subscribe((res) => {
        const urlOriginalData = res.data;
        urlOriginalData?.map((item: any, index: number) => {
          item.label = item.siteUrl;
          item.value = item.id;
        });
        setUrlSearchListData(urlOriginalData);
        const result = urlOriginalData.filter((item: any) => item.siteUrl.includes(userSelectedUrlValue));
        // if (result?.length > 0)
        //   setMessageResult?.(`${result?.length} results found`);
        // else
        //   setMessageResult?.('No results found');
      });
    return () => {
      issueUrlList?.unsubscribe();
    };
  }, [userSelectedUrlValue]);

  const UploadRef = useRef<any>(null);
  const fileuploadConfig = {
    trigger: false,
    type: 'inline',
    size: true,
  };

  const onDeleteIssue = () => {
    setShowLoading(true);
    const param = {
      productId: productId,
      issueId: auditItemCurrent.id,
    };
    v2CreateOrUpdateManualRef.current = productService
    .deleteManualIssue(param)
    .pipe(take(1))
    .subscribe(
      (res) => {
        if (res.message === 'success') {  
          getIssueListByAccordion(activeKeys);
          getCurrentIssueNum();
          loadManualIssueData(()=>{
            setShowLoading(false);
          });
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  useEffect(() => {
   
      if (auditOpenIssueListData?.length === 0 && modalMappedIssue) {
        modalMappedIssue.auditResult = 0;
      }
    
  }, [auditOpenIssueListData])

  const handleDelete = (event: any) => {
    onDeleteIssue();
    setVisibleDeleteConfirmation(false);
  }

  const onIssueSummaryChange = (value: any, event: any) => {
    setIssueSummary(value);
  };

  const onSnippetValueChange = (value: any, event: any) => {
    setSnippetValue(value);
  };

  useEffect(() => {
    adjustSnippetHeight(snippetCreateRef);
  }, [snippetValue])

  useEffect(() => {
    setUniqueKey(prevKey => prevKey + 1);
  }, [uploadMessage]);

  const onIssueDescValueChange = (value: any, event: any) => {
    setIssueDescValue(value);
  };

  const onClearAllFiles = () => {
    setUploadMessage('All files have been removed.');
    // Optionally, clear the message after a short delay to ensure it can be announced again
    setTimeout(() => setUploadMessage(''), 100);
    (document.querySelector('.create-issue-modal .ap-modal-footer') as HTMLElement).click();
    setTimeout(() => {
      setUploadFileList([]);
      setUploadImageValue('');
    }, 2000);
  };

  const onKeydownClearAllFiles = (event: any) => {
    if (event.key === 'Enter') {
      onClearAllFiles();
    }
  };

  const handleClickCancelbutton = () => {
    if(!isTriggerByAddIssue.current) {
      onChangeRadio(radioValuePreRef.current, {} as any, radioItemRef.current, true);
    }
    setLevel2IsGlobalClicked(false);
    setShowCreateIssueModal(false);
    setModalMappedIssue(null);
  };

  const onUploadSuccess = (file: any) => {
    const formData = new FormData();
    formData.append('file', file.originFile);

    axios({
      method: 'post',
      url: '/questionnaire/issuePages/uploadFiles/v2',
      data: formData,
    }).then(
      (res: any) => {
        setShowUploadErrorMsg(false);
        setUploadMessage(`The file has been uploaded successfully`);
        // Optionally, clear the message after a short delay to ensure it can be announced again
        setTimeout(() => setUploadMessage(''), 100);
        const url = res.url;
        const tempList = [...uploadFileList, { file: file, url: res.url }];
        setUploadFileList(tempList);
        const fullUrladdress = `<img alt="${file.name}" class="upload-image" src="${url}">`;
        setUploadImageValue((prevValue: string) => {
          return prevValue + '<br/>' + fullUrladdress;
        });
      },
      (error) => {
        console.log('error', error);
        setShowUploadErrorMsg(true);
      },
    );
  };

  const onRemoveFile = (file: File, fileList: FileList) => {
    setUploadFileList([]);
    setUploadImageValue('');
  };

  const handleSelect = (value: string, item: any, event: React.SyntheticEvent | null) => {
    setUserSelectedData(item);
  };

  const onUserClickAdd = () => {
    let index = urlAddedListData.findIndex((item) => item.value === userSelectedData?.siteUrl);
    let tempArr = [];
    if (index === -1) {
      let item = { label: userSelectedData?.siteUrl||userSelectedUrlValue, value: userSelectedData?.siteUrl||userSelectedUrlValue };
      tempArr.push(item);
      setUrlAddedListData([...tempArr, ...urlAddedListData]);
    }
  };

  const onUserClickAddAll = () => {
    let tempArr: any = [];
    urlSearchListData.filter((originItem: any) => {
      let item = { label: originItem.siteUrl, value: originItem.siteUrl };
      tempArr.push(item);
    });
    setUrlAddedListData(tempArr);
  };

  const currentDelUrl = (event: any, delItem: any) => {
    let latestUrlArr = urlAddedListData.filter((item) => {
      return item.value !== delItem.value;
    });
    setUrlAddedListData([...latestUrlArr]);
  };

  const clickAddIssue = (item: any) => {
    isEditRef.current = false;
    setIssueSummary('');
    setIssueDescValue('');
    setSnippetValue('');
    setUrlAddedListData([]);
    seteditIssueStatus(false);
    setUploadImageValue('');
    setUserSelectedUrlValue('');
    setModalMappedIssue(item);
    setLevel2YesClicked(false);
    setLevel2NoClicked(false);
    setLevel2IsGlobalClicked(false);
    setShowUploadErrorMsg(false);
    setUploadFileList([]);
    setShowCreateIssueModal(true);
    isTriggerByAddIssue.current = true;
    //Drawer
    // setSeverityValue(issueListData[Number(activeKeys?.[0]||0)]?.severity?.toString());
  }

  const questionnaireItemsHandler = (questionnaireItems: any[]) => {
    const _data = _.groupBy(questionnaireItems, 'auditItemId');
    const res: any[] = [];

    for (const key in _data) {
      const currentKeyData = _data[key];

      if (currentKeyData && currentKeyData?.length > 0) {
        const issuePagesRes: any[] = currentKeyData.map((item: any) => {
          if (item.issuePages) {
            const submittedIssueArr = item.issuePages as any[];
            const tempIssuePageItem: any = {
              id: item.id,
              annotation: submittedIssueArr[0]?.annotation,
              remediation: submittedIssueArr[0]?.remediation,
              createdAt: item.createdAt,
              updatedAt: item.updatedAt,
              summarize: item.summarize,
              url: submittedIssueArr[0]?.url,
              auditItemId: Number(key),
              categoryId: item?.categoryId,
              description: item?.description,
              impact: item?.impact,
              mean: item?.mean,
              pic: item?.pic,
              proTip: item?.proTip,
              issuePages: submittedIssueArr,
              wcagCriterian: item?.wcagCriterian,
              auditItemSeq: item?.auditItemSeq,
              auditResult: item?.auditResult,
              productId: item?.productId,
              manualAssessmentInstruction:item?.manualAssessmentInstruction,
              severity: item?.impact
            };
            return tempIssuePageItem;
          }
        });

        const tempChild = currentKeyData[0];
        const tempRes: any = {
          auditItemId: Number(key),
          categoryId: tempChild?.categoryId,
          description: tempChild?.description,
          impact: tempChild?.impact,
          mean: tempChild?.mean,
          pic: tempChild?.pic,
          proTip: tempChild?.proTip,
          issuePages: issuePagesRes,
          wcagCriterian: tempChild?.wcagCriterian,
          auditResult: tempChild?.auditResult,
          manualAssessmentInstruction:tempChild?.manualAssessmentInstruction?.replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/\r\n|\r|\n/g, '<br>')
          .replace(/(Step \d+:)/g, '<label class="title-text">$1</label>'),
          severity: tempChild?.impact
        };
        res.push(tempRes);
      }
    }
    return res;
  };

  useEffect(() => {
    setTimeout(() => {
      adjustSectionHeight();
      setRemainingHeight();
    }, 300);
  }, [auditOpenIssueListData, auditResolvedIssueListData])

  const onClickAccordion = (_currentKeyArray: string[]) => {
    if (_currentKeyArray?.length > 0) {
      removeFixed();
      setShowIssueListContent(false);
      setDoesNotApplyClicked(false);
      setLevel1YesClicked(false);
      setLevel1NoClicked(false);
      setActiveKeys(_currentKeyArray);
      setChildAccoringActiveKeys([]);
      setShowLoading(true);
      getIssueListByAccordion(_currentKeyArray, null, () => {
        setTimeout(() => {
          adjustSectionHeight();
          setRemainingHeight();
        }, 300);
      });
    } else {
      setShowIssueListContent(false);
      setDoesNotApplyClicked(false);
      setLevel1YesClicked(false);
      setLevel1NoClicked(false);
      setActiveKeys([]);
      setChildAccoringActiveKeys([]);
      setTimeout(() => {
        adjustSectionHeight();
        setRemainingHeight();
      }, 300);
    }
  };

  // By section name
  const getManualIssueOriginalData = (sectionName: string, originalIssueData: any, callback: any, isBySelect = false) => {
    const curProductId = productId;
    setSelectedFilter([]);
    setTags([]);
    if(!isBySelect) {
      setShowLoadingSection(true);
    }
    orginalFilterRef.current = [];
    manulaDatatStatus.current = issueService
      .getManualIssueOriginalData(curProductId, isGuidelineRef.current)
      .pipe(take(1))
      .subscribe((res) => {
        if (res?.status === 1) {
          originalIssueData = res?.data;
          if(!isBySelect) {
            if(isGuidelineRef.current) {
              const guidelineArr = transformIssueData(res?.data);
              originalIssueData = guidelineArr;
              sectionName = guidelineArr[0]?.sectionName;
              updateSectionNameListData(originalIssueData);
            } else {
              sectionName = updateSectionNameListData(res?.data)?.[0]?.sectionName;
            }
            setShowLoadingSection(false);
          } else if (isGuidelineRef.current) {
            const guidelineArr = transformIssueData(res?.data);
            originalIssueData = guidelineArr;
          }
          openIssueCount.current = originalIssueData?.find((item: any) => item.sectionName === sectionName)?.openIssueCount;
          const currentSectionDataValue = currentSectionData(sectionName, originalIssueData);
          setSectionName(currentSectionDataValue?.sectionName);
          setSectionDescription(currentSectionDataValue?.sectionDescription);
          setIssueListData(questionnaireItemsHandler(currentSectionDataValue?.questionnaireItems));
          setShowIssueCategoryLoading(false);
          getNotApplicableCount(currentSectionDataValue, questionnaireItemsHandler(currentSectionDataValue?.questionnaireItems));
          setResultFilterBySeverity(questionnaireItemsHandler(currentSectionDataValue?.questionnaireItems));
          //Expand the first item by default
          updateFilterCount(currentSectionDataValue?.sectionName, res.data);
          setTimeout(() => {
            if (currentSectionDataValue?.questionnaireItems?.length > 0) {
              setShowIssueListContent(false);
              // console.log('activeKeys', activeKeys);
              // clickFirstAccordion();
            }
          }, 0);
        }
        callback?.();
        if(!callback) {
          setTimeout(() => {
            adjustSectionHeight();
            setRemainingHeight();
          }, 300);
        }
      });
  };

  const loadManualIssueData = (callback: any = null) => {
    const curProductId = productId;
    const resSubs = issueService
      .getManualIssueOriginalData(curProductId, isGuidelineRef.current)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.status === 1) {
          let issueData = res?.data;
          setOriginalIssueData(issueData);
          if(isGuidelineRef.current) {
            issueData = transformIssueData(issueData);
          }
          updateSectionNameListData(issueData, sectionName, false);
          openIssueCount.current = issueData?.find((item: any) => item.sectionName === sectionName)?.openIssueCount;
          let sectionNameArr: { id: number; sectionName: string }[] = [];
          issueData?.map((item: any, idx: number) => {
            let tempSection = {
              id: idx,
              sectionName: item.sectionName,
              completedValue: item.questionnaireItems.filter((dataItem: any) => dataItem.auditResult || dataItem?.statusConfirmed).length,
              totalValue: item.questionnaireItems.length,
              questionnaire: [...questionnaireItemsHandler(item.questionnaireItems)],
              ...item
            };
            sectionNameArr.push(tempSection);
          });
          setSummaryList(sectionNameArr);
          const currentSectionDataValue = currentSectionData(sectionName, sectionNameArr);
          setIssueListData(questionnaireItemsHandler(currentSectionDataValue?.questionnaireItems));
          getNotApplicableCount(currentSectionDataValue, questionnaireItemsHandler(currentSectionDataValue?.questionnaireItems), filterIssuesBySeverity);
          callback?.();
        }
      });
    return () => {
      resSubs?.unsubscribe();
    };
  }

  const updateSectionNameListData = (result: any, sectionName = '', filterBySeverity = true) => {
    let sectionNameArr: any[] = [];
    setOriginalIssueData(result);
    let current = 0;
    result?.map((item: any, idx: number) => {
      if(sectionName && item.sectionName === sectionName) {
        current = idx;
      }
      let tempSection = {
        id: idx,
        sectionName: item.sectionName,
        completedValue: item.questionnaireItems.filter((dataItem: any) => dataItem.auditResult || dataItem?.statusConfirmed).length,
        totalValue: item.questionnaireItems.length,
        questionnaire: [...questionnaireItemsHandler(item.questionnaireItems)],
        ...item
      };
      sectionNameArr.push(tempSection);
    });
    setIssueListData(questionnaireItemsHandler(result[current].questionnaireItems));
    setSectionNameListData(sectionNameArr);
    // if(filterBySeverity) setResultFilterBySeverity(questionnaireItemsHandler(result[current].questionnaireItems));
    setSummaryList(sectionNameArr);
    return sectionNameArr;
  }

  const isElementInViewport = (el: HTMLElement, adjust: number = 0) => {
    const rect = el?.getBoundingClientRect();
    return (
      rect?.top < (window.innerHeight || document.documentElement.clientHeight) &&
      rect?.bottom > 0 + adjust &&
      rect?.left < (window.innerWidth || document.documentElement.clientWidth) &&
      rect?.right > 0
    );
  };

  const getInnerHeightInViewport = (el: HTMLElement) => {
    const rect = el?.getBoundingClientRect();
    const viewportHeight = window.innerHeight || document.documentElement.clientHeight;
    // Calculate the visible height of the element in the viewport
    const visibleHeight = Math.min(rect?.bottom, viewportHeight) - Math.max(rect?.top, 0);
    return visibleHeight > 0 ? visibleHeight : 0;
  };

  const adjustSectionHeight = () => {
    let adjustHeight = 0;
    const leftSideSection = window.innerWidth <= 1440 ? (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[1] as HTMLElement) : (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[0] as HTMLElement);
    const rightSideSectionContainer = window.innerWidth <= 1440 ? (manualPageContainerRef.current?.querySelectorAll('.right-side-section-container')?.[1] as HTMLElement) : (manualPageContainerRef.current?.querySelectorAll('.right-side-section-container')?.[0] as HTMLElement);
    const bottomElement = manualPageContainerRef.current?.querySelector('.manualPage-bottom') as HTMLElement;
    const titleElement = manualPageContainerRef.current?.querySelector('.manualPage-title') as HTMLElement;
    const breadcrumbElement = manualPageContainerRef.current?.querySelector('.breadcrumbPart') as HTMLElement;
    const rightSideSectionContainerElement = rightSideSectionContainer?.querySelector('.right-side-section-container-filter') as HTMLElement;
    // const visibleHeightTitle = getInnerHeightInViewport(titleElement);
    // console.log('visibleHeightTitle', visibleHeightTitle, isElementInViewport(titleElement, 64));
    if(!isElementInViewport(titleElement, 64) && !leftSideSection?.classList.contains('fixed')) {
        leftSideSection?.classList?.add('fixed');
        rightSideSectionContainer?.classList?.add('fixed');
        titleElement?.classList?.add('fixed');
        bottomElement?.classList?.add('fixed');
        breadcrumbElement?.classList?.add('fixed');
        rightSideSectionContainerElement?.classList?.add('fixed');
    } else if (isElementInViewport(titleElement, 64)) {
        leftSideSection?.classList?.remove('fixed');
        rightSideSectionContainer?.classList?.remove('fixed');
        titleElement?.classList?.remove('fixed');
        bottomElement?.classList?.remove('fixed');
        breadcrumbElement?.classList?.remove('fixed');
        rightSideSectionContainerElement?.classList?.remove('fixed');
    }
    
    if (bottomElement) {
      const visibleHeight = getInnerHeightInViewport(bottomElement);
      if (isElementInViewport(bottomElement)) {
        // adjustHeight = - (64 + visibleHeight + (window.innerWidth <= 1440? 30: 48) + (visibleHeight > 112? 12 : 0) + (visibleHeight > 118 && visibleHeight < 133 && window.innerWidth <= 1440?  -10 : 0) - (window.innerWidth <= 1440? 2 : 20) + (rightSideSection?.offsetHeight <= 362? 24 : 0));
        adjustHeight = - (64 + visibleHeight + 48);
      } else {
        adjustHeight = - 64;
      }
    }
    const viewportHeight = window.innerHeight;
    if (leftSideSection) {
      leftSideSection.style.maxHeight = `${viewportHeight + adjustHeight}px`;
      leftSideSection.style.height = `${viewportHeight + adjustHeight}px`;
    }
    setTimeout(() => {
      
      if(isElementInViewport(bottomElement)) {// getInnerHeightInViewport(bottomElement) >= 113
        const manualReviewList = window.innerWidth <= 1440 ? (manualPageContainerRef?.current?.querySelectorAll('.left-side-section')?.[1] as HTMLElement)?.querySelector('.manual-review-list')  as HTMLElement: 
        (manualPageContainerRef?.current?.querySelectorAll('.left-side-section')?.[0] as HTMLElement)?.querySelector('.manual-review-list') as HTMLElement;
        const visibleHeight = getInnerHeightInViewport(bottomElement);
        const calcManualReviewListHeight = viewportHeight - (68 + 12 + 64 + visibleHeight + (visibleHeight >= 113? 12 : 0));
        const adjustHeightValue = Math.abs(manualReviewList?.offsetHeight - calcManualReviewListHeight);
        const adjustValue = manualReviewList?.offsetHeight - calcManualReviewListHeight;
        if(adjustHeightValue > 2) {
          leftSideSection.style.maxHeight = `${parseInt(leftSideSection.style.height) - (adjustValue)}px`;
          leftSideSection.style.height = `${parseInt(leftSideSection.style.height) - (adjustValue)}px`;
          // console.log('excuted', manualReviewList?.offsetHeight, leftSideSection.style.height);
        }
      }
    }, 0);
  };

  const setRemainingHeight = () => {
    if(showIssueCategoryLoading) return;
    const leftSideSection = window.innerWidth <= 1440 ? (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[1] as HTMLElement) : (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[0] as HTMLElement);
    const rightSideSectionContainer = window.innerWidth <= 1440 ? (manualPageContainerRef.current?.querySelectorAll('.right-side-section-container')?.[1] as HTMLElement) : (manualPageContainerRef.current?.querySelectorAll('.right-side-section-container')?.[0] as HTMLElement);
    const rightSidePage = rightSideSectionContainer?.querySelector('.manual-review-page') as HTMLElement;
    const remainingSection = rightSideSectionContainer?.querySelector('.manual-review-page-remaining-section') as HTMLElement;
    const manualPageSection = window.innerWidth <= 1440 ? manualPageContainerRef.current?.querySelector('.manualPage-content.row.smallTemp') as HTMLElement : manualPageContainerRef.current?.querySelector('.manualPage-content.row.normal') as HTMLElement;
    // console.log('setRemaining', getInnerHeightInViewport(manualPageSection), getInnerHeightInViewport(leftSideSection?.querySelector('.manual-review-nav') as HTMLElement), rightSidePage.offsetHeight, rightSidePage?.offsetHeight);
    const remainingHeight = getInnerHeightInViewport(manualPageSection) - rightSidePage?.offsetHeight - (window.innerWidth <= 1440? 38 : 24);
    if(remainingSection) {
      remainingSection.style.height = rightSidePage?.offsetHeight <= 800? `${remainingHeight > 0 ? remainingHeight : 0}px` : '0px';
    }
  }

  const handleAdjustSectionHeight = throttle(() => {
    if(showIssueCategoryLoading) return;
    setTimeout(() => {
      adjustSectionHeight();
    }, 0); 
  }, 200)

  useEffect(() => {
    const combinedResizeHandler = () => {
      setHasExecuted(false);
      setRemainingHeight();
      setTimeout(() => {
        handleAdjustSectionHeight();
      }, 0);
    };

    const combinedWheelHandler = () => {
      if (!hasExecuted) {
        setRemainingHeight();
        !showIssueCategoryLoading && setHasExecuted(true);
      }
      handleAdjustSectionHeight();
    };
    window.addEventListener('wheel', combinedWheelHandler);
    window.addEventListener('resize', combinedResizeHandler);

    return () => {
      window.removeEventListener('wheel', combinedWheelHandler);
      window.removeEventListener('resize', combinedResizeHandler);
    };
  }, [hasExecuted]);

  useEffect(() => {
    if (forShowingEditModal) return;
    setShowAllLoading(true);
    const curProductId = productId;
    const resSubs = issueService
      .getManualIssueOriginalData(curProductId)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.status === 1) {
          const sectionName = updateSectionNameListData(res?.data)?.[0].sectionName;
          getManualIssueOriginalData(sectionName, res?.data, ()=>{
            setShowAllLoading(false);
            setTimeout(() => {
              adjustSectionHeight();
              setRemainingHeight();
            }, 0);
          });
    }});
    
    // window.addEventListener('resize', combinedResizeHandler);
    // window.addEventListener('wheel', combinedWheelHandler);
    // Cleanup
    return () => {
      resSubs?.unsubscribe();
      manulaDatatStatus?.current?.unsubscribe();
      // window.removeEventListener('resize', combinedResizeHandler);
      // window.removeEventListener('wheel', combinedWheelHandler);
    };
  }, []);

  useEffect(() => {
    if(!showAllLoading) {
      setTimeout(() => {
          (manualPageContainerRef?.current?.querySelector('.searchButtonContainer-narrowFilter_iconPart') as HTMLElement)?.removeAttribute('role');
      }, 0);
    }
  }, [showAllLoading])

  // useEffect(() => {
  //   if (forShowingEditModal) return;
  //   const curProductId = productId;
  //   const issueUrlList = issueService
  //     .getIssueUrlListData(curProductId)
  //     .pipe(take(1))
  //     .subscribe((res) => {
  //       const urlOriginalData = res.data;
  //       urlOriginalData?.map((item: any, index: number) => {
  //         item.label = item.siteUrl;
  //         item.value = item.id;
  //       });
  //       setUrlSearchListData(urlOriginalData);
  //     });
  //   return () => {
  //     issueUrlList?.unsubscribe();
  //   };
  // }, []);

  useEffect(() => {
    if (forShowingEditModal) return;
    const curProductId = productId;
    const param = isGuidelineRef.current? { productId: curProductId, classifiedBy:"guideline"} : { productId: curProductId};
    const sectionIssueStatus = issueService
      .getSectionIssuesStatus(param)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.status === 1) {
          let completedList = res.data.filter((item: any) => item[1] === 1);
          setSectionIssuesCompletedList(completedList);
        }
      });
    return () => {
      sectionIssueStatus?.unsubscribe();
    };
  }, []);

  const getProductInfo = () => {
    const productInfo = getProductInfoCookie();
    return productInfo ? safelyParseJSON(productInfo) : null;
  };

  const getAddedUrlValue = () => {
    let tempUrlArr: any[] = [];
    if (urlAddedListData && urlAddedListData?.length > 0) {
      urlAddedListData?.map((url) => {
        tempUrlArr.push(url.value);
      });
      return tempUrlArr;
    } else {
      return [];
    }
  };

  const saveIssuePageClickHandler = () => {
    setSavePageIssuesBtnLoading(true);
    setShowLoading(true);
    let fullValue = `${issueDescValue}${uploadImageValue}`;
    const param = {
      issueId: editIssueStatus ? currentEditIssue?.id : '',
      productId: productId,
      auditItemId: modalMappedIssue?.auditItemId,
      auditItemSeq: editIssueStatus ? '' : auditOpenIssueListData?.length + 1,
      summarize: issueSummary,
      annotation: snippetValue,
      remediation: fullValue,
      urls: level2YesClicked ? [] : getAddedUrlValue(),
      isGlobal: level2YesClicked ? 1 : 0,
    };
    v2CreateOrUpdateManualRef.current = productService
      .v2CreateOrUpdateManual(param)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res?.status?.toLowerCase() === 'success') {
            if(modalMappedIssue) modalMappedIssue.auditResult = 1;
            setSavePageIssuesBtnLoading(false);
            setLevel2IsGlobalClicked(false);
            setShowCreateIssueModal(false);
            seteditIssueStatus(false);
            getIssueListByAccordion(activeKeys);
            getCurrentIssueNum();
            updateFilterCount(sectionName, originalIssueData);
            loadManualIssueData(()=>setShowLoading(false));
            radioValueRef.current = '';
            radioItemRef.current = null;
            setModalMappedIssue(null);
            radioValuePreRef.current = '2';
            if (forShowingEditModal)
              setSaveIssueClicked?.(true);
            if(!isEditRef.current)
            setTimeout(() => {
              toaster.notify(
              <Notification
                message={"Issue successfully saved."}
                status={"success"}
              />,
              { position: 'bottomLeft', duration: 5000 }
            );
            }, 0);
            setTimeout(() => {
              adjustSectionHeight();
              setRemainingHeight();
            }, 300);
          }
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const onChangeToResolved = (auditIssue: any) => {
    const auditItemNumber = issueData?.find((item: any) => item.id === auditIssue?.id)?.number;
    const param = {
      productId: auditIssue?.productId,
      number: auditItemNumber,
      status: 0,
      comment: reopenModalCommentVal,
    }
    setShowLoading(true);
    v2SetManualStatusRef.current = productService
      .v2SetManualStatus(param)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res.status === 'SUCCESS') {
            getIssueListByAccordion(activeKeys);
            getCurrentIssueNum();
            loadManualIssueData(()=>{
              setShowLoading(false);
            });
            setOpenOrCloseBtnLoading(false);
            setReopenModalVisible(false);
            setReopenModalCommentVal('');
            setTimeout(() => {
              toaster.notify(
                <Notification
                  title=""
                  message={`The issue has been successfully ${auditIssue?.status === 0 ? 'reopened' : 'closed'}.`}
                  status="success"
                />,
                { position: 'bottomLeft', duration: 5000 }
              );
            }, 500);
          }
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const onEditIssueStatus = (auditIssue: any) => {
    let fullValue = auditIssue.remediation;
    let remediationValue = '';
    let uploadImageValue = '';
    setIssueMode(auditIssue.globalFlag === 0?'individual':'global');
    setOptionsByIssueMode(auditIssue.globalFlag === 0?'individual':'global');
    if (fullValue && fullValue.includes('<p>')) {
      let splitValueArr = fullValue.split('<p>');
      remediationValue = splitValueArr[0];
      let tempValue = '';
      for (let i = 1; i < splitValueArr?.length; i++) {
        tempValue += `<p>${splitValueArr[i]}`;
      }
      uploadImageValue = tempValue;
      setUploadImageValue(uploadImageValue);
    } else {
      remediationValue = fullValue;
    }

    if (auditIssue.url?.length > 0) {
      let tempUrlArr: any[] = [];
      auditIssue.url.map((urlLink: string) => {
        tempUrlArr.push({ label: urlLink, value: urlLink });
      });
      setUrlAddedListData(tempUrlArr);
    } else {
      setUrlAddedListData([]);
    }

    isEditRef.current = true;
    setUploadFileList([]);
    setUserSelectedUrlValue('');
    setLevel2IsGlobalClicked(true);
    setIssueSummary(auditIssue.summarize);
    setIssueDescValue(remediationValue);
    setSnippetValue(auditIssue.annotation);
    setCurrentEditIssue(auditIssue);
    seteditIssueStatus(true);
    setShowUploadErrorMsg(false);
    setShowCreateIssueModal(true);
  };

  useEffect(() => {
    if (showCreateIssueModal && isEditRef.current === false) {
      onRadiosChange('global', null);
    }
  }, [showCreateIssueModal])

  const onReopenIssue = (auditIssue: any) => {
    const curProductId = productId;
    const auditItemNumber = issueData?.find((item: any) => item.id === auditIssue?.id)?.number;
    const param = {
      productId: productId,
      number: auditItemNumber,
      status: 1,
      comment: reopenModalCommentVal,
    }

    const param2 = {
      productId: curProductId,
      auditItemId: auditIssue.auditItemId,
      auditResult: 1,
    };
    setShowLoading(true);

    v2SetManualStatusRef.current = productService
      .v2SetManualStatus(param)
      .pipe(
        switchMap((res) => {
          if (res.status === 'SUCCESS') {
            setLevel1NoClicked(true);
            setLevel1YesClicked(false);
            setDoesNotApplyClicked(false);
          }
          return issueService.getAuditItemIssueList(param2).pipe(
            switchMap((res3) => {
              if (res3.status === 1) {
                let openIssList = res3.data.filter((item: any) => item.status === 1);
                setAuditOpenIssueListData(openIssList);
                let resolvedIssList = res3.data.filter((item: any) => item.status === 0);
                setAuditResolvedIssueListData(resolvedIssList);
                setIssueData(res3.data);
                setShowIssueListContent(true);
              }
              const param = isGuidelineRef.current? { productId: curProductId, classifiedBy:"guideline"} : { productId: curProductId};
              return issueService.getSectionIssuesStatus(param); // Return an observable
            }),
          );
        }),
      )
      .subscribe((sectionStatusres) => {
        setShowLoading(false);
        if (sectionStatusres.status === 1) {
          let completedList = sectionStatusres.data.filter((item: any) => item[1] === 1);
          setSectionIssuesCompletedList(completedList);
          setOpenOrCloseBtnLoading(false);
          setReopenModalVisible(false);
          setReopenModalCommentVal('');
          setTimeout(() => {
            toaster.notify(
              <Notification
                title=""
                message={`The issue has been successfully ${auditIssue?.status === 0 ? 'reopened' : 'closed'}.`}
                status="success"
              />,
              { position: 'bottomLeft', duration: 5000 },
            );
          }, 500);
        }
      });
  };

  useEffect(() => {
    return () => {
      v2CreateOrUpdateManualRef?.current?.unsubscribe();
      getAuditItemIssueListRef?.current?.unsubscribe();
      updateManualIssueStatusRef?.current?.unsubscribe();
      updateAduitItemStatusRef?.current?.unsubscribe();
      v2SetManualStatusRef?.current?.unsubscribe();
    };
  }, []);

  const btnStatus = useMemo(() => {
    if (userSelectedUrlValue) {
      if (
        urlValidator(userSelectedUrlValue) !== true ||
        urlAddedListData.some((item) => item.value === (userSelectedData?.siteUrl||userSelectedUrlValue))
      ) {
        return true;
      }
    } else {
      return true;
    }

  }, [userSelectedUrlValue, urlAddedListData]);


  const onPageLinkChange = (value: string, event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>) => {
    setUserSelectedUrlValue(value);
  };

  const getFileSize = (size: any) => {
    const fileSizeMB = (size / 1024 / 1024).toFixed(1);
    const fileSizeKB = (size / 1024).toFixed(1);
    let fileSize;
    if (fileSizeMB < '1') {
      fileSize = fileSizeKB + 'KB';
    } else {
      fileSize = fileSizeMB + 'MB';
    }
    return fileSize;
  };

  const adjustSnippetHeight = (snippetRef: any) => {
    if (snippetRef.current) {
      const textarea = snippetRef.current.querySelector('textarea');
      textarea.style.height = 'auto'; // Reset the height
      textarea.style.height = `${Math.min(textarea.scrollHeight, 268)}px`; // Set the height to the scroll height or max height
    }
  };

  const setOptionsByIssueMode = (value: string) => {
    if (value === 'global') {
      setLevel2YesClicked(true);
      setLevel2NoClicked(false);
      setLevel2IsGlobalClicked(true);
    } else {
      setLevel2YesClicked(false);
      setLevel2NoClicked(true);
      setLevel2IsGlobalClicked(true);
    }
  }

  const onRadiosChange = (value: string, event: React.SyntheticEvent|null) => {
    setIssueMode(value);
    setOptionsByIssueMode(value);
  };

  const removeFixed = () => {
    const leftSideSection = window.innerWidth <= 1440 ? (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[1] as HTMLElement) : (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[0] as HTMLElement);
    const rightSideSectionContainer = window.innerWidth <= 1440 ? (manualPageContainerRef.current?.querySelectorAll('.right-side-section-container')?.[1] as HTMLElement) : (manualPageContainerRef.current?.querySelectorAll('.right-side-section-container')?.[0] as HTMLElement);
    const bottomElement = manualPageContainerRef.current?.querySelector('.manualPage-bottom') as HTMLElement;
    const titleElement = manualPageContainerRef.current?.querySelector('.manualPage-title') as HTMLElement;
    const breadcrumbElement = manualPageContainerRef.current?.querySelector('.breadcrumbPart') as HTMLElement;
    const rightSideSectionContainerElement = manualPageContainerRef.current?.querySelectorAll('.right-side-section-container-filter');

    leftSideSection?.classList?.remove('fixed');
    rightSideSectionContainer?.classList?.remove('fixed');
    titleElement?.classList?.remove('fixed');
    bottomElement?.classList?.remove('fixed');
    breadcrumbElement?.classList?.remove('fixed');
    rightSideSectionContainerElement?.classList?.remove('fixed');
  };

  const setHeaderSection = (val: string) => {
    const leftSideSection = window.innerWidth <= 1440 ? (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[1] as HTMLElement) : (manualPageContainerRef.current?.querySelectorAll('.left-side-section')?.[0] as HTMLElement);
    if(!leftSideSection?.classList?.contains('fixed')) return;
    const breadcrumbElement = manualPageContainerRef.current?.querySelector('.breadcrumbPart') as HTMLElement;
    const titleElement = manualPageContainerRef.current?.querySelector('.manualPage-title') as HTMLElement;

    if (breadcrumbElement) {
      breadcrumbElement.style.display = val;
    }
    if (titleElement) {
      titleElement.style.display = val;
    }
  };

  const modalContent = useMemo(() => {
    return {
    title: isEditRef.current ? 'Edit issue' : 'Add issue',
    body: (
      <div className="create-issue-modal-content">
        <div className="issue-type-header ap-pb-spacing-6">
          <div className="issue-type-desc ap-mt-spacing-3 ap-mb-spacing-3">
            <div className="issue-type-desc-value">
              Type of issue
            </div>
          </div>
          <div>
            <RadioGroup defaultValue={issueMode} value={issueMode} name="interfaceAppearance" onChange={onRadiosChange}>
              <Radio value={'global'} checked={issueMode === 'global'}>Global (an issue that exists on all pages, for example: headers, footers, navigation, modals, etc.)</Radio>
              <Radio value={'individual'} checked={issueMode === 'individual'} >Individual (an issue that exist only on specific pages)</Radio>
            </RadioGroup>
          </div>
          {issueMode === 'individual' ? (
            <>
              <div className="issue-type-header ap-mt-spacing-6">
                <div className="issue-type-item impacted-pages ap-font-weight-2 ap-mb-spacing-3">
                  <span className="red-dot no-top">Impacted pages</span>
                </div>
                <div className="page-link-box">
                  <div className="search-field">
                    <Search
                      data={urlSearchListData}
                      placeholder={'Search impacted page'}
                      searchType={'secondary'}
                      onSelect={(value: string, item: any, event: React.SyntheticEvent) => {
                        handleSelect(value, item, event);
                      }}
                      onClear={() => setUserSelectedUrlValue(null)}
                      searchValue={userSelectedUrlValue}
                      onChange={onPageLinkChange}
                      style={{ position: 'absolute' }}
                    />
                  </div>
                  <div className="btn-box">
                    <Button kind="secondary" onClick={() => onUserClickAdd()} disabled={btnStatus}>
                      Add URL
                    </Button>
                  </div>
                </div>
              </div>
              <div className={`impacted-url-list ${urlAddedListData && urlAddedListData?.length > 0 ? 'showUrlList' : null}`}>
                <div className="url-list-head ap-mb-spacing-3 ap-mt-spacing-4">
                  <div className="url-list-head-left">{urlAddedListData?.length} pages added</div>
                  <div className="url-list-head-right">{/* <span className="ap-ml-spacing-8">Delete</span> */}</div>
                </div>
                <div className={'impacted-url-table'}>
                  <Table originalData={urlAddedListData} condensed>
                    <Column field='label' sortKey='label'>label</Column>
                    <Column field='value' renderCell={renderDeleteCell}>delete</Column>
                  </Table>
                  <div className='impacted-url-table-line'></div>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className={classNames("issue-type-header", { "ap-pb-spacing-6": issueSummary.length <= 150 })}>
          <div className="issue-type-item ap-font-weight-2 ap-mb-spacing-3">
            <span>Issue details</span>
          </div>
          <div className="ap-field-demo-wrapper">
            <Input
              type={'text'}
              onClick={() => { }}
              required={true}
              value={issueSummary}
              onChange={(v: any, e: any) => onIssueSummaryChange(v, e)}
              title={'Issue title'}
              error={issueSummary.length > 150}
              errorNode={(<div id="errormessage" aria-live="polite" className="ap-field-email-validation-error">Maximum 150 character limit exceeded. Please shorten your entry.</div>)}
            ></Input>
          </div>
        </div>
        <div className="url-list-content">
          <div className="content-body-common-box">
            <div className="ap-field-demo-wrapper ap-pb-spacing-6">
              <TextArea
                maxLength={2000}
                value={issueDescValue ? issueDescValue : ''}
                title={'Issue description'}
                required={true}
                className="ap-ml-spacing-3"
                onChange={(v, e) => onIssueDescValueChange(v, e)}
              ></TextArea>
            </div>
            <div className="ap-field-demo-wrapper  ap-pb-spacing-6" ref={snippetCreateRef}>
              <TextArea
                // maxLength={1000}
                value={snippetValue}
                title={'Issue code snippet'}
                className="ap-ml-spacing-3"
                onChange={(v, e) => onSnippetValueChange(v, e)}
              ></TextArea>
            </div>
            <div className="manual-suggestions-lightPart">
              <span className="Appkit4-icon icon-lightbulb-outline lightIcon ap-mr-spacing-3"></span>
              <span>
                For more effective GenAI suggestions to resolve this issue, please include the code snippet.
              </span>
            </div>

            {/* Upload */}
            <div className="content-body-common-title ap-mt-spacing-6 ap-mb-spacing-3">
              Screenshots of the issue
            </div>
            <div className="content-body-common-desc">Please add the alt text for the image to the file name.</div>
            <div className="upload-part">
              <Upload
                ref={UploadRef}
                config={fileuploadConfig}
                multiple={true}
                onSuccess={onUploadSuccess}
                onRemove={onRemoveFile}
                acceptFileType=".JPG,.PNG"
                maxFileSize={10 * 1024 * 1024}
                withCredentials={true}
                autoUpload={true}
                showFileList={false}
              ></Upload>

              <div className="file-list">
                <div
                  className={`del-all ${uploadFileList?.length > 0 ? 'show' : ''}`}
                  role="button"
                  tabIndex={0}
                  onClick={onClearAllFiles}
                  onKeyDown={onKeydownClearAllFiles} 
                >
                  <span>Delete all attachments</span>
                </div>
                {uploadFileList.map((file, index) => {
                  return (
                    <div key={index} className="file-item">
                      <div className="file-loaded">
                        <div className="file-name-part">
                          <span className="Appkit4-icon icon-document-file-outline"></span>
                          <span className="file-upload-span">
                            <span className="file-upload-fileName"> {file.file.name}</span>
                            <span className="file-upload-fileSize"> &nbsp;&nbsp;({getFileSize(file.file.size)})</span>
                          </span>
                        </div>
                        <span className={`ap-fileupload-success ${file.file.status === 'success' ? 'show' : ''}`}>
                          <span className="Appkit4-icon icon-circle-checkmark-outline"></span>
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={`file-list-error  ${showUploadErrorMsg ? 'show' : ''}`}>
                <span>There was an error uploading the file. Please try again or contact support.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    ),
    footer: (
      <>
        <div className="url-list-footer ap-py-spacing-6">
          <div className="list-modalFooter-descArea">
            <span>Required Fields</span>
          </div>
          <div className='footer-button-group'>
            <Button kind='text' onClick={handleClickCancelbutton}>
              Cancel
            </Button>
            <Button
              loading={savePageIssuesBtnLoading}
              kind="primary"
              disabled={(issueMode === 'global' && issueSummary?.length > 0 && issueDescValue?.length > 0 && issueSummary?.length <= 150) 
                || (issueMode === 'individual' && urlAddedListData?.length > 0 && issueSummary?.length > 0 && issueDescValue?.length > 0)
                ? false : true}
              className="ap-ml-spacing-4"
              onClick={saveIssuePageClickHandler}
            >
              {isEditRef.current ? 'Save changes' : 'Save issue'}
            </Button>
            <div
                aria-live="polite"
                aria-atomic="true"
                className="sr-only"
                key={uniqueKey}
              >
                {uploadMessage}
              </div>
          </div>
        </div>
      </>
    ),
  }}, [showCreateIssueModal, savePageIssuesBtnLoading, issueMode, userSelectedUrlValue, urlAddedListData, issueSummary, issueDescValue, snippetValue, uploadFileList, uploadMessage, uniqueKey]);

  const getCurrentIssueNum = () => {
    const curProductId = productId;
    const param = isGuidelineRef.current? { productId: curProductId, classifiedBy:"guideline"} : { productId: curProductId};
    const issueNumstatus = issueService
      .getSectionIssuesStatus(param)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.status === 1) {
          let completedList = res.data.filter((item: any) => item[1] === 1);
          setSectionIssuesCompletedList(completedList);
        }
      });
    return () => {
      issueNumstatus?.unsubscribe();
    };
  };

  const getIssueListByButton = (callback: any = null) => {
    setShowLoading(true);
    let currentKey = Number(activeKeys) as number;
    let currentAuditItemId = issueListData[currentKey]?.auditItemId;
    const param = {
      productId: productId,
      auditItemId: currentAuditItemId,
    };
    if (param.productId && param.auditItemId)
      getAuditItemIssueListRef.current = issueService
        .getAuditItemIssueList(param)
        .pipe(take(1))
        .subscribe((res: any) => {
          setShowLoading(false);
          if (res.status === 1) {
            let openIssList = res.data.filter((item: any) => item.status === 1);
            setAuditOpenIssueListData(openIssList);
            let resolvedIssList = res.data.filter((item: any) => item.status === 0);
            setAuditResolvedIssueListData(resolvedIssList);
            setIssueData(res.data);
            setShowIssueListContent(true);
            callback?.();
          }
        });
  };

  const resetTabForElements = () => {
    const accordionDiv = document.querySelectorAll('.manualPage-content .ap-accordion-content.ap-accordion-cursor-default');
    if (accordionDiv) {
        accordionDiv.forEach((element: any) => {
            const focusableElements = element.querySelectorAll('a, button, input, textarea, select, .ap-accordion-toggle, .ap-radio, .ap-radio.ap-radio-checked, .ap-tabset-toggle.has-label.active');
            if (element?.classList.contains('hidden')) {
                focusableElements.forEach((element: any) => {
                    element.setAttribute('tabindex', '-1');
                })
            } else {
                focusableElements.forEach((element: any) => {
                    element.setAttribute('tabindex', '0');
                })
            }

            const radioElements = element.querySelectorAll('input[type="radio"]');
            radioElements.forEach((element: any) => {
              element.setAttribute('tabindex', '-1');
            });
        });
    }
  }

  const getIssueListByAccordion = (_currentKeyArray: any, resData: any = null, callback: any = null) => {
    let currentKey = _currentKeyArray[0];
    let currentAuditItemId = resData === null? issueListData[currentKey]?.auditItemId : resData[currentKey]?.auditItemId;
    const param = {
      productId: productId,
      auditItemId: currentAuditItemId,
    };
    if (param.productId && param.auditItemId)
      getAuditItemIssueListRef.current = issueService
        .getAuditItemIssueList(param)
        .pipe(take(1))
        .subscribe((res: any) => {
          setShowLoading(false);
          if (res.status === 1) {
            let openIssList = res.data.filter((item: any) => item.status === 1);
            setAuditOpenIssueListData(openIssList);
            let resolvedIssList = res.data.filter((item: any) => item.status === 0);
            setAuditResolvedIssueListData(resolvedIssList);
            setIssueData(res.data);
            setTimeout(() => {
              if(res.itemStatus === 0) {
                setLevel1NoClicked(false);
                setDoesNotApplyClicked(false);
                setLevel1YesClicked(false);
              } else if (res.itemStatus === 1) {
                setLevel1NoClicked(true);
                setDoesNotApplyClicked(false);
                setLevel1YesClicked(false);
              } else if (res.itemStatus === 2) {
                setDoesNotApplyClicked(true);
                setLevel1NoClicked(false);
                setLevel1YesClicked(false);
              } else if (res.itemStatus === 3) {
                setLevel1YesClicked(true);
                setLevel1NoClicked(false);
                setDoesNotApplyClicked(false);
              }
              callback?.();
            }, 0);
            setShowIssueListContent(true);
          }

          setTimeout(() => {
            let elements = manualPageContainerRef?.current?.querySelectorAll('[class="ap-radio"]');
            elements?.forEach((element: any) => {
              element.tabIndex = 0;
            });
            resetTabForElements();
          }, 100);
        });
  };

  const callback = () => {
    removeFixed();
    //automatically close and expand item
    if (Number(activeKeys?.[0]) + 1 < issueListData.length) {
      onClickAccordion([(Number(activeKeys?.[0]) + 1).toString()]);
    } else {
      setActiveKeys([]);
      setTimeout(() => {
        adjustSectionHeight();
        setRemainingHeight();
      }, 300);
    }
    setDoesNotApplyClicked(false);
    setLevel1YesClicked(false);
    setLevel1NoClicked(false);
  }

  const onClickYesBtn = (item: any, event?: Event) => {
    event?.preventDefault();
    event?.stopPropagation();
    setShowLoading(true);
    const param = {
      productId: productId,
      auditItemId: item.auditItemId,
      auditResult: 3
    };
    updateAduitItemStatusRef.current = issueService
      .setManualItemStatus(param)
      .pipe(take(1))
      .subscribe((res: any) => {
        setShowLoading(false);
        if (res.status === 'SUCCESS') {
          item.auditResult = 3;
          item.statusConfirmed = true;
          getIssueListByButton(callback);
          loadManualIssueData();
          getCurrentIssueNum();
          radioValueRef.current = '';
          radioItemRef.current = null;
        }
      });
  };
  //getNotApplicableCount: this need one new api
  function getNotApplicableCount(currentSectionData: any, issueListData: any, resultFilterBySeverityUpdated: any = null, callback: any = null) {
    countNotApplicable.current = 0;
    const result = questionnaireItemsHandler((currentSectionData?.[0] || currentSectionData)?.questionnaireItems);
    const filteredListData = selectedFilter?.length > 0? result.filter((item) => selectedFilter.includes(item?.impact)) : result;
    countNotApplicable.current = filteredListData.filter((item: any) => item.auditResult === 2).length;
    callback?.();
  }

  const onClickNoBtn = (auditItem: any, isByCancel = false) => {
    setIssueSummary('');
    setIssueDescValue('');
    setSnippetValue('');
    setUrlAddedListData([]);
    seteditIssueStatus(false);
    setUploadImageValue('');
    setUserSelectedUrlValue('');
    setLevel2YesClicked(false);
    setLevel2NoClicked(false);
    setUploadFileList([]);
    // if (!isByCancel) setShowOpenedIssue(true);
    if(isByCancel) return;
    setLevel2IsGlobalClicked(false);
    setShowUploadErrorMsg(false);
    if (!isByCancel) setShowCreateIssueModal(true);
  };

  const getLinkUrl = (auditItem: any, needHost = false) => {
    const tempHost = process?.env?.REACT_APP_app_url;
    const hostArr = tempHost?.split('.');
    const appId = (getProductInfoCookie() && safelyParseJSON(getProductInfoCookie()!)?.appId);
    if (hostArr) {
      const host = hostArr[hostArr.length - 1].includes('/') ? tempHost : `${tempHost}/`;
      const auditItemNumber = issueData?.find((item: any) => item.id === auditItem.id)?.number;
      const url = `${needHost? host:'/'}v2/dwrapper/issuedetail?appId=${appId}&id=${auditItemNumber}&origin=manualreview`;
      return url;
    }
    return;
  }

  const filterIssuesBySeverity = () => {
    const currentSectionDataValue = currentSectionData(sectionName, originalIssueData);
    const resultDetail = selectedFilter?.length === 0? currentSectionDataValue?.questionnaireItems : currentSectionDataValue?.questionnaireItems.filter((cItem: any) => {
      return selectedFilter.includes(cItem?.impact)
    });
    setResultFilterBySeverity(questionnaireItemsHandler(resultDetail));
    //Filter result list
    setIssueListData(questionnaireItemsHandler(resultDetail));
    getNotApplicableCount(currentSectionDataValue, shouldCallRef.current? resultDetail : issueListData, resultDetail || [], () => {
      setShowLoading(false);
    });
  };
  const prevResultFilterBySeverityRef = useRef(resultFilterBySeverity);
  useEffect(() => {
    if (!isEqual(prevResultFilterBySeverityRef.current, resultFilterBySeverity)) {
      if (issueListData?.length > 0) {
        clickFirstAccordion();
      }
      prevResultFilterBySeverityRef.current = resultFilterBySeverity;
    }
  }, [resultFilterBySeverity])

  useEffect(() => {
    if (tags?.length >= 0 || shouldCallRef.current === true) {
      filterIssuesBySeverity();
      shouldCallRef.current = false;
    }
  }, [tags])

  const onClickNotApplyBtn = (item: any, statusConfirmed: boolean = true, event?: Event) => {
    event?.preventDefault();
    event?.stopPropagation();
    setShowLoading(true);
    const param = {
      productId: productId,
      auditItemId: item.auditItemId,
      auditResult: statusConfirmed ? 2 : 1
    };
    updateAduitItemStatusRef.current = issueService
      .setManualItemStatus(param)
      .pipe(take(1))
      .subscribe((res: any) => {
        setShowLoading(false);
        if (res.status === 'SUCCESS') {
          item.auditResult = statusConfirmed ? 2 : 1;
          item.statusConfirmed = statusConfirmed;
          getIssueListByButton(callback);
          getCurrentIssueNum();
          loadManualIssueData();
          radioValueRef.current = '';
          radioItemRef.current = null;
        }
      });
  };

  const onKeydownAddIssue = (event: any, item: any) => {
    if (event.key === 'Enter') {
      clickAddIssue(item);
    }
  };
  
  const IssueList = () => {
    const customTitle = (item: any, index: number) => {
      return (
        <div className='custom-issue-title-section'>
          <div className="custom-issue-title">
            {(item?.auditResult > 0 || item?.statusConfirmed) ? <span aria-hidden="true" className="Appkit4-icon completed icon-success-fill" key={index}></span> :
              <span aria-hidden="true" className="Appkit4-icon icon-success-fill" key={index}></span>
            }
            <div>{item.description}</div>
          </div>
          <div className='option-group ap-mt-spacing-3 ap-ml-spacing-7'><Badge value={item?.wcagCriterian?.map((criterion: any) => criterion.content)?.join(',')} type={'info'} size="small" /></div>
        </div>
      );
    };

    const onTabChange = (i: number, value: string) => {
      setactiveIndex(i);
      if (i === 0) {
        setShowOpenedIssue(true);
      } else {
        setShowOpenedIssue(false);
      }
      setTimeout(() => {
        adjustSectionHeight();
        setRemainingHeight();
      }, 300);
    };

    const handleSelection = (itemName: string, filterList: any[] | undefined) => {
      let nextselectedFilter = Array.from(selectedFilter)
      if (nextselectedFilter.indexOf(itemName) === -1) {
        nextselectedFilter.push(itemName)
      } else {
        nextselectedFilter.splice(nextselectedFilter.indexOf(itemName), 1);
      }
      const selectedList = filterList?.filter(item => nextselectedFilter.includes(item.filterLevel)).map(item => item.filterLevel) as string[];
      setSelectedFilter(selectedList);
    }

    const toggleNarrowScreenFilter = (event: MouseEvent) => {
      event.stopPropagation();
      setShowMenu(!showMenu);
    };

    const onNotApplicableAllChange = (value: boolean) => {
      removeFixed();
      if(value === true && openIssueCount.current === 0) {
        handleContinue();
        return;
      }
      if (value === true) {
        setTriggerSource('checkbox');
        setVisibleConfirmation(value);
      } else {
        setVisibleResetConfirmation(true);
      }
    }

    const dropdownSelecthandler = (value: any, item: any, event: any, auditItem: any, checkpointItem: any) => {
      if (['cplink', 'mcplink'].includes(value)) {
          const url = getLinkUrl(auditItem, true)||'';
          setTimeout(() => {
            navigator.clipboard.writeText(url);
          }, 0);
          return;
      }
      if (['editissue'].includes(value)) {
        onEditIssueStatus(auditItem);
      }
      if (['deleteissue'].includes(value)) {
        setVisibleDeleteConfirmation(true);
        setAuditItemCurrent(auditItem);
        setModalMappedIssue(checkpointItem);
      }
      if (['reopenissue', 'closeissue'].includes(value)) {
        setAuditItemCurrent(auditItem);
        setReopenModalVisible(true);
      }
    }

    const onChangeFilter = (item: string, isByTag = false) => {
      if (isByTag) setTags(tags.filter(itemInfo => itemInfo !== item));
      setSelectedFilter(tags.filter(itemInfo => itemInfo !== item));
      if(tags.length === 1) shouldCallRef.current = true;
    }

    const onChangeRadioEvent = (value: RadioValueType, event: React.SyntheticEvent, item: any) => {
      if (level1YesClicked) {
        radioValuePreRef.current = '1';
      } else if (level1NoClicked) {
        radioValuePreRef.current = '2';
      } else if (doesNotApplyClicked) {
        radioValuePreRef.current = '3';
      } else {
        radioValuePreRef.current = '';
      }
      radioValueRef.current = value.toString();
      radioItemRef.current = item;
      if (level1NoClicked && (radioValueRef.current === '1' || radioValueRef.current === '3')) {
        setTriggerSource('radio');
        setVisibleConfirmation(true);
      } else if (!level1NoClicked && (radioValueRef.current === '1' || radioValueRef.current === '3')) { 
        handleContinue(true);
      } else if (radioValueRef.current === '2') {
        isTriggerByAddIssue.current = false;
        onChangeRadio(radioValueRef.current, {} as any, radioItemRef.current);
      }
    }

    const onChildAccordingClickAccordion = (activeKeys: string[], event: React.MouseEvent) => {
      setChildAccoringActiveKeys(activeKeys);
      setTimeout(() => {
        adjustSectionHeight();
        setRemainingHeight();
      }, 300);
    };

    const noResult = <NoResultsPage
      imgSrc={NoResultsSvg}
      imgAlt="no results found logo"
      titleArea="No checkpoints found."
      descArea={`Select a different filter option.`}
    />;

    const onApplyEvent = () => {
      setTags(selectedFilter);
      orginalFilterRef.current = selectedFilter;
      setShowMenu(false);
    }

    const clearFilters = () => {
      setTags([]);
      setSelectedFilter([]);
      orginalFilterRef.current = [];
      shouldCallRef.current = true;
    }

    const onKeyDownFilter = (event: React.KeyboardEvent<HTMLDivElement>) => {
      const { key } = event;
      const element = document.activeElement as HTMLElement;
      if (key === 'Enter') {
        event.preventDefault();
        // const list = (menuRef.current! as HTMLElement)?.querySelectorAll('.filter-list-row');
        const index = Number(element.className.split(' ')?.[1]);
        index > -1 && handleSelection(filterList?.[index]?.filterLevel, filterList);
        if (!showMenu) setShowMenu(true);
        if (showMenu && element.className.indexOf('btn-apply') > -1) {
            setShowMenu(false);
        }
      }
    }

    return (
      <>
      <div className='manual-review-page'>
        <div className='manual-review-page-title ap-mb-spacing-1'>{sectionName}</div>
        <div className='manual-review-page-des ap-mb-spacing-3' dangerouslySetInnerHTML={{ __html: sectionDescription }}></div>
        {/* Filter */}
        <div className='right-side-section-container-filter'>
        <div className='content-area ap-mb-spacing-4'>
           <div>
            <Checkbox
              checked={((countNotApplicable.current === resultFilterBySeverity?.length
                || notApplicableAll) && ((resultFilterBySeverity === null || resultFilterBySeverity?.length > 0) || selectedFilter.length === 0) && countNotApplicable.current !== 0) &&
                !(countNotApplicable.current > 0 && countNotApplicable.current < resultFilterBySeverity?.length) && (resultFilterBySeverity === null || resultFilterBySeverity?.length > 0)}
              // indeterminate={(countNotApplicable.current > 0 && countNotApplicable.current < resultFilterBySeverity?.length) && (resultFilterBySeverity === null || resultFilterBySeverity?.length > 0)}
              disabled={!((resultFilterBySeverity?.length > 0) ||
                (resultFilterBySeverity === null))} 
              onChange={onNotApplicableAllChange}>Mark all checkpoints as not applicable</Checkbox>
          </div>
          <div className='popup-area'>
            <Button
              ref={btnRef}
              kind="secondary"
              className="searchButtonContainer-narrowFilter_iconPart"
              onClick={toggleNarrowScreenFilter}
              aria-label="Filter">
              <span className="Appkit4-icon icon-filter-outline filterIcon-cls"></span>
            </Button>
            <div ref={menuRef} className={`filterItemsPart filter-content ${showMenu ? 'show' : 'hide'}`} onKeyDown={onKeyDownFilter}>
              <div className='filter-title'>Severity</div>
              {
                filterList?.map((item: any, index: number) => <div tabIndex={0} className={classNames(`filter-list-row ${index}`, index === filterList.length - 1? "last" : "")} key={index}>
                  <Checkbox className='filter-item-checkbox' checked={selectedFilter.indexOf(item.filterLevel) > -1} tabIndex={-1} onChange={() => { handleSelection(filterList?.[index].filterLevel, filterList) }}>{item.filterLevel}</Checkbox>
                  <span>{item.count}</span>
                </div>)
              }
              <div className="filter-footer">
                <Button kind='secondary' className="ap-pattern-btn-clear-filters" disabled={selectedFilter?.length === 0} onClick={() => { setSelectedFilter([]) }}>Clear</Button>
                <Button className="ap-pattern-btn-apply" onClick={onApplyEvent}>Apply</Button>
              </div>
            </div>
          </div>
        </div>
        <div className={classNames('data-list-filter', tags && tags.length > 0? 'has-tag':'')}>
          {tags && tags.length > 0 && <><div tabIndex={0} className="header-right-option-clear" onClick={clearFilters}>Clear filters</div>
            <div className="tags-panel-border"></div></>}
          {tags && tags.length > 0 && <div className="data-list-header-right-option">
            <div className="tags-panel">
              {tags.map((item: string, index: number) => <Tag key={index} onClose={() => onChangeFilter(item, true)}>{item}</Tag>)}
            </div>
          </div>}
        </div>
        </div>
        {
            ((resultFilterBySeverity?.length > 0) ||
            ((resultFilterBySeverity === null || selectedFilter?.length === 0) && resultFilterBySeverity?.length !== 0)) ?
              issueListData && issueListData.map((item: any, index: number) => {
                  if(resultFilterBySeverity?.length !== 0 && !resultFilterBySeverity.some((dataItem: any) => dataItem?.auditItemId?.toString() === item?.auditItemId?.toString()))
                    return;
                  else
                    return (
                    <div className="ap-pattern-section-content manul-issue-list">
                      {(showLoading && activeKeys?.[0] === index.toString()) ? <LoadingComponent></LoadingComponent> :
                        <Accordion onClick={onClickAccordion} activeKeys={activeKeys}>
                          <AccordionItem
                            className="custom-acc"
                            itemKey={index.toString()}
                            key={index}
                            templateHeader={() => customTitle(item, index)}>
                            {showLoading ? (
                              <LoadingComponent />
                            ) : (
                              <>
                                <div className={`operation-box ap-mb-spacing-3`}>
                                  <div className='option-group ap-ml-spacing-7'>
                                  {/* disabled={showOpenedIssue ? false : true} */}
                                    <RadioGroup name="option" 
                                      defaultValue={doesNotApplyClicked ? '3' : (level1NoClicked ? '2' : (level1YesClicked ? '1' : ''))} 
                                      onChange={(value: RadioValueType, event: React.SyntheticEvent) => onChangeRadioEvent(value, event, item)}
                                      value={doesNotApplyClicked ? '3' : (level1NoClicked ? '2' : (level1YesClicked ? '1' : ''))}>
                                      <Radio checked={level1YesClicked} value={'1'}>Yes</Radio>
                                      <Radio checked={level1NoClicked} value={'2'}>No</Radio>
                                      <Radio checked={doesNotApplyClicked} value={'3'}>Not applicable</Radio>
                                    </RadioGroup>
                                  </div>
                                </div>
                                <div className="accessOptionPart-child-content">
                                  <Accordion
                                    multiple={false}
                                    onClick={(activeKeys: string[], event: React.MouseEvent<HTMLElement>) => {
                                      onChildAccordingClickAccordion(activeKeys, event);
                                    }}
                                    activeKeys={childAccoringActiveKeys}
                                    showExpandIcon={false}
                                  >
                                    <AccordionItem
                                        templateHeader={() => 
                                        <><div><span aria-hidden="true" className="Appkit4-icon icon-information-outline ap-mr-spacing-3"></span><span>How to check for this</span></div>
                                        <Tooltip
                                        trigger="hover"
                                        position="top"
                                        distance={4}
                                        id="tooltipDesc"
                                        hideTooltipOnBlur={true}
                                        content={
                                          childAccoringActiveKeys?.[0] === '0' ? 'Collapse' : 'Expand'
                                        }
                                      >
                                        <span aria-hidden="true" className={classNames("Appkit4-icon", { "icon-expand-chevron-outline": childAccoringActiveKeys?.[0] !== '0', "icon-collapse-chevron-outline": childAccoringActiveKeys?.[0] === '0' })}></span>
                                        </Tooltip>
                                        </>}
                                      itemKey={`0`}
                                      className={`${item.clicked ? 'content-clicked' : ''}`}
                                      // toggleFromHeaderIconOnly={true}
                                      // inclElementToToggle={".icon-expand-chevron-outline"}
                                    >
                                      <div style={{ display: 'flex', width: '100%' }}>
                                        <div className={`editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart ${childAccoringActiveKeys.length > 0 ? 'show' : ''}`}>
                                          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-title">
                                                <p
                                                  dangerouslySetInnerHTML={{ __html: item?.manualAssessmentInstruction?.toString() }}
                                                ></p>
                                          </div>
                                        </div>
                                      </div>
                                    </AccordionItem>
                                  </Accordion>
                                </div>
                                {/* Issue List */}
                                {auditOpenIssueListData.length>0||auditResolvedIssueListData.length>0? <div className="ap-extra-template-container ap-mt-spacing-6 ap-ml-spacing-7">
                                  <div className='template-container-title ap-mb-spacing-4'>Related issues</div>
                                  <Tabs type="underline" activeIndex={activeIndex} onTabChange={onTabChange}>
                                    <Tab label={`Open (${auditOpenIssueListData.length})`} value="openIssue"></Tab>
                                    <Tab label={`Closed (${auditResolvedIssueListData.length})`} value="resolvedIssue"></Tab>
                                  </Tabs>
                                </div>:null}
                                <div className={`issueDetails ${showIssueListContent&&(auditOpenIssueListData.length>0||auditResolvedIssueListData.length>0) ? 'show' : ''}`}>
                                  {showOpenedIssue ? (
                                    <>
                                      {auditOpenIssueListData.length>0||auditResolvedIssueListData.length>0? activeIndex === 0 && auditOpenIssueListData.length == 0 && (!level1YesClicked || !level1NoClicked || !doesNotApplyClicked)
                                       && (<em className="no-description">No open issues related to this checkpoint.</em>) : null}
                                      {auditOpenIssueListData?.map((auditIssue: any, idx) => (
                                        <Panel className="issue-list-panel ap-mb-spacing-3" key={idx}>
                                          <div className="issue-details-box normal">
                                            <div className="issue-title">{auditIssue.summarize} </div>
                                            <div className="issue-status-update">
                                              {/* <div className='option-title ap-mr-spacing-8' onClick={() => openIssue(auditIssue)}><span className="Appkit4-icon icon-open-in-new-outline ap-mr-spacing-3"></span>Open issue</div> */}
                                              <Button
                                                kind="text"
                                                className={"option-title-open-issue-btn ap-mr-spacing-4"}
                                                icon={'icon-open-in-new-outline'}
                                                onClick={()=>openIssue(auditIssue)}>
                                                Open issue
                                              </Button>
                                              <DropdownButton
                                                customTriggerNode={true}
                                                customTriggerClassName="custom-node"
                                                dropdownClassName="dpdStyle"
                                                data={getDropDownContent()}
                                                onSelect={(value, event) => dropdownSelecthandler(value, null, event, auditIssue, item)}
                                                prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
                                              >
                                                <span aria-hidden="true" className="Appkit4-icon icon-horizontal-more-outline"></span>
                                              </DropdownButton>
                                            </div>
                                          </div>
                                          <div className="issue-details-box smallTemp">
                                            {/* <div className="issue-title">Issue added on {moment(nextDate).format('YYYY-MM-DD')}</div> TBD */}
                                            <div className="issue-title">{auditIssue.summarize}</div>
                                            <div className="issue-status-update ap-mt-spacing-5">
                                              {/* <div className='option-title ap-mr-spacing-8' onClick={() => openIssue(auditIssue)}><span className="Appkit4-icon icon-open-in-new-outline ap-mr-spacing-3"></span>Open issue</div> */}
                                              <Button
                                                kind="text"
                                                className={"option-title-open-issue-btn ap-mr-spacing-4"}
                                                icon={'icon-open-in-new-outline'}
                                                onClick={()=>openIssue(auditIssue)}>
                                                Open issue
                                              </Button>
                                              <DropdownButton
                                                customTriggerNode={true}
                                                customTriggerClassName="custom-node"
                                                dropdownClassName="dpdStyle"
                                                data={getDropDownContent()}
                                                onSelect={(value, event) => dropdownSelecthandler(value, null, event, auditIssue, item)}
                                                prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
                                              >
                                                <span className="Appkit4-icon icon-horizontal-more-outline"></span>
                                              </DropdownButton>
                                            </div>
                                          </div>
                                        </Panel>
                                      ))}
                                      {<Button
                                        icon="icon-plus-outline"
                                        kind='text'
                                        tabIndex={0}
                                        className={`ap-mt-spacing-3 add-issue-btn ${level1NoClicked ? 'showAdd' : ''}`}
                                        onClick={() => clickAddIssue(item)}
                                        onKeyDown={(event: any) => {
                                          onKeydownAddIssue(event, item);
                                        }}
                                      >
                                        Add issue
                                      </Button>}
                                    </>
                                  ) : (
                                    <>
                                      {auditOpenIssueListData.length>0||auditResolvedIssueListData.length>0? activeIndex === 1 && auditResolvedIssueListData.length === 0 && (<em className="no-description">No closed issues.</em>) : null}
                                      {auditResolvedIssueListData?.map((auditIssue: any, idx) => (
                                      <Panel className="issue-list-panel ap-mb-spacing-3" key={idx}>
                                      <div className="issue-details-box normal">
                                        <div className="issue-title">{auditIssue.summarize} </div>
                                        <div className="issue-status-update">
                                          {/* <div className='option-title ap-mr-spacing-8' onClick={() => openIssue(auditIssue)}><span className="Appkit4-icon icon-open-in-new-outline ap-mr-spacing-3"></span>Open issue</div> */}
                                          <Button
                                                kind="text"
                                                className={"option-title-open-issue-btn ap-mr-spacing-4"}
                                                icon={'icon-open-in-new-outline'}
                                                onClick={()=>openIssue(auditIssue)}>
                                                Open issue
                                              </Button>
                                              <DropdownButton
                                                customTriggerNode={true}
                                                customTriggerClassName="custom-node"
                                                dropdownClassName="dpdStyle"
                                                data={getDropDownContent(true)}
                                                onSelect={(value, event) => dropdownSelecthandler(value, null, event, auditIssue, item)}
                                                prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
                                              >
                                                <span className="Appkit4-icon icon-horizontal-more-outline"></span>
                                              </DropdownButton>
                                        </div>
                                      </div>
                                      <div className="issue-details-box smallTemp">
                                        {/* <div className="issue-title">Issue added on {moment(nextDate).format('YYYY-MM-DD')}</div> TBD */}
                                        <div className="issue-title">{auditIssue.summarize}</div>
                                        <div className="issue-status-update ap-mt-spacing-5">
                                          {/* <div className='option-title ap-mr-spacing-8' onClick={() => openIssue(auditIssue)}><span className="Appkit4-icon icon-open-in-new-outline ap-mr-spacing-3"></span>Open issue</div> */}
                                          <Button
                                            kind="text"
                                            className={"option-title-open-issue-btn ap-mr-spacing-4"}
                                            icon={'icon-open-in-new-outline'}
                                            onClick={()=>openIssue(auditIssue)}>
                                            Open issue
                                          </Button>
                                          <DropdownButton
                                            customTriggerNode={true}
                                            customTriggerClassName="custom-node"
                                            dropdownClassName="dpdStyle"
                                            data={getDropDownContent(true)}
                                            onSelect={(value, event) => dropdownSelecthandler(value, null, event, auditIssue, item)}
                                            prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
                                          >
                                            <span className="Appkit4-icon icon-horizontal-more-outline"></span>
                                          </DropdownButton>
                                        </div>
                                      </div>
                                      </Panel>
                                      ))}
                                    </>
                                  )}
                                </div>
                              </>
                            )}
                          </AccordionItem>
                        </Accordion>}
                    </div>)
              }) : noResult
        }
      </div>
      <div className='manual-review-page-remaining-section'></div>
      </>
    );
  };

  const isInsideModal = (target: HTMLElement, className: string): boolean => {
    while (target) {
      if (target?.classList && target?.classList.contains(className)
      || (target.className.includes('icon-checkbox-selected-fill'))) {
        return true;
      }
      target = target.parentElement as HTMLElement;
    }
    return false;
  };

  useEffect(() => {
    if (!showMenu) return;
    function menuClickHandler(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (!btnRef.current || !menuRef.current) return;
      if (btnRef.current?.contains(target)) {
        setShowMenu(!showMenu);
        return;
      }

      if (!isInsideModal(target, 'filterItemsPart')) {
        setSelectedFilter(orginalFilterRef.current);
        setShowMenu(false);
      }
    }

    function hideMenuPart(event: any) {
      menuRef.current?.classList.remove('show');
    }

    window.removeEventListener('click', menuClickHandler);
    window.addEventListener('click', menuClickHandler);
    window.addEventListener('resize', (event: any) => hideMenuPart(event));
    return () => {
      window.removeEventListener('click', menuClickHandler);
      window.removeEventListener('resize', (event: any) => hideMenuPart(event));
    };
  }, [showMenu]);

  useImperativeHandle(ref, () => ({
    showEditModal: (currentAuditItemId: string, currentId: string) => {
      isEditRef.current = true;
      const param = {
        productId: productId,
        auditItemId: currentAuditItemId,
      };
      if (param.productId && param.auditItemId)
        getAuditItemIssueListRef.current = issueService
          .getAuditItemIssueList(param)
          .pipe(take(1))
          .subscribe((res: any) => {
            if (res.status === 1) {
              onEditIssueStatus(res.data?.find((item: any) => item.id === currentId));
            }
          });
    }
  }));

  const handleCancel = () => {
    if (radioValueRef.current === '') {
      setNotApplicableAll(false);
    } else {
      onChangeRadio(radioValuePreRef.current, {} as any, radioItemRef.current, true);
    }
    radioValueRef.current = '';
    radioItemRef.current = null;
    setVisibleConfirmation(false);
  }

  const handleCancelReset = () => {
    setVisibleResetConfirmation(false);
  }

  const handleCancelDelete = () => {
    setVisibleDeleteConfirmation(false);
  }

  const clickFirstAccordion = () => {
    let executed = false;
    issueListData.map((item: any, index: number) => {
      if (item.auditItemId === resultFilterBySeverity?.[0]?.auditItemId) {
        onClickAccordion([index.toString()]);
        executed = true;
      }
    });
    if(!executed) {
      setDoesNotApplyClicked(false);
      setLevel1YesClicked(false);
      setLevel1NoClicked(false);
    }
  }

  const handleContinue = (isByYesContinue = false) => {
    if (radioValueRef.current === '') {
      setNotApplicableAll(true);
      countNotApplicable.current = 0;
      const listData = questionnaireItemsHandler(currentSectionData(sectionName, originalIssueData)?.questionnaireItems);
      const filteredListData = selectedFilter?.length > 0? listData.filter((item) => 
        resultFilterBySeverity.some((dataItem: any) => dataItem?.auditItemId?.toString() === item?.auditItemId?.toString())
      ) : listData;
      setShowLoading(true);  
      filteredListData.map((item) => {
        item.statusConfirmed = true;
      });
      const param = {
        productId: productId,
        auditItemIdList: filteredListData.map(item=>item.auditItemId),
        auditResult: 2
      };
      updateAduitItemStatusRef.current = issueService
        .setManualItemStatus(param)
        .pipe(take(1))
        .subscribe({
        next: () => {
          if (filteredListData?.length > 0) {
            getCurrentIssueNum();
            loadManualIssueData(()=>setShowLoading(false));
            setTimeout(() => {
              setActiveKeys([]);
              setTimeout(() => {
                adjustSectionHeight();
                setRemainingHeight();
                setHasExecuted(false);
              }, 300);
            }, 0); 
          }
        }
      });
      if(listData?.length ===  0) {
        setActiveKeys([]);
        getManualIssueOriginalData(sectionName, originalIssueData, () => setShowAllLoading(false));
      }
    } else if(isByYesContinue) {
      onChangeRadio(radioValueRef.current, {} as any, radioItemRef.current);
    }
    radioValueRef.current = '';
    radioItemRef.current = null;
    setVisibleConfirmation(false);
  }

  const handleContinueReset = () => {
    setNotApplicableAll(false);
    countNotApplicable.current = 0;
    const listData = questionnaireItemsHandler(currentSectionData(sectionName, originalIssueData)?.questionnaireItems);//check originalIssueData[0]
    const filteredListData = selectedFilter?.length > 0? listData.filter((item) => 
      resultFilterBySeverity.some((dataItem: any) => dataItem?.auditItemId?.toString() === item?.auditItemId?.toString())
    ) : listData;
    setShowLoading(true);
    filteredListData.map((item) => {
      item.statusConfirmed = false;
    });
    const param = {
      productId: productId,
      auditItemIdList: filteredListData.map(item=>item.auditItemId),
      auditResult: 0
    };
    updateAduitItemStatusRef.current = issueService
      .setManualItemStatus(param)
      .pipe(take(1))
      .subscribe({
      next: () => {
          if (filteredListData?.length > 0) {
              loadManualIssueData(()=>setShowLoading(false));
              getCurrentIssueNum();
              clickFirstAccordion();
          }
      // Perform any additional actions after all observables have completed
    }});
    setVisibleResetConfirmation(false);
  }

  const onChangeRadio = (value: RadioValueType, event: React.SyntheticEvent, item: any, isByCancel = false) => {
    switch (value) {
      case '1':
        !isByCancel && setShowIssueListContent(false);
        setLevel1YesClicked(true);
        setLevel1NoClicked(false);
        setDoesNotApplyClicked(false);
        !isByCancel && onClickYesBtn(item);
        break;
      case '2':
        setLevel1YesClicked(false);
        setDoesNotApplyClicked(false);
        setLevel1NoClicked(true);
        setShowIssueListContent(true);
        setModalMappedIssue(item);
        onClickNoBtn(item, isByCancel);
        isEditRef.current = false;
        break;
      case '3':
        !isByCancel && setShowIssueListContent(false);
        setDoesNotApplyClicked(true);
        setLevel1YesClicked(false);
        setLevel1NoClicked(false);
        !isByCancel && onClickNotApplyBtn(item);
        break;
      case '':
        !isByCancel && setShowIssueListContent(false);
        setLevel1YesClicked(false);
        setLevel1NoClicked(false);
        setDoesNotApplyClicked(false);
        break;  
    }
  }

  const goToDashboard = () => {
    const appId = (getProductInfoCookie() && safelyParseJSON(getProductInfoCookie()!)?.appId);
    navigate(`/v2/dwrapper/board?appId=${appId}`);
  };

  const goToIssues = () => {
    const appId = (getProductInfoCookie() && safelyParseJSON(getProductInfoCookie()!)?.appId);
    navigate(`/v2/dwrapper/issues?appId=${appId}`);
  };

  const exitBtnClick = () => {
    location.state?.origin?.toString() === 'dashboard' ? goToDashboard() : goToIssues();
  }

  const openIssue = (item: any) => {
    const url = getLinkUrl(item)||'';
    setOpenUrl(url);
    setTimeout(() => {
      anchorRef.current?.click();
    }, 0);
    // window.open(url, '_blank');
  };

  const selectTypeCallback = (isGuideline: boolean, callback?: any) => {
    isGuidelineRef.current = isGuideline;
    getManualIssueOriginalData(sectionName, originalIssueData, ()=>{
      getCurrentIssueNum();
      setShowAllLoading(false);
      callback?.();
      setTimeout(() => {
        adjustSectionHeight();
        setRemainingHeight();
      }, 300);
    });
  }

  const dataSeverity = [
    { value: 'Critical', label: 'Critical', key: 'Critical' },
    { value: 'Serious', label: 'Serious', key: 'Serious' }
  ];
  // const [severityValue, setSeverityValue] = useState('');

  return (
    <>
      <div ref={manualPageContainerRef} className={classNames("ap-container manualPage", { "loading": showIssueCategoryLoading || showLoading })}>
      <div className="row">
          <div className="row">
            <div className="breadcrumbPart">
              <Breadcrumb>
                <BreadcrumbItem onClick={location.state?.origin?.toString() === 'dashboard' ? goToDashboard : goToIssues}>
                  <span tabIndex={0}>{location.state?.origin?.toString() === 'dashboard' ? 'Dashboard' : 'Issues'}</span>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <span tabIndex={-1}>Manual review</span>
                </BreadcrumbItem>
              </Breadcrumb>
            </div>
          </div>
          <div className="row">
            <div className={classNames("ap-component-name manualPage-title")}>
              Manual review
            </div>
          </div>
        </div>
      <div className="row">
        <div className={classNames("manualPage-content-section", { "loading": showAllLoading })}>
        {
          showAllLoading ? <LoadingComponent></LoadingComponent> :
            (<><div className="manualPage-content row normal">
              <div className="col-3 left-side-section">      
                {SectionListPart({
                setShowIssueListContent, setAuditOpenIssueListData, setAuditResolvedIssueListData,
                setActiveKeys, getManualIssueOriginalData, setSectionName, summaryList, sectionIssuesCompletedList, sectionNameList, selectedId, setSelectedId, setShowIssueCategoryLoading, selectedType, setSelectedType,
                selectTypeCallback, originalIssueData, showLoadingSection, guidelineList, showLoading, setShowLoading, removeFixed, setHeaderSection
              })}
              </div>
              <div className="col-9 manual9 right-side-section-container" tabIndex={-1}>
                {
                  <div className="ap-panel-with-extra-container ap-container">
                    <div className='right-side-section'>{showIssueCategoryLoading ? <LoadingComponent /> : IssueList()}</div>
                  </div>
                }
              </div>
            </div>
              <div className="manualPage-content row smallTemp">
                <div className="col-4 left-side-section">{SectionListPart({
                setShowIssueListContent, setAuditOpenIssueListData, setAuditResolvedIssueListData,
                setActiveKeys, getManualIssueOriginalData, setSectionName, summaryList, sectionIssuesCompletedList, sectionNameList, selectedId, setSelectedId, setShowIssueCategoryLoading, selectedType, setSelectedType,
                selectTypeCallback, originalIssueData, showLoadingSection, guidelineList, showLoading, setShowLoading,removeFixed, setHeaderSection
              })}</div>
                <div className="col-8 manual9 right-side-section-container" tabIndex={-1}>
                {
                  <div className="ap-panel-with-extra-container ap-container">
                    <div className='right-side-section'>{showIssueCategoryLoading ? <LoadingComponent /> : IssueList()}</div>
                  </div>
                }
                </div>
              </div>
            </>)}
        {showAllLoading ? <div></div> : null}
        </div>
        </div>
        <div className="row">
          {<div className="manualPage-bottom">
            <div className="manualPage-bottom-content"><Button kind='secondary' tabIndex={0} onClick={exitBtnClick}>Exit manual review</Button></div>
            <FooterPage />
          </div>}
        </div>
      </div>
      {<CommonModal
        visible={showCreateIssueModal}
        modalContent={modalContent}
        setVisibleFunc={setShowCreateIssueModal}
        className={'create-issue-modal'}
        bodyStyle={{}}
        onCancel={handleClickCancelbutton}
        maskCloseable={false}
      />}
      <Modal
        visible={visibleConfirmation}
        className='ap-pattern-confirmation-close'
        title='Open issues will be closed'
        closable
        maskCloseable={false}
        onCancel={handleCancel}
        footer={
          <>
            <Button kind="secondary" onClick={handleCancel}>Cancel</Button>
            <Button kind="primary" onClick={() => handleContinue(true)}>Yes, continue</Button>
          </>
        }
      >
        <div className='ap-pattern-confirmation-message'>{triggerSource === 'checkbox' ? 'Choosing this option will close all the open issues related to the checkpoints on this section. Do you want to continue?' 
        : 'Choosing this option will close all the open issues related to this checkpoint. Do you want to continue?'}</div>
      </Modal>
      <DeleteIssueConfirmation visibleDeleteConfirmation={visibleDeleteConfirmation} setVisibleDeleteConfirmation={setVisibleDeleteConfirmation} handleCancelDelete={handleCancelDelete} handleDelete={handleDelete}></DeleteIssueConfirmation>
      {<Modal
        visible={visibleResetConfirmation}
        className='ap-pattern-confirmation-close'
        title='Checkpoints will be reset'
        closable
        maskCloseable={false}
        onCancel={handleCancelReset}
        footer={
          <>
            <Button kind="secondary" onClick={handleCancelReset}>Cancel</Button>
            <Button kind="primary" onClick={handleContinueReset}>Yes, continue</Button>
          </>
        }>
        <div className='ap-pattern-confirmation-message'>Choosing this option will reset the checkpoints on this section. Any related issues will be preserved. Do you want to continue?</div>
      </Modal>}
      {reopenModalVisible && (
        <CommonModal
          modalContent={reopenModalInfo}
          visible={reopenModalVisible}
          setVisibleFunc={setReopenModalVisible}
        />
      )}
      <a ref={anchorRef} href={openUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'none' }}>click</a>
    </>
  );
});

export default Manualpage;