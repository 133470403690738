import Axios from 'axios';
import { AuthUserType } from '@models';
import { BehaviorSubject } from 'rxjs';
import { decryptData, encryptData, getCookie, safelyParseJSON, setCookie } from '@utils/common';
import * as jwt from 'jsonwebtoken';
import { authService } from './auth.service';
const baseURL = process.env.REACT_APP_service_url;
const axios = Axios.create({
  baseURL,
});
const apiUndefinedError$ = new BehaviorSubject<boolean | null>(null);
const apiAuthError$ = new BehaviorSubject<boolean | null>(null);
const productPermissionError$ = new BehaviorSubject<boolean | null>(null);
const token = getCookie('idToken')!;
const decodeds: any = jwt.decode(token);
// request拦截
axios.interceptors.request.use(
  (config: any) => {
    if (config && config.url && !config.url.includes('http')) {
      const token = 'Bearer ' + getCookie('idToken');//window.localStorage.getItem('idToken');
      config.headers.authorization = token;
      const localStorageUserStr: string = decryptData(getCookie('azureAuthUser')!) || '';
      try {
        let localStorageUser: AuthUserType | null = null;
        if(localStorageUserStr)       
          localStorageUser = safelyParseJSON(localStorageUserStr);
          config.headers['user_id'] = localStorageUser?.preferredMail||decodeds?.preferredMail;
      } catch (error) {}
    }
    config.headers['login_type'] = sessionStorage.getItem('loginType')||'internal';
    return config;
  },
  (error) => Promise.reject(error),
);

// response拦截
axios.interceptors.response.use(
  (response) => {
    const data = response.data || {};
    if (data.status === -1 && (data.message || '').includes('NOT_PERMITTED')) {
      productPermissionError$.next(true);
      if(data.message.includes('INVALID TOKEN')){
        authService.logout();
      }
    }
    return data;
  },
  (error) => {
    if (error?.response?.status === 401 || error?.response?.status === 400) {
      authService.logout();
      apiAuthError$.next(true);
    }
    // else {
    //   apiUndefinedError$.next(true);
    // }
    console.error(error);
    return Promise.reject(error?.response);
  },
);

export { axios, apiAuthError$, apiUndefinedError$, productPermissionError$ };
