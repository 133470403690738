import React, { useEffect, useMemo, useRef, useState, useContext, useCallback } from 'react';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { Search } from '@appkit4/react-components/search';
import { Button } from '@appkit4/react-components/button';
import { Select, Badge, DropdownButton } from '@appkit4/react-components';
import { Pagination } from '@appkit4/react-components/pagination';
import { Tag } from '@appkit4/react-components/tag';
import './issues.scss';
import IssueDashboard from './IssueDashboard';
import { IIssueType, ItemType, IIssueDashboardType } from './types';
import IssueFiltersDashboard from './IssueFiltersDashboard';
import noIssueAuto from '../../assets/icons/noIssueAuto.svg';
import noIssueManual from '../../assets/icons/noIssueManual.svg';

import NoSearchResultsSvg from '../../assets/icons/noSearchResults.svg';
import NotificationNew from '@components/Notification';
import { issueService } from '@services/issue.service';
import { catchError, concatMap, take, switchMap } from 'rxjs/operators';
import { useLocation, useSearchParams } from 'react-router-dom';
import { filtersStatusPartData, filtersSeverityData, filterEffortLevelData, filtersWcagData, filterConformanceLevelData, filtersTestTypeData } from './constants';
import LoadingComponent from '@components/Loading';
import { assetService } from '@services/asset.service';
import { Observable, Subscription, of } from 'rxjs';
import { capitalizeFirstLetter, decryptData, getCookie, getProductInfoCookie, safelyParseJSON, sanitizeHtml } from '@utils/common';
import _ from 'lodash';
import moment from 'moment';
import FooterPage from '@components/Footer';
import { useNavigate } from 'react-router-dom';
import ScanModal from '@components/ScanModal';
import ReRunScanSvg from '@assets/icons/RerunScan.svg';
import NoIssueToManageSvg from '@assets/icons/no-products.svg';
import { productService } from '@services';
import { NoResultsPage } from '@components/Home';
import { EntryContext } from '@layout/Entry';

const tabLabels = [
  { name: 'Automated scan', value: 'automatedscan' },
  { name: 'Manual review', value: 'manualreview' },
];

const Issues = () => {
  const {
    setNewestAssessmentId,
    setCloseScanModalStatus,
    closeScanModalMethod,
    setCloseScanModalMethod,
    setReScanBtnDisabled,
    reScanBtnDisabled,
    currentProductIdAndAssessmentId,
    saveIssueClicked,
    setSaveIssueClicked,
    narrowScreenSelectedItems,
    setNarrowScreenSelectedItems,
    setMessageResult,
  } = useContext(EntryContext);
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const activeIndex = 0;
  const [showNotification, setShowNotification] = React.useState(false);
  const [showNarrowFilter, setShowNarrowFilter] = React.useState(false);
  const narrowScreenFilterItmPartRef = useRef<HTMLDivElement>(null);
  const narrowScreenFilterBtnRef = useRef<HTMLButtonElement>(null);
  const [issueDashboardItems, setIssueDashboardItems] = useState<IIssueType[]>([]);
  const [pinIssueList, setPinIssueList] = useState<any[]>([]);
  const [unpinIssueList, setUnpinIssueList] = useState<any[]>([]);
  const [filterItemsInResponse, setFilterItemsInResponse] = useState<any>('');
  const [assessmentId, setAssessmentId] = useState('');
  const [latestAssessmentId, setLatestAssessmentId] = useState('');
  const [isManualLastOne, setIsManualLastOne] = useState(false);

  const [issueTotalNum, setIssueTotalNum] = useState('');
  const [issueAllPageCnt, setIssueAllPageCnt] = useState('');
  const [lastUpdateDateTime, setLastUpdateDateTime] = useState('');
  const narrowScreenSelectedItemsValue = () => {
    return JSON.parse(sessionStorage.getItem('issueFilterConfig')|| '[]');
  }
  const getDropDownContent = (): any => {
    return [
      {
        value: 'automated',
        label: 'Run automated scan',
        iconName: 'icon-analysis-outline',
      },
      {
        value: 'manual',
        label: 'Go to manual review',
        iconName: 'icon-survey-checkmark-outline'
      }];
}

  const appId = location.state?.appId;
  const narrowScreenSelectedItemsHandler = (issueDashboardAreaItems: IIssueType[]) => {
    // not for narrow screen
    // if (issueDashboardAreaItems.length === 0) return [];
    // if (tabIndex === 0) return [filtersStatusPartData[0]];
    // if (tabIndex === 1) return filtersStatusPartData;
    // return [];

    return [];
    // not for narrow screen
  };

  const searchBtnInfo = useMemo(() => {
    if (activeIndex === 0)
      return {
        label: 'Run automated scan',
        icon: 'icon-analysis-outline',
      };
    if (activeIndex === 1)
      return {
        label: 'Open manual review',
        icon: 'icon-survey-checkmark-outline',
      };
  }, [activeIndex]);

  const btnClickHandler = () => {
    if (searchBtnInfo?.label === 'Open manual review') {
      navigate('/v2/manualIssue', {
        state: {
        origin: 'issues'
      }});
    }
    if (searchBtnInfo?.label === 'Run automated scan') {
      autoScanTblDataRef.current = [
        ...autoScanTblDataRef.current?.map((ele) => ({ ...ele, scanStatus: 'Pending...' })),
      ];
      setShowScanModal(true);
      setCloseScanModalMethod && setCloseScanModalMethod(false);
      setCloseScanModalStatus && setCloseScanModalStatus(false);
      localStorage.setItem('scanModalFrom', 'reRun');
    }
  };

  const [filtersStatusPartDataNew, setFiltersStatusPartDataNew] = useState<any[]>([]);
  const [filtersTestTypeDataNew, setFiltersTestTypeDataNew] = useState<any[]>([]);
  const [filtersSeverityDataNew, setFiltersSeverityDataNew] = useState<any[]>([]);
  const [filtersEffortLevelDataNew, setFiltersEffortLevelDataNew] = useState<any[]>([]);
  const [filtersWcagDataNew, setFiltersWcagDataNew] = useState<any[]>([]);
  const [filtersConformanceLevelDataNew, setFiltersConformanceLevelDataNew] = useState<any[]>([]);
  // const [narrowScreenSelectedItems, setNarrowScreenSelectedItems] = useState<ItemType[]>(
  //   narrowScreenSelectedItemsHandler(issueDashboardItems),
  // );
  const narrowScreenSelectedItemsRef = useRef<ItemType[]>([]);

  const [narrowFilterSelectedItems, setNarrowFilterSelectedItems] = useState<ItemType[]>(
    narrowScreenSelectedItemsHandler(issueDashboardItems),
  );

  const [autoScanedUrls, setAutoScanedUrls] = useState<any[]>([]);

  const autoScanTblDataRef = useRef<any[]>([]);

  const [searchParm, setSearchParm] = useState('');
  const [searchUsrInput, setSearchUsrInput] = useState('');

  const [showLoading, setShowLoading] = useState(false);

  const [showScanModal, setShowScanModal] = useState(false);

  const [isAssetAssessmentScanning, setIsAssetAssessmentScanning] = useState(false);
  const [isManualReviewStart, setIsManualReviewStart] = useState(false);

  const [showFilterLoading, setShowFilterLoading] = useState<any>(null);
  let deleteSubRef: Subscription;
  useEffect(() => {
    const appId = search.get('appId');
    let v2AccessRef: Subscription;
    if (appId) {
      v2AccessRef = productService
        .v2GetPrivateAssetMemberDetail(appId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res?.status === 1 && !res?.isMember) {
            navigate('/v2/forbidden', {
              state: {
                origin: 'issues',
                productName: res?.name,
                ownerEmail: res?.ownerName,
              },
            });
          }
        });
    }
    return () => {
      v2AccessRef?.unsubscribe();
      deleteSubRef?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    let getManualCheckForDashboardSub: Subscription;
    if (productId) {
      getManualCheckForDashboardSub = issueService.getManualCheckForDashboard({ productId }).subscribe((res) => {
        const { manualcheckStatus } = res;
        if (manualcheckStatus) {
          if (manualcheckStatus === 0) {
            setIsManualReviewStart(false);
          } else if ([1, 2].includes(manualcheckStatus)) {
            setIsManualReviewStart(true);
          }
        }
      });
    }

    return () => {
      getManualCheckForDashboardSub?.unsubscribe();
    };
  }, []);

  useEffect(() => {
    narrowScreenSelectedItemsRef.current = narrowScreenSelectedItems;
    sessionStorage.setItem('issueFilterConfig', JSON.stringify(narrowScreenSelectedItems));
  }, [narrowScreenSelectedItems]);

  const [pageShowCnt, setPageShowCnt] = React.useState<any>('5');
  const data = [
    { value: '5', label: '5' },
    { value: '10', label: '10' },
    { value: '15', label: '15' },
  ];

  const [currentPage, setCurrentPage] = React.useState(1);

  const getFilterItemName = (belongsTo: string, field = "name") => {
    return narrowScreenSelectedItemsValue().filter((itm: any) => itm?.belongsTo === belongsTo).map((ele: any) => ele[field]);
  };

  const getFilterStatusItemName = (belongsTo: string,) => {
    const statusItem = narrowScreenSelectedItemsValue().filter((itm: any) => itm?.belongsTo === belongsTo).map((ele: any) => ele.name);
    const queryStatusArr = [];
    if (statusItem.indexOf('Closed issues') > -1) {
      queryStatusArr.push(0);
    }
    if (statusItem.indexOf('Open issues') > -1) {
      queryStatusArr.push(1);
    }
    
    return queryStatusArr;
  };

  const issueDataHandler = (data: any) => {
    return data.map((item: any) => ({
      auditItemId: item.axeRule,
      id: item.id,
      issueType: item.status === 1 ? 'open' : 'close',
      issueLabel: item?.auditType === 'auto' ? item?.description : item?.summarize,//?
      issueSrc: item?.auditType === 'auto' ? item?.content : sanitizeHtml(item?.detailDescription),//?
      acNumber: item?.number,
      issueLevel: capitalizeFirstLetter(item?.severity),
      effortLevels: [item?.effort, item?.category],
      auditType: item?.auditType,
      issuePinnedAt: item?.issuePinnedAt,
      issuePinnedId: item?.issuePinnedId,
      pinnedUserId: item?.pinnedUserId,
      detail: {
        details: {
          description: `      Element has insufficient color contrast of 3.91 (foreground color: #d04a02, background color: #efefef, font
            size: 9.0pt (12px), font weight: normal). Expected contrast ratio of 4.5:1.`,
          issueCodeSnippet: item.content,
          scopeAndImpact: {
            conformaceLevel: `A`,
            wcagCriteriaNotMet: `1.1.1 Non-text content`,
            issueScope: 'Global issue that affects multiple pages.',
          },
        },
        occurrences: {
          impactedPages: [
            `https://hq.pwc.com/our-clients-and-business/delivering-one-firm-to-clients/strategic-negotiation-and-pricing-team.html`,
          ],
        },
      },
    }));
  };

  const filtersPartDataHandler = (
    data: ItemType[],
    issueDashboardAreaItems: IIssueType[],
    filterDataInResponse: any,
    isByResize: boolean = false,
  ) => {
    const len = issueDashboardAreaItems.length;
    const getCheckedStatus = (value: string) => {
      if (len === 0 && (isByResize? narrowScreenSelectedItemsRef.current.length : narrowScreenSelectedItemsValue().length) === 0 && !searchUsrInput) return false;
      // if (tabIndex === 0) {
      //   if (filtersStatusPartData.map((item: ItemType) => item.value).includes(value)) {
      //     return value === 'open';
      //   }
      // } else {
      //   const openItem = filtersStatusPartDataNew.find((itm) => itm.value === 'open');
      //   const closeItem = filtersStatusPartDataNew.find((itm) => itm.value === 'close');
      //   const statusArr = getFilterStatusItemName('status');
      //   if (value === 'open') {
      //     if (isManualReviewStart === false) {
      //       return false;
      //     }
      //     return openItem?.checked || (statusArr.length === 1 && statusArr[0] === 1);
      //   }
      //   if (value === 'close') {
      //     if (isManualReviewStart === false) {
      //       return false;
      //     } else {
      //       const statusparam = getFilterStatusItemName('status');
      //       if (statusparam.includes(0)) {
      //         return true;
      //       }
      //     }
      //     return closeItem.checked;
      //   }
      // }
      if ((isByResize? narrowScreenSelectedItemsRef.current : narrowScreenSelectedItemsValue()).map((item: ItemType) => item.value).includes(value)) {
        return true;
      }

      return false;
    };
    const getDisabledStatus = (value: string) => {
      // if (len === 0 && narrowScreenSelectedItemsValue().length === 0 && !searchUsrInput) return true;
      // if (filtersStatusPartData.map((item: ItemType) => item.value).includes(value)) {
      //   if (tabIndex === 0) {
      //     return !(value === 'open');
      //   }
      //   if (tabIndex === 1) {
      //     if (isManualReviewStart === false) {
      //       return true;
      //     }
      //     return false;
      //   }
      // }
      return false;
    };
    const getCnt = (value: string) => {
      // if (len === 0 && narrowScreenSelectedItemsValue().length === 0) return 0;
      const keys = Object.keys(filterDataInResponse);
      const idx = keys.findIndex((key) => key.toLocaleLowerCase().replaceAll(' ', '') === value.toLocaleLowerCase());
      return filterDataInResponse[keys[idx]];
    };

    return data.map((item: ItemType) => {
      const { value } = item;
      return {
        ...item,
        checked: getCheckedStatus(value),
        disabled: getDisabledStatus(value),
        cnt: getCnt(value) || 0,
      };
    });
  };

  useEffect(() => {
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    const resSubs = assetService
      .getAssetUrls(productId)
      .pipe(take(1))
      .subscribe((res) => {
        if (res.status === 1) {
          setAutoScanedUrls(res.data);
          autoScanTblDataRef.current = res.data.map((url: any, idx: number) => ({
            ...url,
            id: idx + 1,
            pageTitle: url?.nickName,
            url: url?.siteUrl,
            scanStatus: url?.scanStatus,
            action: 'Delete',
          }));
        }
      });

    return () => {
      resSubs?.unsubscribe();
    };
  }, []);

  const loadPageListSubRef = useRef<Subscription | null>(null);
  const loadPageList = () => {
    const productId =
      (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId') || currentProductIdAndAssessmentId?.curProductId;
    setShowLoading(true);
    setCurrentPage(currentPage === 0 ? 1 : currentPage);
    loadPageListSubRef.current = assetService
      .getLatestAssessment(productId)
      .pipe(
        take(1),
        switchMap((res) => {
          if (Object.keys(res).length === 0) return of(null);
          const assessmentId = res.id;
          setAssessmentId(String(res.id));
          localStorage.setItem('assessmentId', String(res.id));
          setLatestAssessmentId(String(res.id));
          setNewestAssessmentId && setNewestAssessmentId(String(res.id));
          const defaultInput: any = {
            assessmentId: Number(assessmentId),
            category: getFilterItemName('wcagcategory'),
            severity: getFilterItemName('severity'),
            effort: getFilterItemName('effortlevel'),
            auditType: getFilterItemName('testtype', 'value'),
            conformanceLevel: getFilterItemName('conformancelevel', 'value'),
            pageSize: Number(pageShowCnt),
            pageNumber: currentPage === 0 ? 1 : currentPage,
            description: searchParm,
            productId: productId,
            status: getFilterStatusItemName('status'),
          };
          return issueService.getPageListData(defaultInput);
        }),
        take(1),
      )
      .subscribe((res) => {
        if (res) {
          const _res = issueDataHandler(res.details);
          const _filterItemRes = res?.filterNum;
          setLastUpdateDateTime(res?.lastUpdateTime);
          setFiltersStatusPartDataNew(filtersPartDataHandler(filtersStatusPartData, _res, _filterItemRes));
          setFiltersTestTypeDataNew(filtersPartDataHandler(filtersTestTypeData, _res, _filterItemRes));
          setFiltersSeverityDataNew(filtersPartDataHandler(filtersSeverityData, _res, _filterItemRes));
          setFiltersEffortLevelDataNew(
            filtersPartDataHandler(filterEffortLevelData, _res, _filterItemRes),
          );
          setFiltersConformanceLevelDataNew(
            filtersPartDataHandler(filterConformanceLevelData, _res, _filterItemRes),
          );
          setFiltersWcagDataNew(filtersPartDataHandler(filtersWcagData, _res, _filterItemRes));

          setIssueDashboardItems(_res);
          setIsManualLastOne(_res?.[_res?.length-1]?.auditType === 'manual'&&_res?.length!==1);
          setPinIssueList(_res?.filter((item: any) => item.pinnedUserId !== null && item.issuePinnedAt !== null && item.issuePinnedId !== null));
          setUnpinIssueList(_res?.filter((item: any) => !item.pinnedUserId && !item.issuePinnedAt && !item.issuePinnedId));
          setFilterItemsInResponse(_filterItemRes);
          setIssueTotalNum(res?.filterNum?.issuesNum);
          setIssueAllPageCnt(Math.ceil(Number(res?.filterNum?.issuesNum) / pageShowCnt).toString());
          setShowLoading(false);
        } else {
          setIssueDashboardItems([]);
          setPinIssueList([]);
          setUnpinIssueList([]);
          setFiltersStatusPartDataNew(filtersPartDataHandler(filtersStatusPartData, [], []));
          setFiltersTestTypeDataNew(filtersPartDataHandler(filtersTestTypeData, [], []));
          setFiltersSeverityDataNew(filtersPartDataHandler(filtersSeverityData, [], []));
          setFiltersEffortLevelDataNew(filtersPartDataHandler(filterEffortLevelData, [], []));
          setFiltersConformanceLevelDataNew(filtersPartDataHandler(filterConformanceLevelData, [], []));
          setFiltersWcagDataNew(filtersPartDataHandler(filtersWcagData, [], []));
          setShowLoading(false);
        }
      });
  };

  const loadFilterPageListSubRef = useRef<Subscription | null>(null);
  const loadFilterPageList = (searchValue?: string) => {
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    setShowFilterLoading(true);
    setCurrentPage(currentPage === 0 ? 1 : currentPage);
    loadFilterPageListSubRef.current = assetService
      .getLatestAssessment(productId)
      .pipe(
        take(1),
        switchMap((res) => {
          if (Object.keys(res).length === 0) return of(null);
          const assessmentId = res.id;
          setAssessmentId(String(res.id));
          localStorage.setItem('assessmentId', String(res.id));
          setLatestAssessmentId(String(res.id));
          setNewestAssessmentId && setNewestAssessmentId(String(res.id));
          const defaultInput: any = {
            assessmentId: Number(assessmentId),
            category: getFilterItemName('wcagcategory'),
            severity: getFilterItemName('severity'),
            effort: getFilterItemName('effortlevel'),
            auditType: getFilterItemName('testtype', 'value'),
            conformanceLevel: getFilterItemName('conformancelevel', 'value'),
            pageSize: Number(pageShowCnt),
            pageNumber: currentPage === 0 ? 1 : currentPage,
            description: searchValue||searchParm,
            productId: productId,
            status: getFilterStatusItemName('status'),
          };
          return issueService.getPageListData(defaultInput);
        }),
        take(1),
      )
      .subscribe((res) => {
        if (res) {
          const _res = issueDataHandler(res.details);
          const _filterItemRes = res?.filterNum;
          setLastUpdateDateTime(res?.lastUpdateTime);
          setFiltersStatusPartDataNew(filtersPartDataHandler(filtersStatusPartData, _res, _filterItemRes));
          setFiltersTestTypeDataNew(filtersPartDataHandler(filtersTestTypeData, _res, _filterItemRes));
          setFiltersSeverityDataNew(filtersPartDataHandler(filtersSeverityData, _res, _filterItemRes));
          setFiltersEffortLevelDataNew(
            filtersPartDataHandler(filterEffortLevelData, _res, _filterItemRes),
          );
          setFiltersConformanceLevelDataNew(
            filtersPartDataHandler(filterConformanceLevelData, _res, _filterItemRes),
          );
          setFiltersWcagDataNew(filtersPartDataHandler(filtersWcagData, _res, _filterItemRes));
          setIssueDashboardItems(_res);
          setIsManualLastOne(_res?.[_res?.length-1]?.auditType === 'manual'&&_res?.length!==1);
          setPinIssueList(_res?.filter((item: any) => item.pinnedUserId !== null && item.issuePinnedAt !== null && item.issuePinnedId !== null));
          setUnpinIssueList(_res?.filter((item: any) => !item.pinnedUserId && !item.issuePinnedAt && !item.issuePinnedId));
          setFilterItemsInResponse(_filterItemRes);
          setIssueTotalNum(res?.filterNum?.issuesNum);
          setIssueAllPageCnt(Math.ceil(Number(res?.filterNum?.issuesNum) / pageShowCnt).toString());
          setShowFilterLoading(false); 
        } else {
          setIssueDashboardItems([]);
          setFiltersStatusPartDataNew(filtersPartDataHandler(filtersStatusPartData, [], []));
          setFiltersTestTypeDataNew(filtersPartDataHandler(filtersTestTypeData, [], []));
          setFiltersSeverityDataNew(filtersPartDataHandler(filtersSeverityData, [], []));
          setFiltersEffortLevelDataNew(filtersPartDataHandler(filterEffortLevelData, [], []));
          setFiltersConformanceLevelDataNew(filtersPartDataHandler(filterConformanceLevelData, [], []));
          setFiltersWcagDataNew(filtersPartDataHandler(filtersWcagData, [], []));
          setShowFilterLoading(false);
        }
        setTimeout(() => {
          if (res?.filterNum?.issuesNum > 0) {
            setMessageResult?.(`${res?.filterNum?.issuesNum} issues found`);
          } else {
            setMessageResult?.('No issues found. Try a different keyword or select a different filter option.');
          }
          setTimeout(() => {
            setMessageResult?.('');
          }, 200);
        }, 200);
      });
  };

  const dropdownSelecthandler = (value: any, item: any, event: any) => {
    event.stopPropagation();
    if (['automated'].includes(value)) {
      autoScanTblDataRef.current = [
        ...autoScanTblDataRef.current?.map((ele) => ({ ...ele, scanStatus: 'Pending...' })),
      ];
      setShowScanModal(true);
      setCloseScanModalMethod && setCloseScanModalMethod(false);
      setCloseScanModalStatus && setCloseScanModalStatus(false);
      localStorage.setItem('scanModalFrom', 'reRun');
    }
    if (['manual'].includes(value)) {
      navigate('/v2/manualIssue', {
        state: {
        origin: 'issues'
      }});
    }
  }

  // useEffect(() => {
  //   if (currentProductIdAndAssessmentId) {
  //     loadPageList();
  //   }
  // }, [currentProductIdAndAssessmentId]);

  useEffect(() => {
    if (saveIssueClicked) {
      loadPageList();
      if(saveIssueClicked)
        setSaveIssueClicked?.(false);
    }
  }, [saveIssueClicked]);

  useEffect(() => {
    if (currentProductIdAndAssessmentId) {
      loadFilterPageList();
    }
  }, [pageShowCnt, currentPage, activeIndex, narrowScreenSelectedItems, currentProductIdAndAssessmentId]);

  const _fetchFilterData = useCallback(
    _.debounce((value, event) => loadFilterPageList(value), 1500),
    [activeIndex],
  );
  
  const onSearchChange = (value: string, event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>) => {
    setSearchUsrInput(value);
    if (value) {
      setCurrentPage(1);
      setTimeout(function () {
        setSearchParm(value);
      }, 2000);
      _fetchFilterData(value, event);
    }
  };

  const onPageChange = (page: number) => {
    setCurrentPage(page);
  };

  const toPrePage = (v: any) => {};

  const toNexPage = (v: any) => {};

  const clearAll = () => {
    if (issueDashboardItems.length === 0 && narrowScreenSelectedItemsValue().length === 0) return;
    // if (narrowScreenSelectedItemsValue().length === 1 && narrowScreenSelectedItems[0].value === 'open') return;
    setFiltersStatusPartDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersTestTypeDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersConformanceLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setNarrowScreenSelectedItems(narrowScreenSelectedItemsHandler(issueDashboardItems));
  };

  const getNoSearchResultsPageText = () => {
    return {
      imgSrc: NoSearchResultsSvg,
      boldTitlePartText: 'No issues found.',
      descPartText: `Try a different keyword or select a different filter option.`,
    };
  };
  const getNoUsrInputSearchResultsPageText = () => {
    return {
      imgSrc: NoSearchResultsSvg,
      boldTitlePartText: 'No issues found.',
      descPartText: `Try a different keyword or select a different filter option.`,
    };
  };

  const toggleNarrowScreenFilter = (event: MouseEvent) => {
    event.stopPropagation();
    setShowNarrowFilter(!showNarrowFilter);
  };

  const onNarrowScreenTagClose = (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>,
    tag: ItemType,
  ) => {
    setNarrowFilterSelectedItems((prevs: any) => prevs.filter((prev: any) => prev.value !== tag.value));
    setNarrowScreenSelectedItems((prevs: any) => prevs.filter((prev: any) => prev.value !== tag.value));
  };
  const clearNarrowScreenSelectedItems = () => {
    // if (narrowScreenSelectedItemsValue().length === 0) return;
    // setNarrowFilterSelectedItems([]);
    // setNarrowScreenSelectedItems([]);
    // setFiltersStatusPartDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));

    if (issueDashboardItems.length === 0 && narrowFilterSelectedItems.length === 0) return;
    // if (narrowFilterSelectedItems.length === 1 && narrowFilterSelectedItems[0].value === 'open') return;
    setFiltersStatusPartDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersTestTypeDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersConformanceLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setNarrowFilterSelectedItems(narrowScreenSelectedItemsHandler(issueDashboardItems));
    setNarrowScreenSelectedItems(narrowScreenSelectedItemsHandler(issueDashboardItems));
  };

  const isInsideModal = (target: HTMLElement, className: string): boolean => {
    while (target) {
      if (target.classList && target.classList.contains(className)
      || (target.className.includes('icon-checkbox-selected-fill'))) {
        return true;
      }
      target = target.parentElement as HTMLElement;
    }
    return false;
  };

  useEffect(() => {
    function narrowScreenNotFilterPartClickHandler(event: MouseEvent) {
      const target = event.target as HTMLElement;
      if (narrowScreenFilterBtnRef.current?.contains(target)) {
        setShowNarrowFilter(!showNarrowFilter);
        return;
      }
      if (!narrowScreenFilterItmPartRef.current?.contains(target) && !isInsideModal(target, 'filterItemsPart')) {
        setShowNarrowFilter(false);
      }
    }

    function hideNarrowFilterPart(event: any) {
      narrowScreenFilterItmPartRef.current?.classList.remove('show');
      setFiltersStatusPartDataNew(filtersPartDataHandler(filtersStatusPartData, issueDashboardItems, filterItemsInResponse, true));
      setFiltersTestTypeDataNew(filtersPartDataHandler(filtersTestTypeData, issueDashboardItems, filterItemsInResponse, true));
      setFiltersSeverityDataNew(filtersPartDataHandler(filtersSeverityData, issueDashboardItems, filterItemsInResponse, true));
      setFiltersEffortLevelDataNew(
        filtersPartDataHandler(filterEffortLevelData, issueDashboardItems, filterItemsInResponse, true),
      );
      setFiltersConformanceLevelDataNew(
        filtersPartDataHandler(filterConformanceLevelData, issueDashboardItems, filterItemsInResponse, true),
      );
      setFiltersWcagDataNew(filtersPartDataHandler(filtersWcagData, issueDashboardItems, filterItemsInResponse, true));
      setNarrowFilterSelectedItems([...removeDuplicates(narrowScreenSelectedItems)]);
    }

    window.addEventListener('click', (event: MouseEvent) => narrowScreenNotFilterPartClickHandler(event));
    window.addEventListener('resize', (event: any) => hideNarrowFilterPart(event));
    return () => {
      window.removeEventListener('click', (event: MouseEvent) => narrowScreenNotFilterPartClickHandler(event));
      window.removeEventListener('resize', (event: any) => hideNarrowFilterPart(event));
    };
  }, []);

  useEffect(() => {
    return () => {
      loadPageListSubRef?.current?.unsubscribe();
      loadFilterPageListSubRef?.current?.unsubscribe();
    };
  }, []);

  const onNarrowScreenFiltersClearBtnHandler = () => {
    // setFiltersStatusPartDataNew((prev: ItemType[]) =>
    //   prev.map((itm) => ({ ...itm, checked: itm.value === 'open' ? true : false })),
    // );
    // setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    // setNarrowFilterSelectedItems([]);

    if (issueDashboardItems.length === 0 && narrowFilterSelectedItems.length === 0) return;
    // if (narrowFilterSelectedItems.length === 1 && narrowFilterSelectedItems[0].value === 'open') return;

    setFiltersStatusPartDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersTestTypeDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm,  checked: false })));
    setFiltersSeverityDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersWcagDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersEffortLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setFiltersConformanceLevelDataNew((prev: ItemType[]) => prev.map((itm) => ({ ...itm, checked: false })));
    setNarrowFilterSelectedItems(narrowScreenSelectedItemsHandler(issueDashboardItems));
  };

  const removeDuplicates = (items: any[]) => {
    const uniqueItems = Array.from(new Set(items.map(item => item.value)))
      .map(value => items.find(item => item.value === value));
    return uniqueItems;
  };

  const onNarrowScreenFiltersApplyBtnHandler = () => {
    setShowNarrowFilter(false);
    setNarrowScreenSelectedItems([...removeDuplicates(narrowFilterSelectedItems)]);
  };

  const pageShowNumSelectorHandler = (vals: any) => {
    const tempAllCnt = Number(vals) * Number(currentPage);
    const finalCurrentPage =
      tempAllCnt > Number(issueTotalNum) ? Math.ceil(Number(issueTotalNum) / Number(vals)) : Number(currentPage);

    setCurrentPage(finalCurrentPage);
    setPageShowCnt(vals);
  };

  const reloadPage = () => {
    loadPageList();
  };

  const onCheckboxChange = () => {};

  const onCheckboxStatusChange = (result: any) => {
    const openItem = result.items.find((item: any) => item.value === 'open');
    if (openItem?.checked && result.target.value === 'close') {
      localStorage.setItem('isCheckOpenStatus', 'true');
    }
  };

  const noIssuePageText = useMemo(() => {
    // if (activeIndex === 0) {
    //   return {
    //     imgSrc: Success,
    //     boldTitlePartText: 'Great work! There are no open issues from the last automated scan.',
    //     descPartText: `To identify any new issues, it is recommended to rerun an automated scan if you have recently added new URLs,
    //   features or pages to your product.`,
    //     runAutoScanPartBtnText: 'Run automated scan',
    //     btnIcon: 'icon-analysis-outline',
    //   };
    // }

    // if (activeIndex === 1) {
    const autoIssueCount = filtersTestTypeDataNew.find(item => item.value === "auto")?.cnt;
    const manualIssueCount = filtersTestTypeDataNew.find(item => item.value === "manual")?.cnt;
    if (isManualReviewStart === false && autoIssueCount === 0) {
      // return {
      //   imgSrc: Welcome,
      //   boldTitlePartText: 'It appears that you have not yet begun the manual review process.',
      //   descPartText: `To ensure a thorough evaluation of your product and identify any accessibility issues, 
      //   please initiate the manual review.`,
      //   runAutoScanPartBtnText: 'Start manual review',
      //   btnIcon: 'icon-survey-checkmark-outline',
      // };
      return {
        imgSrc: noIssueAuto,
        boldTitlePartText: 'Great start! No issues found on the automated scan.',
        descPartText: `To ensure a thorough evaluation of your application, please initiate the manual review.`,
        runAutoScanPartBtnText: 'Start manual review',
        btnIcon: 'icon-survey-checkmark-outline'
      };
    }
      // return {
      //   imgSrc: Success,
      //   boldTitlePartText: 'Great work! There are no open issues from the last manual review.',
      //   descPartText: `Continue conducting regular manual reviews to ensure accessibility compliance.`,
      //   runAutoScanPartBtnText: 'Start manual review',
      //   btnIcon: 'icon-survey-checkmark-outline',
      // };
    if(isManualReviewStart !== false && autoIssueCount === 0 && manualIssueCount === 0) {
      return {
        imgSrc: noIssueManual,
        boldTitlePartText: 'No accessibility issues.',
        descPartText: `Continue performing regular checks on your application. Any issues detected from automated scans and manual reviews will be listed here.`,
      };
    }
    // }
  }, [isManualReviewStart, filtersTestTypeDataNew]);

  const pageNumHandler = () => {
    setCurrentPage(1);
  };

  const onDeleteIssue = (issue: any) => {
    const param = {
      productId: Number(sessionStorage.getItem('productId')) || Number(currentProductIdAndAssessmentId?.curProductId),
      issueId: issue?.id
    };
    deleteSubRef = productService
    .deleteManualIssue(param)
    .pipe(take(1))
    .subscribe(
      (res) => {
        if (res.message === 'success') {  
          loadPageList();
        }
      },
      (error) => {
        console.log(error);
      },
    );
  }

  const getColNineContent = () => {
    const getNextContent = () => {
      if (issueDashboardItems?.length > 0) {
        return (
          <>
            <div className="lastScanDatearea">
              <span className="showMsgArea">
                Showing {Number(pageShowCnt) * Number(currentPage) - Number(pageShowCnt) + 1}-
                {Number(pageShowCnt) * Number(currentPage) > Number(issueTotalNum)
                  ? issueTotalNum
                  : Number(pageShowCnt) * Number(currentPage)}{' '}
                of <span className="total-area">{issueTotalNum}</span>
              </span>
              <div className="lastScanPart">
                <span>Last update:</span>
                <span>{lastUpdateDateTime && moment(lastUpdateDateTime).format('MMM DD, YYYY h:mm:ss A')}</span>
              </div>
            </div>
            {pinIssueList && pinIssueList.length > 0 && (
              <div className="ap-issue-list-title">Pinned</div>
            )}
            <div className="issueDashboardArea">
              {pinIssueList.map((issue: IIssueType, idx: number) => (
                <IssueDashboard
                  curIssueTypeTabIdx={activeIndex}
                  issue={issue}
                  key={`${idx}${issue.acNumber}`}
                  reloadPage={reloadPage}
                  onDeleteIssue={() => onDeleteIssue(issue)}
                  isPinned={true}
                  reloadPageFilter={loadFilterPageList}
                  isManualLastOne={idx === issueDashboardItems.length - 1 && isManualLastOne}
                />
              ))}
            </div>
            {unpinIssueList && unpinIssueList.length > 0 && pinIssueList.length > 0 && (
              <div className="ap-issue-list-title">All</div>
            )}
            <div className="issueDashboardArea">
              {unpinIssueList.map((issue: IIssueType, idx: number) => (
                <IssueDashboard
                  curIssueTypeTabIdx={activeIndex}
                  issue={issue}
                  key={`${idx}${issue.acNumber}`}
                  reloadPage={reloadPage}
                  onDeleteIssue={() => onDeleteIssue(issue)}
                  isPinned={false}
                  reloadPageFilter={loadFilterPageList}
                  isManualLastOne={idx + pinIssueList.length === issueDashboardItems.length - 1 && isManualLastOne}
                />
              ))}
            </div>
            <div className="pageNationPart">
              <div className="showPart">
                <span>Show</span>
                <div className="pageDropdownPart">
                  <Select
                    data={data}
                    value={pageShowCnt}
                    searchable={false}
                    defaultValue={['5']}
                    placeholder=""
                    hideTitleOnInput={true}
                    dropdownAlwaysDown={false}
                    onSelect={pageShowNumSelectorHandler}
                  ></Select>
                </div>
                <span>items per page</span>
              </div>
              <div className="pagePart">
                <Pagination
                  current={currentPage}
                  total={Number(issueAllPageCnt)}
                  onPageChange={onPageChange}
                  toPreviousPage=""
                  toNextPage=""
                ></Pagination>
              </div>
            </div>
          </>
        );
      }

      if (noIssuePageText&&isManualReviewStart === false && narrowScreenSelectedItemsValue().length === 0 && filtersTestTypeDataNew.find(item => item.value === "auto")?.cnt === 0) {

        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={noIssuePageText?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{noIssuePageText?.boldTitlePartText}</div>
            <div className="descPart">{noIssuePageText?.descPartText}</div>
            <div className="runAutoScanPart">
              <Button kind="primary" icon={noIssuePageText?.btnIcon} onClick={noIssueGotoPage}>
                {noIssuePageText?.runAutoScanPartBtnText}
              </Button>
            </div>
          </div>
        );
      }

      if (noIssuePageText&&issueDashboardItems?.length === 0 && narrowScreenSelectedItemsValue().length === 0 && !searchUsrInput) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={noIssuePageText?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{noIssuePageText?.boldTitlePartText}</div>
            <div className="descPart">{noIssuePageText?.descPartText}</div>
            {noIssuePageText?.btnIcon&&noIssuePageText?.runAutoScanPartBtnText&&<div className="runAutoScanPart">
              <Button kind="primary" icon={noIssuePageText?.btnIcon} onClick={noIssueGotoPage}>
                {noIssuePageText?.runAutoScanPartBtnText}
              </Button>
            </div>}
          </div>
        );
      }

      // if (
      //   issueDashboardItems?.length === 0 &&
      //   narrowScreenSelectedItemsValue().length === 1 &&
      //   narrowScreenSelectedItems[0].value === 'open' &&
      //   !searchUsrInput
      // ) {
      //   return (
      //     <div className="noIssuePage">
      //       <div className="imgPart">
      //         <img src={noIssuePageText?.imgSrc} alt="No issue icon" />
      //       </div>
      //       <div className="boldTitlePart">{noIssuePageText?.boldTitlePartText}</div>
      //       <div className="descPart">{noIssuePageText?.descPartText}</div>
      //       <div className="runAutoScanPart">
      //         <Button kind="primary" icon={noIssuePageText?.btnIcon} onClick={noIssueGotoPage}>
      //           {noIssuePageText?.runAutoScanPartBtnText}
      //         </Button>
      //       </div>
      //     </div>
      //   );
      // }
      if (getNoSearchResultsPageText()&&issueDashboardItems?.length === 0 && narrowScreenSelectedItemsValue().length > 0) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={getNoSearchResultsPageText()?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{getNoSearchResultsPageText()?.boldTitlePartText}</div>
            <div className="descPart">{getNoSearchResultsPageText()?.descPartText}</div>
          </div>
        );
      }

      if (getNoUsrInputSearchResultsPageText()&&searchParm && issueDashboardItems?.length === 0) {
        return (
          <div className="noIssuePage">
            <div className="imgPart">
              <img src={getNoUsrInputSearchResultsPageText()?.imgSrc} alt="No issue icon" />
            </div>
            <div className="boldTitlePart">{getNoUsrInputSearchResultsPageText()?.boldTitlePartText}</div>
            <div className="descPart">{getNoUsrInputSearchResultsPageText()?.descPartText}</div>
          </div>
        );
      }
    };
    // const loadingBtn = <Button
    //   kind="primary"
    //   icon={searchBtnInfo?.icon}
    //   onClick={btnClickHandler}
    //   loading={true}
    //   disabled={true}>
    //   {searchBtnInfo?.label}
    // </Button>;
   
    return (
      <>
        <div className="searchButtonContainer">
          <Button
            ref={narrowScreenFilterBtnRef}
            kind="secondary"
            className="searchButtonContainer-narrowFilter_iconPart"
            onClick={toggleNarrowScreenFilter}
            aria-label="Filter"
          >
            <span className="Appkit4-icon icon-filter-outline filterIcon-cls"></span>
          </Button>
          <div ref={narrowScreenFilterItmPartRef} className={`filterItemsPart ${showNarrowFilter ? 'show' : 'hide'}`}>
            <div className="filterItemsPart-content">
              <div className="statusPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowScreenSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Status'}
                  items={filtersStatusPartDataNew}
                  tabActiveIndex={activeIndex}
                  setFiltersItemsPartData={setFiltersStatusPartDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxStatusChange}
                />
              </div>
              <div className="testTypePart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowScreenSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Test type'}
                  items={filtersTestTypeDataNew}
                  setFiltersItemsPartData={setFiltersTestTypeDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              <div className="severityPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowScreenSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Severity'}
                  items={filtersSeverityDataNew}
                  setFiltersItemsPartData={setFiltersSeverityDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              <div className="effortLevelPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowScreenSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Effort level estimation'}
                  items={filtersEffortLevelDataNew}
                  setFiltersItemsPartData={setFiltersEffortLevelDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              <div className="conformanceLevelPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowScreenSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'Conformance level'}
                  items={filtersConformanceLevelDataNew}
                  setFiltersItemsPartData={setFiltersConformanceLevelDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
              <div className="wcagPart">
                <IssueFiltersDashboard
                  pageNumHandler={pageNumHandler}
                  searchUsrInput={searchUsrInput}
                  tabActiveIndex={activeIndex}
                  isManualReviewStart={isManualReviewStart}
                  narrowScreenSelectedItems={narrowScreenSelectedItems}
                  setNarrowScreenSelectedItems={setNarrowFilterSelectedItems}
                  allIssueCnt={issueDashboardItems.length}
                  title={'WCAG category'}
                  items={filtersWcagDataNew}
                  setFiltersItemsPartData={setFiltersWcagDataNew}
                  setIssueDashboardItems={setIssueDashboardItems}
                  onCheckboxChange={onCheckboxChange}
                />
              </div>
            </div>
            <div className="filterItemsPart-btnParts">
              <Button kind="secondary" onClick={onNarrowScreenFiltersClearBtnHandler}>
                Clear
              </Button>
              <Button kind="primary" onClick={onNarrowScreenFiltersApplyBtnHandler}>
                Apply
              </Button>
            </div>
          </div>
          <Search
            // disabled={!isManualReviewStart && activeIndex === 1}
            // disabled={(!isManualReviewStart && filtersTestTypeDataNew.find(item => item.value === "manual")?.cnt === 0) || (issueDashboardItems?.length === 0 && narrowScreenSelectedItemsValue().length === 0 && !searchUsrInput)}
            searchType={'secondary'}
            placeholder="Search issue"
            onChange={onSearchChange}
            className="searchStyle"
            searchValue={searchUsrInput}
            onClear={() => {
              setSearchUsrInput('');
              setShowFilterLoading(true);
              setTimeout(function () {
                setSearchParm('');
              }, 1000);
              _fetchFilterData('', null);
            }}
          />
          {/* {isManualReviewStart === false && searchBtnInfo?.label === 'Open manual review' ? null : (
            searchBtnInfo?.label === 'Run automated scan' && reScanBtnDisabled? loadingBtn : <Button
              kind="primary"
              icon={searchBtnInfo?.icon}
              onClick={btnClickHandler}
            >
              {searchBtnInfo?.label}
            </Button>
          )} */}
          <DropdownButton
            // ref={threeDropdownBtnRef}
            kind='secondary'
            dropdownClassName="dpdStyle"
            data={getDropDownContent()}
            onSelect={dropdownSelecthandler}
            prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
            ariaLabel='Options'
          >
            <span className={`Appkit4-icon icon-circle-more-outline`}></span>
          </DropdownButton>
        </div>
        <div className="narrowChoosedFilterItemsPart">
                    {
                      narrowScreenSelectedItems?.length > 0 && (<>
                        <div className="narrow-clearFilters-part" onClick={clearNarrowScreenSelectedItems}>
                          Clear filters
                        </div>
                        <div className="narrowScreenFIlterDivider"></div>
                      </>)
                    }
                    <div className="narrow-selectedItems-part">
                      {narrowScreenSelectedItems?.map((tag: any, idx: number) => (
                        <Tag
                          key={`${idx}-${tag.value}`}
                          // closable={tag.value !== 'open'}
                          onClose={(event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLButtonElement>) =>
                            onNarrowScreenTagClose(event, tag)
                          }
                        >
                          {tag.name}
                        </Tag>
                      ))}
                    </div>
        </div>
        {showFilterLoading ? <LoadingComponent /> : getNextContent()}
      </>
    );
  };

  const goToAutoScanPage = () => {
    navigate('/v2/autoMatedScan', {
      state: {
        origin: 'issues',
        curProductInfo: safelyParseJSON(getProductInfoCookie()!),
      },
    });
  };

  const noIssueGotoPage = () => {
    if (noIssuePageText?.runAutoScanPartBtnText === 'Run automated scan') {
      navigate('/v2/autoMatedScan', {
        state: {
          origin: 'issues',
          curProductInfo: safelyParseJSON(getProductInfoCookie()!),
        },
      });
    } else if (noIssuePageText?.runAutoScanPartBtnText === 'Start manual review') {
      navigate('/v2/manualIssue', {
        state: {
        origin: 'issues'
      }});
    }
  };

  return (
    <>
      <div className={`issuesContainer`} tabIndex={-1}>
        <div className="row">
          <h1 className="issueLabel">Issues</h1>
        </div>
        {showLoading ? (
          <LoadingComponent />
        ) : latestAssessmentId ? (
          <>
            <div className="row">
              <div className="col-9">
                {getColNineContent()}
                </div>
              <div className="col-3 notNarrowScreenFiltersContent">
                <div className="notNarrowScreenFiltersArea">
                  <div className="filterHeader">
                    <span>Filters</span>
                      <span
                        onClick={clearAll}
                        tabIndex={0}
                        role='button'
                        aria-label='Clear all'
                        onKeyDown={
                          (e) => {
                            if (e.key === 'Enter') {
                              clearAll();
                            }
                          }
                        }>
                        Clear all
                      </span>
                  </div>
                  <div className="dataContent">
                    <div className="statusPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Status'}
                        items={filtersStatusPartDataNew}
                        setFiltersItemsPartData={setFiltersStatusPartDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setFilter={setNarrowFilterSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxStatusChange}
                      />
                    </div>
                    <div className="testTypePart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setFilter={setNarrowFilterSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Test type'}
                        items={filtersTestTypeDataNew}
                        setFiltersItemsPartData={setFiltersTestTypeDataNew}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                    <div className="severityPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Severity'}
                        items={filtersSeverityDataNew}
                        setFiltersItemsPartData={setFiltersSeverityDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setFilter={setNarrowFilterSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                    <div className="effortLevelPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Effort level estimation'}
                        items={filtersEffortLevelDataNew}
                        setFiltersItemsPartData={setFiltersEffortLevelDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setFilter={setNarrowFilterSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                    <div className="conformanceLevelPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setFilter={setNarrowFilterSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'Conformance level'}
                        items={filtersConformanceLevelDataNew}
                        setFiltersItemsPartData={setFiltersConformanceLevelDataNew}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                    <div className="wcagPart">
                      <IssueFiltersDashboard
                        pageNumHandler={pageNumHandler}
                        searchUsrInput={searchUsrInput}
                        tabActiveIndex={activeIndex}
                        isManualReviewStart={isManualReviewStart}
                        narrowScreenSelectedItems={narrowScreenSelectedItems}
                        allIssueCnt={issueDashboardItems.length}
                        title={'WCAG category'}
                        items={filtersWcagDataNew}
                        setFiltersItemsPartData={setFiltersWcagDataNew}
                        setNarrowScreenSelectedItems={setNarrowScreenSelectedItems}
                        setFilter={setNarrowFilterSelectedItems}
                        setIssueDashboardItems={setIssueDashboardItems}
                        onCheckboxChange={onCheckboxChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {showNotification && <NotificationNew />}
          </>
        ) : (
          showFilterLoading || showFilterLoading === null? <LoadingComponent /> : 
          <div className="issuesContainer-noIssuesToManagePart">
            <NoResultsPage
              imgSrc={NoIssueToManageSvg}
              imgAlt="No issues to manage logo"
              titleArea="No issues to manage."
              descArea={`To get started, please select set up an automated scan to begin your journey to accessible and inclusive experiences. `}
              btnArea={
                <Button kind="primary" onClick={goToAutoScanPage}>
                  Set up automated scan
                </Button>
              }
            />
          </div>
        )}
      </div>

      {getProductInfoCookie()&&<ScanModal
        curProductInfo={safelyParseJSON(getProductInfoCookie()!)}
        footer={<></>}
        visible={showScanModal && !closeScanModalMethod}
        setVisibleFunc={setShowScanModal}
        className="reRunScanModal"
        title="Confirmation"
        imgSrc={ReRunScanSvg}
        boldText="Confirm the pages to scan."
        descText="Ensure manual review issues are still applicable if adding or removing pages to scan. "
        originTableData={autoScanTblDataRef.current}
        isAssetAssessmentScanning={isAssetAssessmentScanning}
        setIsAssetAssessmentScanning={setIsAssetAssessmentScanning}
        origin="reRun"
        needGetAssetUrls={true}
      />}
    </>
  );
};

export default Issues;
