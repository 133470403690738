import React, { useState, useEffect, useMemo, useCallback, useContext } from 'react';
import './app.scss';
import FooterPage from '../Footer';
import { Outlet, useLocation } from 'react-router-dom';
import { userService } from '@services';
import { EntryContext } from '@layout/Entry';
import LoadingComponent from '@components/Loading';
import { authService } from '@services/auth.service';
import { of, Subscription } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import { AuthUserType, UserType } from '@models';
import _ from 'lodash';
import DashboardWrapper from '@components/DashboardWrapper';
import { decryptData, encryptData, getCookie, safelyParseJSON, setCookie } from '@utils/common';

function App() {
  const { user, setUser, authUser, setAuthUser, isLogin } = useContext(EntryContext);
  const [myAssets, setMyAssets] = useState<Array<any>>([]);
  const [authUserRetryTimes, setAuthUserRetryTimes] = useState<number>(0);

  const [isLoadingAuthUser, setIsLoadingAuthUser] = useState<boolean>(false);

  const [loading, setLoading] = useState(false);

  const { pathname, search } = useLocation();

  const localStorageIdToken: string = useMemo(() => {
    return getCookie('idToken') || '';
  }, []);

  const localStorageAuthUser: AuthUserType = useMemo(() => {
    return safelyParseJSON(decryptData(getCookie('azureAuthUser')!) || '{}');
  }, []);

  const getAuthUserInfo = () => {
    authService
      .getAuthUserInfo()
      .pipe(
        take(1),
        concatMap((res) => {
          if (res) {
            return of(res);
          } else {
            return authService.refreshAppServiceAuthSession();
          }
        }),
        concatMap((refreshServiceAuthSessionRes: AuthUserType | any) => {
          if (refreshServiceAuthSessionRes) {
            if (refreshServiceAuthSessionRes.uid) {
              return of(refreshServiceAuthSessionRes);
            } else {
              return authService.getAuthUserInfo();
            }
          } else {
            return of(null);
          }
        }),
        catchError((error: any) => {
          setAuthUserRetryTimes(authUserRetryTimes + 1);
          return of(null);
        }),
      )
      .subscribe((res) => {
        const authUser: AuthUserType = res;
        if (authUser) {
          setAuthUser(authUser);
          if (!getCookie('azureAuthUser')) {
            setCookie('azureAuthUser', encryptData(authUser));
          }
        }
        setIsLoadingAuthUser(false);
      });
  };

  useEffect(() => {
    if (!isLogin) {
      if (!authUser) {
        if (localStorageAuthUser && !!localStorageAuthUser.cloudEmail) {
          setAuthUser(localStorageAuthUser);
        } else if (!isLoadingAuthUser && authUserRetryTimes < 3) {
          setIsLoadingAuthUser(true);
          if(getCookie('idToken'))
            getAuthUserInfo();
        }
      }
    }
  }, [
    isLoadingAuthUser,
    authUserRetryTimes,
    localStorageIdToken,
    localStorageAuthUser,
    localStorageIdToken,
    localStorageAuthUser,
    getAuthUserInfo,
    authUser,
    isLogin,
    pathname,
    search,
    setAuthUser,
    isLoadingAuthUser,
    authUserRetryTimes,
  ]);

  const personalProjectHandler = (projects: any[]) => {
    return projects?.map((project) => ({ ...project, isAccessible: true }));
  };

  /**
   * if authUser then check the  user's products created from tools
   */
  useEffect(() => {
    setLoading(true);
    let getMembershipSub: Subscription;
    if (authUser) {
      getMembershipSub = userService
        .getMembership()
        .pipe(take(1))
        .subscribe((_res) => {
          if (_res && _res.status) {
            setMyAssets(personalProjectHandler(_res.memberships) || []);
          }
          setLoading(false);
        });
    }
    return () => {
      if (getMembershipSub) {
        getMembershipSub.unsubscribe();
      }
    };
  }, [authUser]);

  // disable the pendo for now, wait for the next guidance
  useEffect(() => {
    if (authUser) {
      const env = process.env.REACT_APP_env || 'dev';
      if (['dev', 'stg', 'qa'].includes(env)) {
        (window as any).pendo.initialize({
          visitor: {
            id: `${authUser?.cloudEmail}-${env}`,
            email: authUser?.cloudEmail,
            full_name: authUser?.name,
          },
          events: {
            ready: function () {},
          },
        });
      }
    }
  }, [authUser]);

  const getIsNewCls = () => {
    return ['/v2/', '/v2/autoMatedScan', '/v2/manualIssue'].includes(pathname);
  };

  return (
    <div className={'app-container'}>
      {pathname && (
        <div className={`content ${getIsNewCls() ? 'homePage' : ''}`}>
          {loading ? <LoadingComponent className="loadingCls" /> : <DashboardWrapper productList={myAssets} />}
        </div>
      )}
    </div>
  );
}

export default App;
