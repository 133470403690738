import Axios from 'axios';
import { AuthUserType } from '@models';
import { decryptData, encryptData, getCookie, safelyParseJSON } from '@utils/common';

const baseURL = process.env.REACT_APP_app_server_url || process.env.REACT_APP_app_url;
const authAxios = Axios.create({
  baseURL,
});

// request拦截
authAxios.interceptors.request.use(
  (config: any) => {
    if (config && config.url && !config.url.includes('http')) {
      // const token = window.localStorage.getItem('accessToken');
      const token = 'Bearer ' + getCookie('idToken');//window.localStorage.getItem('idToken');
      config.headers.authorization = token;
      const localStorageUserStr: string = decryptData(getCookie('azureAuthUser')!) || '';
      try {
        const localStorageUser: AuthUserType = safelyParseJSON(localStorageUserStr);
        if (localStorageUser && Object.keys(localStorageUser).length) {
          config.headers['user_id'] = localStorageUser.preferredMail;
        }
      } catch (error) {}
    }
    config.headers['login_type'] = sessionStorage.getItem('loginType') || 'internal';
    return config;
  },
  (error) => Promise.reject(error),
);

// response拦截
authAxios.interceptors.response.use(
  (response) => response.data || {},
  (error) => {
    if (error && error.response) {
      console.error(error.response);
    }
    return Promise.reject(error);
  },
);
export { authAxios };
