import React, { useEffect, useRef, useState } from 'react';
import './floatingMenu.scss'; // Assuming you have a CSS file for styles
import { Button, Tooltip } from '@appkit4/react-components';
import classNames from 'classnames';

const FloatingMenu = ({ onClickEvent, onClickBottomIcon, selectedPanel  }: any) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const onKeyDown = (event: React.KeyboardEvent, index: number) => {
    event.stopPropagation();
    const key = event.key.toLowerCase();
    if (key === 'enter') {
        onClickEvent(index.toString());
        setTimeout(() => {
            (containerRef.current?.querySelectorAll('.floating-menu-icon-container')?.[index] as HTMLElement)?.focus();
        }, 0);
    }
  };

  return (
    <div className={`floating-menu-container open`} ref={containerRef}>
      <div className="floating-menu-list-container">
        <Tooltip
            trigger='hover'
            position='right'
            distance={8}
            id="tooltipDesc"
            content="Dashboard"
        >
        <Button kind='text' 
        className={classNames("floating-menu-icon-container", { "selected": selectedPanel === '0' })} 
        icon="icon-speedometer-outline" 
        onClick={() => onClickEvent('0')}
        onKeyDown={(e: any) => onKeyDown(e, 0)}
        aria-label="Dashboard">
        </Button>
        </Tooltip>
        <Tooltip
            trigger='hover'
            position='right'
            distance={8}
            id="tooltipDesc"
            content="Issues"
        >
        <Button kind='text' 
        className={classNames("floating-menu-icon-container", { "selected": selectedPanel === '1' })} 
        icon="icon-alert-outline" 
        onClick={() => onClickEvent('1')}
        onKeyDown={(e: any) => onKeyDown(e, 1)}
        aria-label="Issues"
        ></Button>
        </Tooltip>
        <Tooltip
            trigger='hover'
            position='right'
            distance={8}
            id="tooltipDesc"
            content="Settings"
        >
        <Button kind='text' 
        className={classNames("floating-menu-icon-container", { "selected": selectedPanel === '2' })} 
        icon="icon-setting-outline" 
        onClick={() => onClickEvent('2')}
        onKeyDown={(e: any) => onKeyDown(e, 2)}
        aria-label="Settings"
        ></Button>
        </Tooltip>
      </div>

      <div className='bottom-icon'>
      <Tooltip
            trigger='hover'
            position='right'
            distance={8}
            id="tooltipDesc"
            content="Issues and feedback"
        >
      <Button kind='text' 
        className={classNames("floating-menu-icon-container")} 
        icon="icon-comment-outline" 
        onClick={()=>onClickBottomIcon?.()}
        tabIndex={0}
        aria-label='Feedback'>
        </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default FloatingMenu;