export enum TeamMemberRoleEnum {
  DESIGNER = 'Designer',
  DEVELOPER = 'Developer',
  PRODUCT_MANAGER = 'Product manager',
  DIRECTOR = 'Director',
  QUALITY_ASSURANCE = 'Quality assurance',
  OWNER = 'Owner',
  OTHER = 'Other',
}

export const rolesBtn = [
  {
    value: 'designer',
    label: TeamMemberRoleEnum.DESIGNER,
  },
  {
    value: 'developer',
    label: TeamMemberRoleEnum.DEVELOPER,
  },
  {
    value: 'productManager',
    label: TeamMemberRoleEnum.PRODUCT_MANAGER,
  },
  {
    value: 'director',
    label: TeamMemberRoleEnum.DIRECTOR,
  },
  {
    value: 'qualityAssurance',
    label: TeamMemberRoleEnum.QUALITY_ASSURANCE,
  },
  {
    value: 'other',
    label: TeamMemberRoleEnum.OTHER,
  },
];
export const rolesArr = [
  {
    value: 'owner',
    label: TeamMemberRoleEnum.OWNER,
  },
  {
    value: 'designer',
    label: TeamMemberRoleEnum.DESIGNER,
  },
  {
    value: 'developer',
    label: TeamMemberRoleEnum.DEVELOPER,
  },
  {
    value: 'productManager',
    label: TeamMemberRoleEnum.PRODUCT_MANAGER,
  },
  {
    value: 'director',
    label: TeamMemberRoleEnum.DIRECTOR,
  },
  {
    value: 'qualityAssurance',
    label: TeamMemberRoleEnum.QUALITY_ASSURANCE,
  },
  {
    value: 'other',
    label: TeamMemberRoleEnum.OTHER,
  },
];
