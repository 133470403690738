export const authenticationTypesData = [
  {
    title: "PwC IdAM or IAM's federated PwC Identity service.",
    desc: 'Your application uses access management services provided by PwC. ',
    icon: 'icon-shield-half-outline',
    value: 'idamOrIam',
    clicked: false,
  },
  {
    title: 'IAM or other authorization services. ',
    desc: 'Your application uses access management services provided by external companies or third-party services. ',
    icon: 'icon-shield-outline',
    value: 'iamOrOther',
    clicked: false,
  },
  {
    title: 'Unsure about what type of authentication is used.',
    desc: "Your application may utilize one of the previously mentioned authentication methods, but you're uncertain which one.",
    icon: 'icon-help-question-outline',
    value: 'unsure',
    clicked: false,
  },
];
export const authenticationTypesDataForSettingsPage = [
  {
    title: "PwC IdAM or IAM's federated PwC Identity service.",
    desc: 'Your application uses access management services provided by PwC. ',
    icon: 'icon-shield-half-outline',
    value: 'idamOrIam',
    clicked: false,
  },
  {
    title: 'IAM or other authorization services. ',
    desc: 'Your application uses access management services provided by external companies or third-party services. ',
    icon: 'icon-shield-outline',
    value: 'iamOrOther',
    clicked: false,
  },
];

export const idamOrIamExpandData = [
  {
    label: "Go to your application's admin panel.",
    value: 'goTo',
    links: [],
  },
  {
    label: 'Add the accounts below to your user profiles and grant them admin permissions: ',
    value: 'addAccounts',
    links: ['USDIPAccessibility.Assessor@pwc.com', 'USDIPAccessibility.ProdAssessor@pwc.com'],
  },
  {
    label: 'Return and confirm set up is complete on the checkbox below.',
    value: 'returnConform',
    links: [],
  },
];
