import { Routes, Navigate, Route } from 'react-router-dom';
import AuthRoute from '../components/AuthRoute';
import { RouteType } from '../models';
import { useMemo, useCallback, useContext } from 'react';
import { EntryContext } from './Entry';
import App from '../components/App';
import Dashboard from '@components/Dashboard';
import Issues from '@components/Issues';
import { Login } from '@layout/Login';
import ProductList from '@components/ProductList';
import DashboardWrapper from '@components/DashboardWrapper';
import IssueDetail from '@components/Issues/IssueDetail';
import Home from '@components/Home';
import SettingsPage from '@components/SettingsPage';
import ForbiddenPage from './ForbiddenPage';
import AutomatedAssessmentPage from './GettingStarted/AutomatedAssessment';
import ManualReviewPage from './GettingStarted/ManualReview';
import FaqPage from './HelpCenter/FaqPage';
import ReleaseNotes from './HelpCenter/ReleaseNotes';
import IssueNotFound from '@components/IssueNotFound';
// import ManualReviewPage from './GettingStarted/ManualReview';

// TODO add settings page
function EntryRouters() {
  return (
    <Routes>
      <Route path="/v2/signin" element={<App />}></Route>
      <Route path="/v2/" element={<App />}></Route>
      <Route path="/v2/autoMatedScan" element={<App />}></Route>
      <Route path="/v2/manualIssue" element={<App />}></Route>
      <Route path="/v2/forbidden" element={<ForbiddenPage />}></Route>
      <Route path="/v2/dwrapper" element={<DashboardWrapper />}>
        <Route path="board" element={<Dashboard />}></Route>
        <Route path="issues" element={<Issues />}></Route>
        <Route path="issuedetail" element={<IssueDetail />}></Route>
        <Route path="settings" element={<SettingsPage />}></Route>
        <Route path="issuenotfound" element={<IssueNotFound />}></Route>
      </Route>
      <Route path="/v2/getting-started" element={<DashboardWrapper />}>
        <Route path="automated-assessment" element={<AutomatedAssessmentPage />}></Route>
        <Route path="manual-review" element={<ManualReviewPage />}></Route>
      </Route>
      <Route path="/v2/help-center" element={<DashboardWrapper />}>
        <Route path="faq" element={<FaqPage />}></Route>
        <Route path="release-notes" element={<ReleaseNotes />}></Route>
      </Route>
    </Routes>
  );
}

export default EntryRouters;
