import React from 'react';
import { Loading } from '@appkit4/react-components/loading';
import './loading.scss';

const LoadingComponent = (props: any) => {
  const { className, message } = props;
  return (
    <div className={`loading-container ${className ? className : ''}`}>
      <Loading loadingType="circular" indeterminate={true} compact={false}></Loading>
      <span className="loading-container-message ap-ml-spacing-3">{message}</span>
    </div>
  );
};

export default LoadingComponent;
