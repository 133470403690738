import { Observable } from 'rxjs';
import { apiService } from './rxjs.service';

export interface IssuesFilterInputType {
  productId: number;
  assessmentId: number | null;
  category?: string;
  url?: string;
  impact?: string[];
  assessment?: string;
  conformance?: string;
  file?: string;
  result?: string;
  sorting?: {
    column?: string;
    order?: string | null;
  };
  global?: string;
}

function getIssuesFilters(productId: number, assessmentId: number | null): Observable<any> {
  let apiUrl = `/report/filters?productId=${productId || ''}`;
  if (!!assessmentId) {
    apiUrl += `&assessmentId=${assessmentId}`;
  }
  return apiService.get(apiUrl);
}

function getIssuesByPage(productId: number, assessmentId: number | null): Observable<any> {
  let apiUrl = `/report/allByPage?productId=${productId || ''}`;
  if (!!assessmentId) {
    apiUrl += `&assessmentId=${assessmentId}`;
  }
  return apiService.get(apiUrl);
}

function getIssuesByCategory(productId: number, assessmentId: number | null): Observable<any> {
  let apiUrl = `/report/allByCategory?productId=${productId || ''}`;
  if (!!assessmentId) {
    apiUrl += `&assessmentId=${assessmentId}`;
  }
  return apiService.get(apiUrl);
}

function getIssuesByRequirement(productId: number, assessmentId: number | null): Observable<any> {
  let apiUrl = `/report/allByRequirement?productId=${productId || ''}`;
  if (!!assessmentId) {
    apiUrl += `&assessmentId=${assessmentId}`;
  }
  return apiService.get(apiUrl);
}

function getFilteredIssuesByPage(input: IssuesFilterInputType): Observable<any> {
  let apiUrl = `/report/allByPage/queryToFilter`;
  const defaultInput = {
    productId: null,
    assessmentId: null,
    category: 'all',
    url: 'all',
    impact: 'all',
    assessment: 'all',
    global: 'true',
  };
  return apiService.post(apiUrl, Object.assign({}, defaultInput, input));
}

function getFilteredIssuesByCategory(input: IssuesFilterInputType): Observable<any> {
  let apiUrl = `/report/allByCategory/queryToFilter`;
  const defaultInput = {
    assessmentId: null,
    category: 'all',
    url: 'all',
    impact: 'all',
    assessment: 'all',
    global: 'true',
  };
  return apiService.post(apiUrl, Object.assign({}, defaultInput, input));
}

function getFilteredIssuesByRequirement(input: IssuesFilterInputType): Observable<any> {
  let apiUrl = `/report/allByRequirement/queryToFilter`;
  const defaultInput = {
    assessmentId: null,
    category: 'all',
    url: 'all',
    impact: 'all',
    assessment: 'all',
    global: 'true',
  };
  return apiService.post(apiUrl, Object.assign({}, defaultInput, input));
}

function getFilteredIssuesDataStream(param: { [x: string]: any }): Observable<any> {
  let apiUrl = `/report/filters/exportCsvToFilter`;
  return apiService.post(apiUrl, param);
}

function exportCsv(data: any, filename: string) {
  const blob = new Blob([`\uFEFF${data}`], {
    type: 'text/csv; cgarset=utf-8',
  });

  const csvData = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', csvData);
  link.setAttribute('download', filename);
  link.click();
}

export type GetPageListDataType = {
  assessmentId: number;
  category: string[];
  severity: string[];
  effort: string[];
  assessment: string;
  pageSize: number;
  pageNumber: number;
  description: string;
  productId: number;
};

function getPageListData(param: GetPageListDataType): Observable<any> {
  let apiUrl = `/report/allByFilter/queryToFilter`;
  const defaultInput = {
    assessmentId: 14680,
    category: ['ARIA'],
    severity: ['serious'],
    effort: [],
    assessment: 'auto',
    pageSize: 10,
    pageNumber: 1,
    description: 'APK0000045',
    productId: 6878,
  };
  return apiService.post(apiUrl, param);
}

export type GetCommentDataType = {
  productId: number;
  assessmentId: number;
  number: string;
};

function getComment(param: GetCommentDataType): Observable<any> {
  let apiUrl = `/report/allByFilter/getComment`;

  return apiService.post(apiUrl, param);
}

export type AddCommentDataType = {
  productId: number;
  assessmentId: number;
  number: string;
  comment: string;
};

function addComment(param: AddCommentDataType): Observable<any> {
  let apiUrl = `/report/allByFilter/addComment`;

  return apiService.post(apiUrl, param);
}

function uploadImage(file: any): Observable<any> {
  let apiUrl = `/questionnaire/issuePages/uploadFiles/v2`;
  return apiService.post(apiUrl, file);
}

export type setManualStatusDataType = {
  productId: number;
  number: string;
  status: number;
  comment: string;
};
function setManualStatus(param: setManualStatusDataType): Observable<any> {
  let apiUrl = `/product/v2/setManualStatus`;
  return apiService.post(apiUrl, param);
}

function getIssueDetail(param: any): Observable<any> {
  let apiUrl = `/report/allByFilter/getDetail`;
  const { assessmentId, productId, number } = param;
  const finalParam = {
    assessmentId,
    productId,
    number,
  };
  return apiService.post(apiUrl, finalParam);
}

export type GetCMannualListDataType = {
  productId: number;
};
function getManualIssueOriginalData(param: GetCMannualListDataType, isGuideLine = false): Observable<any> {
  let apiUrl = `/questionnaire/form?productId=${param}&version=2`;
  if(isGuideLine) apiUrl = `/questionnaire/form?productId=${param}&version=2&classifiedBy=guideline`;
  return apiService.get(apiUrl);
}

function getIssueUrlListData(param: GetCMannualListDataType): Observable<any> {
  let apiUrl = `/product/sites?productId=${param}`;
  return apiService.get(apiUrl);
}
const changeToResolvedStatus = (param: any): Observable<any> => {
  return apiService.post('/questionnaire/item/setIssueStatus', param);
};
const pinIssue = (param: any): Observable<any> => {
  if (param.isPin)
    return apiService.post('/report/pin', param);
  else
    return apiService.deleteByRequestBody('/report/pin', param);
};

const getAuditItemIssueList = (param: any): Observable<any> => {
  return apiService.post('/questionnaire/item/getItemIssue', param);
};

const setManualItemStatus = (param: any): Observable<any> => {
  return apiService.post('/questionnaire/item/setItemStatus', param);
};

const getSectionIssuesStatus = (param: any): Observable<any> => {
  return apiService.post('/questionnaire/item/getManualCheck', param);
};

function getManualCheckForDashboard(param: any): Observable<any> {
  let apiUrl = `/questionnaire/item/getManualCheck`;
  const { productId } = param;
  const finalParam = {
    productId,
  };
  return apiService.post(apiUrl, finalParam);
}

function getDashboardPageIssueNum(param: any): Observable<any> {
  let apiUrl = `/report/allByFilter/getIssueNum`;
  const { productId, assessmentId } = param;
  const finalParam = {
    productId,
    assessmentId,
  };
  return apiService.post(apiUrl, finalParam);
}

function getAISuggestion(param: any): Observable<any> {
  let apiUrl = `/assessment/aiSuggestion/getAiSuggestion`;
  const { assessmentReportId, number } = param;
  const finalParam = {
    assessmentReportId,
    number
  };
  return apiService.post(apiUrl, finalParam);
}

function getAssignedUserList(param: number): Observable<any> {
  let apiUrl = `/report/allByFilter/comment/mention/user?productId=${param}`;
  return apiService.get(apiUrl);
}

export const issueService = {
  getIssuesFilters,
  getIssuesByPage,
  getIssuesByCategory,
  getIssuesByRequirement,
  getFilteredIssuesByPage,
  getFilteredIssuesByCategory,
  getFilteredIssuesByRequirement,
  getFilteredIssuesDataStream,
  exportCsv,
  getPageListData,
  getComment,
  addComment,
  uploadImage,
  getIssueDetail,
  getManualIssueOriginalData,
  getIssueUrlListData,
  setManualStatus,
  getDashboardPageIssueNum,
  getManualCheckForDashboard,
  changeToResolvedStatus,
  getAuditItemIssueList,
  setManualItemStatus,
  getSectionIssuesStatus,
  getAISuggestion,
  pinIssue,
  getAssignedUserList
};
