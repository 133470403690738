import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import './index.scss';
import CommonModal from '@components/modals/CommonModal';
import { Input } from '@appkit4/react-components/field';
import { Button } from '@appkit4/react-components/button';
import { Subscription, interval, of } from 'rxjs';
import { take, switchMap, concatMap } from 'rxjs/operators';
import { assetService } from '@services/asset.service';
import { productService } from '@services';
import { EntryContext } from '@layout/Entry';
import moment from 'moment';
import classNames from 'classnames';

const VerificationModal = (props: any) => {
  const {
    showVerificationCode,
    setShowVerificationCode,
    assetScanningErrorTime,
    originTData,
    verificationResultType,
    verificationCodeInfo,
    setVerificationCodeInfo,
    setCloseScanModalMethod
  } = useContext(EntryContext);
  const {productName, authUser, productId} = props;

  const [passcodeExpiredTime, setPasscodeExpiredTime] = useState<number | null>(null);
  const [verificationCode, setVerificationCode] = useState('');
  const [loadingState, setLoadingState] = useState(false);
  const [loadingRequestState, setLoadingRequestState] = useState(false);
  const autoScanTaskCreateRef = useRef<Subscription>();
  const assessmentIdRef = useRef<number>();
  const expireTotalTime = useRef<number>();
  const inputCodeRef = useRef<any>();
  const passcodeExpiredTimeCountSubRef = useRef<Subscription|null>();
  let getCompletedAssessmentSub: Subscription;
  const onChangeInput = (value: any, e: any) => {
    setVerificationCode(value);
    setInvalidCode(false);
    if(passcodeExpiredTimeString && passcodeExpiredTimeString !== '0:00') {
      setIsLastAssessmentHasError?.(false);
    }
  };
  const calcExpiredTimeFunc = (diffMilliSeconds: number) => {
    const remainingTime = (expireTotalTime.current||0) - diffMilliSeconds;
    expireTotalTime.current = remainingTime;
    setPasscodeExpiredTime(remainingTime);
    return remainingTime;
  };

  useEffect(() => {
    setLoadingRequestState(false);
    setInvalidCode(false);
    setIsLastAssessmentHasError(false);
  }, [showVerificationCode])

  useEffect(() => {
    if(verificationResultType === 'invalidPasscode') {
      setIsLastAssessmentHasError(true);
      setInvalidCode(true);
      setLoadingState(false);
      setVerificationCode(verificationCodeInfo||'');
    }
  }, [verificationResultType])

  const submitClick = () => {
    setCloseScanModalMethod && setCloseScanModalMethod(true);
    passcodeExpiredTimeCountSubRef.current?.unsubscribe();
    passcodeExpiredTimeCountSubRef.current = null;
    setLoadingState(true);
    setVerificationCodeInfo?.(verificationCode);
    getCompletedAssessmentSub = assetService
    .getLatestAssessment(Number(productId))
    .pipe(
      take(1),
      switchMap((res) => {
        assessmentIdRef.current = res.id;
        return assetService.sendPasscode({ assessmentId: assessmentIdRef.current.toString(), passcode: verificationCode, ttl: passcodeExpiredTime||0 });
      })
    ).subscribe((res: any) => {
      // setVerificationCode('');
    });
  };

  const [isLastAssessmentHasError, setIsLastAssessmentHasError] = useState(false);
  const [invalidCode, setInvalidCode] = useState(false);
  const passcodeExpiredTimeString = useMemo(() => {
    let privatePasscodeExpiredTime = passcodeExpiredTime;
    // console.log('privatePasscodeExpiredTime', privatePasscodeExpiredTime);
    if (privatePasscodeExpiredTime) {
      if (privatePasscodeExpiredTime > 0) {
        privatePasscodeExpiredTime =
          Math.floor(privatePasscodeExpiredTime / 1000) * 1000;
        const duration = moment.duration(
          privatePasscodeExpiredTime,
          'milliseconds',
        );
        const returnValue = moment
        .utc(duration.as('milliseconds'))
        .format('m:ss')
        .toString();
        // console.log(returnValue, passcodeExpiredTime);
        if(returnValue === '0:00') {
          setIsLastAssessmentHasError(true);
        }
        return returnValue;
      } else {
        setIsLastAssessmentHasError(true);
        return '0:00';
      }
    } else {
      // close passcode input, and wait completed message
      return '';
    }
  }, [
    passcodeExpiredTime
  ]);

  useEffect(() => {
    setTimeout(() => {
      inputCodeRef.current?.querySelector('.ap-field-input')?.click();
    }, 100);
    return () => {
      if (!!passcodeExpiredTimeCountSubRef.current) {
        passcodeExpiredTimeCountSubRef.current.unsubscribe();
        passcodeExpiredTimeCountSubRef.current = null;
      }
      getCompletedAssessmentSub?.unsubscribe();
    };
  }, []);

  useEffect(() => {
      if (assetScanningErrorTime) {//   isIAMPasscodeRequired && assetScanningErrorTime
        expireTotalTime.current = assetScanningErrorTime;
        calcExpiredTimeFunc(0);
        passcodeExpiredTimeCountSubRef.current = interval(1000).subscribe((x) => {
          const remainingTime = calcExpiredTimeFunc(1000);
          if (remainingTime <= 0) {
            setIsLastAssessmentHasError(true);
            passcodeExpiredTimeCountSubRef.current?.unsubscribe();
            passcodeExpiredTimeCountSubRef.current = null;
          }
        });
      } else if (!!passcodeExpiredTimeCountSubRef.current) {
        setPasscodeExpiredTime(null);
        passcodeExpiredTimeCountSubRef.current.unsubscribe();
        passcodeExpiredTimeCountSubRef.current = null;
      } else if (assetScanningErrorTime === 0) {
        setIsLastAssessmentHasError(true);
        setPasscodeExpiredTime(0);
      }
  }, [assetScanningErrorTime])

  const requestCode = () => {
    setCloseScanModalMethod && setCloseScanModalMethod(true);
    setLoadingRequestState(true);
    // setTimeout(() => {
    //   setLoadingRequestState(false);
    //   // setShowVerificationCode?.(false);
    // }, 3000);
    // return;

    const param = {
      productId: productId,
      urls: originTData?.map((data: any) => ({
        siteUrl: data?.url,
        nickName: data?.pageTitle,
      })),
    };

    autoScanTaskCreateRef.current = productService
      .v2SaveAutoScanUrls(param)
      .pipe(
        take(1),
        concatMap((res: any) => {
          if (res) {
            return assetService.createAssessmentWork(String(productId), null);
          }
          return of(null);
        }),
        take(1),
      )
      .subscribe((createAssessmentWorkRes: any) => {
        // if (createAssessmentWorkRes && createAssessmentWorkRes.status === 1) {
        //   setLoadingRequestState(false);
        // }
      });
  }
  const onCancel = () => {
    setCloseScanModalMethod && setCloseScanModalMethod(true);
  };
  const shouldShowPasscodeExpiredTime = !!passcodeExpiredTimeString && passcodeExpiredTimeString !== '0:00';
  const verificationContent = {
    title: `Verification required to scan ${productName}`,
    body: (
      <div className="verificationModal ap-mt-spacing-3">
        <div className="verificationModal-textPart">
          {/* <h2>{finalBoldText}</h2> */}
          <span>A verification code has been sent via email{authUser? ` to ${authUser}`:null}.</span>
        </div>
          <div className="verificationModal-enterPart">
            <Input
              ref={inputCodeRef}
              tabIndex={0}
              error={isLastAssessmentHasError}
              type={'text'}
              title={"Enter code"}
              required={true}
              value={verificationCode}
              onChange={onChangeInput}
              disabled={loadingState||loadingRequestState||(!isLastAssessmentHasError&&((!!passcodeExpiredTimeString && passcodeExpiredTimeString === '0:00')||passcodeExpiredTimeString === ''))}
            >
          </Input>
          </div>
          {/* {isLastAssessmentHasError.toString()}{invalidCode.toString()} */}
          <div className={classNames("verification-message ap-mt-spacing-3", { "more-height": !!passcodeExpiredTimeString && passcodeExpiredTimeString !== '0:00'&&isLastAssessmentHasError,
            "loading": loadingState
          })} >
            {shouldShowPasscodeExpiredTime ? (//should still show or not
              <>
                <p className="passcode-expired-time">
                  The code will expire in {' '}
                  <span className="passcode-expired-time-value">
                    {passcodeExpiredTimeString || ''}
                  </span>
                </p>
                {!isLastAssessmentHasError&&<br/>}
                </>
              ) : null}
            {isLastAssessmentHasError && !loadingState && ((!!passcodeExpiredTimeString && passcodeExpiredTimeString === '0:00') || passcodeExpiredTimeString === '') && <>
              <p className="message-expired">The code has expired. Please request a new one.</p>
              <br/>
            </>}
            {isLastAssessmentHasError && !!passcodeExpiredTimeString && passcodeExpiredTimeString !== '0:00' && <>
              <p className="message-expired">You have entered an incorrect code. Please try again.</p>
              <br/>
            </>}
        </div>
        {!loadingState && <div className="notification-panel ap-mt-spacing-3">
          <span className="Appkit4-icon icon-warning-fill warning-icon"></span>
          <div>The scan will continue after closing this window. A new code can be requested once the scan is complete. </div>
        </div>}
      </div>
    ),
    footer: <div className='bottom-footer'>
      {/* {isLastAssessmentHasError.toString()}{invalidCode.toString()}{passcodeExpiredTimeString.toString()} */}
      <div className='required'>Required fields</div>
      <div>
      {loadingRequestState?
      <Button kind="secondary" loading={true} disabled={true}>
        Request code
      </Button> :
      <Button
        tabIndex={0}
        kind="secondary"
        disabled={!isLastAssessmentHasError||(!!passcodeExpiredTimeString && passcodeExpiredTimeString !== '0:00')}
        onClick={requestCode}
      >
        Request code
      </Button>}
      {loadingState?
      <Button kind="primary" loading={true} disabled={true}>
        Continue
      </Button>
      :<Button tabIndex={0} kind="primary" disabled={invalidCode||!(!!passcodeExpiredTimeString && passcodeExpiredTimeString !== '0:00'&&verificationCode!=='')} onClick={submitClick}>
        Continue
      </Button>}
      </div>
    </div>
  };


  return <CommonModal
      className="verificationModal"
      visible={showVerificationCode}
      modalContent={verificationContent}
      setVisibleFunc={setShowVerificationCode}
      maskCloseable={false}
      onCancel={onCancel}
    />;
};

export default VerificationModal;