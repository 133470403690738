import React, { useState } from 'react';
import { Avatar } from '@appkit4/react-components';
import './timeline.component.scss';
import mockImage from './mockImages/mock-manual-timeLine-img01.jpg';

const Timeline = (props: any) => {
  const { userCommentData } = props;

  const getCommentContent = () => {
    const getIconEle = () => {
      const { action } = userCommentData;
      let iconType = 'icon-circle-radio-outline';
      if (action) {
        let iconColorStyle = '';
        if (['open', 'reopen'].includes(action)) {
          iconColorStyle = 'comment-right-content-open-iconColor';
        } else if (action === 'close') {
          iconType = 'icon-circle-checkmark-outline';
          iconColorStyle = 'comment-right-content-close-iconColor';
        }
        // icon-circle-checkmark-outline
        return <span className={`Appkit4-icon ${iconType} ${iconColorStyle}`}></span>;
      }
      return null;
    };

    return (
      <div className="comment-right-content">
        {getIconEle()}
        <div
          className="historyCommentCodeContainer"
          dangerouslySetInnerHTML={{ __html: userCommentData.comment }}
        ></div>
        {/* {userCommentData.commentImgSrc && <img src={mockImage}></img>} */} {/*for now no need but do not delete*/}
      </div>
    );
  };

  return (
    <div className="ap-feedback-issue-detail-comment">
      <div className="comment-left">
        <div className="comment-avatar">
          {userCommentData?.usrImgSrc && <img className="avatar-image" src={userCommentData?.usrImgSrc} alt="" />}
          {!userCommentData?.usrImgSrc && (
            <Avatar
              diameter="40"
              borderWidth="0"
              bgColor={userCommentData.bgColor}
              label={userCommentData.name}
              disabled={true}
            ></Avatar>
          )}
        </div>
        <div className="comment-timeline"></div>
      </div>
      <div className="comment-right">
        <div className={`comment-content ${userCommentData.action === 'close' ? 'closed' : ''}`}>
          {getCommentContent()}
        </div>
        {userCommentData.pwcUserType?.toLowerCase() === 'internal' ? (
          <div className="comment-date">
            {userCommentData?.createdTime}&nbsp;•&nbsp;
            {userCommentData.fullName ? (
              <span>
                {userCommentData.fullName}&nbsp;{userCommentData.country ? '(' + userCommentData.country + ')' : ''}
              </span>
            ) : null}
          </div>
        ) : (
          ''
        )}
        {userCommentData.pwcUserType?.toLowerCase() === 'external' ? (
          <div className="comment-date">
            {userCommentData?.createdTime}&nbsp;•&nbsp;
            {userCommentData.firstName}&nbsp;{userCommentData.lastName}
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default Timeline;
