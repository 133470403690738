//@ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Button, ButtonProps } from '@appkit4/react-components/button';
import './form.scss';

export interface FormProps {
    className?: string;
    solid?: boolean;
    title?: React.ReactNode;
    extra?: React.ReactNode;
    header?: React.ReactNode;
    footer?: React.ReactNode;
    cancelText?: string;
    okText?: string;
    okButtonProps?: ButtonProps;
    cancelButtonProps?: ButtonProps;
    [key: string]: any;
    onCancel?: () => void;
    onOK?: () => void;
    extra?: React.ReactNode;
    divider?: boolean;
    headerLayout?: 'horizontal' | 'vertical';
    footerLayout?: 'horizontal' | 'vertical';
}

export interface FormLocale {
    okText: string;
    cancelText: string;
  }

export const handleCancel = (onCancelFunc) => {
    onCancelFunc?.();
}

export const handleOk = (onOkFunc, e: any) => {
    onOkFunc?.(e);
}
const Form = React.forwardRef<HTMLElement, FormProps>((props, ref) => {

    const {
        className,
        solid = false,
        title,
        extra,
        header,
        body,
        footer,
        cancelText = 'Cancel',
        okText = 'Submit',
        onCancel,
        onOk,
        headerLayout,
        footerLayout,
        divider = false,
        ...restProps
    } = props;

    const renderFooter = () => {
        return (
            <>
                <Button kind='primary' onClick={(e)=>handleOk(onOk, e)} {...props.okButtonProps}>{okText}</Button>
                {divider && <hr />}
                <Tooltip  
                  trigger="hover"
                  position="top"
                  distance={4}
                  id="tooltipDesc"
                  appendAfterTarget={true}
                  hideTooltipOnBlur={true}
                  content={
                    'Use this option if signing in with your email address and password.'
                  }
                >
                <Button kind='secondary' onClick={()=>handleCancel(onCancel)} {...props.cancelButtonProps}>{cancelText}</Button>
                </Tooltip>
            </>
        );
    }

    return (
        <div className={classNames('ap-pattern-form', className, { 'solid-form': solid })} {...restProps}>
            <div className={classNames('ap-pattern-form-header', { 'vertical': headerLayout === 'vertical' })}>
                {header}
            </div>
            <form className='ap-pattern-form-body'>
                {props.children}
                <div className={classNames('ap-pattern-form-footer', { 'vertical': footerLayout === 'vertical' })}>
                    {
                        footer === undefined ? renderFooter() : footer
                    }
                </div>
            </form>
        </div>
    )
})

export default Form;
