import {
  ResponseStatus,
  ProductType,
  ProductListInfoType,
  UserType,
  SummaryResult,
  ProductCreateInputType,
  ProductCreateResponseType,
  ProductUpdateInputType,
  ProductUpdateResponseType,
  ProductListItemType,
  AssetAssessmentApiType,
} from '@models';
import { Observable } from 'rxjs';
import { apiService } from './rxjs.service';

const createProduct = (productInfo: ProductCreateInputType): Observable<ProductCreateResponseType> => {
  return apiService.post('/product/create', productInfo);
};

const updateProduct = (productInfo: ProductUpdateInputType): Observable<ProductUpdateResponseType> => {
  return apiService.post('/product/update', productInfo);
};

const getProducts = (keyword: string, options?: any): Observable<ProductListInfoType> => {
  return apiService.post('/product/list', {
    keyword: keyword,
    // sort: { name: 1, status: 1, infoComplete: 1, lastUpdatedDate: 1 },
    // filter: { status: null, infoComplete: null },
    // pageNum: 1,
    // pageSize: 5,
  });
};

const getProductInfo = (appId: string): Observable<ProductType> => {
  return apiService.get(`/product/info?appId=${appId}`);
};

const getPrivateAssetMemberDetail = (productID: number): Observable<ProductListItemType> => {
  return apiService.get(`/product/info/getPrivateAssetMemberDetail?productId=${productID}`);
};

const getFtuxStatus = (
  productId: number,
): Observable<{
  ftuxStatus: number;
  status: number;
}> => {
  return apiService.get(`/product/info/getFtuxStatus?productId=${productId}`);
};

const setFtuxStatus = (
  productId: number,
  status: number,
): Observable<{
  ftuxStatus: number;
  status: number;
}> => {
  return apiService.get(`/product/info/setFtuxStatus?productId=${productId}&status=${status}`);
};

const getProductCheckProcess = (
  productId: number,
): Observable<{
  percentage: number;
}> => {
  return apiService.get(`/product/info/getProductCheckProcess?productId=${productId}`);
};

const updateIsPrivate = (
  productId: number,
  isPrivate: boolean,
): Observable<{ status: ResponseStatus; data: ProductType }> => {
  return apiService.get(`/product/share/setPrivate?productId=${productId}&isPrivate=${isPrivate ? 1 : -1}`);
};

const updateCrawlSetting = (productId: number, maxCrawl?: number): Observable<{ status: ResponseStatus }> => {
  return apiService.get(`/product/info/setCrawlSetting?productId=${productId}&max_crawl=${maxCrawl}`);
};

const getProductMembers = (productId: string | number): Observable<{ status: ResponseStatus; data: UserType[] }> => {
  return apiService.get(`/product/share/members?productId=${productId}`);
};

const addProductMember = (
  productId: string | number,
  member: any,
  role: string,
): Observable<{ status: ResponseStatus; data: UserType[] }> => {
  return apiService.post(`/product/share/add`, { productId, member, role });
};

const removeProductMember = (
  productId: string | number,
  email: string,
): Observable<{ status: ResponseStatus; data: UserType[] }> => {
  return apiService.get(`/product/share/remove?productId=${productId}&email=${email}`);
};

const toggleSchedule = (productId: number, enable: 1 | -1): Observable<{ status: ResponseStatus; data: UserType }> => {
  return apiService.get(`/assessment/schedule/switch/${productId}?enable=${enable}`);
};

const updateSchedule = (
  productId: number,
  frequency: 1 | 2 | 3, // 1 weekly on Monday 2 Bi-weekly on Monday 3 First Day of a month
): Observable<{ status: ResponseStatus; data: UserType }> => {
  return apiService.get(`/assessment/schedule/update?productId=${productId}&frequency=${frequency}`);
};

const updateProductOwner = (
  productId: string | number,
  name: string,
  email: string,
): Observable<{ status: ResponseStatus; data: UserType[] }> => {
  return apiService.post(`/product/update/owner`, {
    id: productId,
    name: name,
    email: email,
  });
};

const updateEmailNotification = (
  productId: number,
  email: string,
  notification: number,
): Observable<{ status: ResponseStatus }> => {
  return apiService.get(
    `/product/share/emailNotification?productId=${productId}&email=${email}&notification=${notification}`,
  );
};

const getSummary = (productId: string | number): Observable<SummaryResult> => {
  return apiService.get(`/product/info/summary?productId=${productId}`);
};

const getProductSitesAndPrimarySite = (productId: string | number): Observable<AssetAssessmentApiType> => {
  return apiService.get(`/product/info/getProductSitesAndPrimarySite?productId=${productId}`);
};

const updateUserRole = (userId: string, role: string): Observable<{ status: ResponseStatus; data: UserType[] }> => {
  return apiService.post(`/product/share/role`, { userId, role });
};
const getScore = (productId: string): Observable<any> => {
  return apiService.get(`/product/info/getProductCheckScore?productId=${productId}`);
};

const updatePillar = (productId: number, pillar: string): Observable<{ status: ResponseStatus; data: UserType[] }> => {
  return apiService.post(`/product/update/pillar`, { productId, pillar });
};

const v2CreateProduct = (productInfo: any): Observable<any> => {
  return apiService.post('/product/v2/create', productInfo);
};

type IV2SaveAutoScanUrlsType = {
  productId: string;
  urls: { id?: number; siteUrl: string; nickName: string }[];
};

const v2SaveAutoScanUrls = (param: IV2SaveAutoScanUrlsType): Observable<any> => {
  return apiService.post('/product/v2/upsetUrls', param);
};
const v2OtherSettingsSaveChanges = (param: any): Observable<any> => {
  return apiService.post('/product/v2/setOther', param);
};

const v2BasicSettingsSaveChanges = (param: any): Observable<any> => {
  return apiService.post('/product/v2/setInformation', param);
};

const v2UpdateMember = (param: any): Observable<any> => {
  return apiService.post('/product/v2/upsetMember', param);
};

const v2CreateOrUpdateManual = (param: any): Observable<any> => {
  return apiService.post('/product/v2/manual', param);
};

const deleteManualIssue = (param: any): Observable<any> => {
  return apiService.deleteByRequestBody('/product/v2/manual', param);
};

const v2SetManualStatus = (param: any): Observable<any> => {
  return apiService.post('/product/v2/setManualStatus', param);
};

const v2GetPrivateAssetMemberDetail = (appId: string): Observable<any> => {
  return apiService.get(`/product/info/getPrivateAssetMemberDetailV2?appId=${appId}`);
};

export const productService = {
  createProduct,
  updateProduct,
  getProducts,
  getProductInfo,
  getFtuxStatus,
  setFtuxStatus,
  getProductCheckProcess,
  updateIsPrivate,
  updateCrawlSetting,
  getProductMembers,
  addProductMember,
  removeProductMember,
  toggleSchedule,
  updateSchedule,
  updateProductOwner,
  updateEmailNotification,
  getSummary,
  getPrivateAssetMemberDetail,
  getProductSitesAndPrimarySite,
  updateUserRole,
  updatePillar,
  getScore,
  v2CreateProduct,
  v2SaveAutoScanUrls,
  v2OtherSettingsSaveChanges,
  v2BasicSettingsSaveChanges,
  v2UpdateMember,
  v2CreateOrUpdateManual,
  v2SetManualStatus,
  v2GetPrivateAssetMemberDetail,
  deleteManualIssue
};
