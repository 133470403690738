import * as React from 'react';
import ClassNames from 'classnames';
import '@appkit4/styles/appkit.min.css';
import '@appkit4/react-components/dist/styles/appkit4-react.min.css';

export interface IconBadgeProps {
  icon?: React.ReactNode | string;
  prefixCls?: string;
  className?: string;
  style?: React.CSSProperties;
  type?:
  | 'primary'
  | 'primary-outlined'
  | 'danger'
  | 'danger-outlined'
  | 'warning'
  | 'warning-outlined'
  | 'success'
  | 'success-outlined'
  | 'info'
  | 'info-outlined';
  size?: 'sm' | 'lg';
  rounded?: boolean;
  textColor?: string;
  role?: string;
  tabIndex?: number | undefined;
  value?: string | React.ReactNode;
  onClick?: (event: React.SyntheticEvent) => void;
  [key: string]: any;
}

const IconBadge = React.forwardRef<HTMLDivElement, IconBadgeProps>((props, ref) => {
  const {
    icon,
    prefixCls: customizePrefixCls,
    type = 'primary',
    className,
    size = 'sm',
    rounded = false,
    children,
    role,
    tabIndex,
    value,
    onClick,
    ...restProps
  } = props;

  const prefixCls = customizePrefixCls
    ? `${customizePrefixCls}-badge`
    : 'ap-badge';

  let isRounded;

  if (typeof value === 'string') {
    if (!value) {
      isRounded = false;
    } else if (/[^\x00-\xff]/.test(value) && !icon) {
      isRounded = value.length <= 1;
    } else if (!icon) {
      isRounded = value.length <= 2;
    }
  }

  const clickable = role === 'button' || role === 'link';

  const classNames = ClassNames(prefixCls, className, {
    [`${prefixCls}-${type}`]: type,
    [`${prefixCls}-${size}`]: size,
    [`${prefixCls}-round`]: isRounded || rounded,
    gesture: clickable,
  });

  const onkeydown = (event: React.KeyboardEvent) => {
    const key = event.key;
    if (
      (('Enter' === key || ' ' === key) &&
        role === 'button') ||
      ('Enter' === key && role === 'link')
    ) {
      onClick?.(event);
    }
  };

  const classNameIcon: string = ClassNames('Appkit4-icon', icon as string);

  return (
    <div
      ref={ref}
      className={classNames}
      role={role}
      tabIndex={clickable ? 0 : tabIndex}
      onClick={clickable ? onClick : undefined}
      onKeyDown={clickable ? onkeydown : undefined}
      {...restProps}>
      {icon ? (
        <div className="icon">
          {typeof icon === 'string' ? (
            <span className={classNameIcon}></span>
          ) : (
            icon
          )}
        </div>
      ) : null}

      <span className="ap-badge-text">{value}</span>
    </div>
  );
});

IconBadge.displayName = 'IconBadge';

IconBadge.defaultProps = {
  type: 'primary' as IconBadgeProps['type'],
  size: 'medium' as IconBadgeProps['size'],
  rounded: false as IconBadgeProps['rounded'],
};

export default IconBadge;