import React, { useState, useRef, useEffect } from 'react';
import './index.scss';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Switch } from '@appkit4/react-components/switch';
import { Select, Badge } from '@appkit4/react-components';
import { Subscription, of, zip } from 'rxjs';
import { switchMap, concatMap, filter } from 'rxjs/operators';
import { productService } from '@services';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import { idamOrIamExpandData, authenticationTypesDataForSettingsPage } from '@layout/AutoMatedScan.page/constants';
import LoggedOutSvg from '@assets/icons/LoggedOut.svg';
import ScanInProgressSvg from '@assets/icons/scanInProgress.svg';
import ConsultingCyberSecuritySvg from '@assets/icons/ConsultingCyberSecurity.svg';
import UpdatedPasswordUpdatedSvg from '@assets/icons/UpdatedPasswordUpdated.svg';
import { CheckboxGroup, Checkbox } from '@appkit4/react-components/checkbox';
import { Input } from '@appkit4/react-components/field';
import { assetService } from '@services/asset.service';
import { Accordion, AccordionItem } from '@appkit4/react-components/accordion';
import { decryptData, getCookie, getProductInfoCookie, safelyParseJSON } from '@utils/common';

const EditAccessRequirementsModal = (props: any) => {
  const {
    visible = true,
    setVisibleFunc,
    bodyStyle = {},
    curProductInfo,
    setNeedRefreshFrom,
    uuid,
    autoScaneAuthType,
    autoScaneUsername,
    autoScanePwd,
  } = props;
  const [authTypeData, setAuthTypeData] = useState(authenticationTypesDataForSettingsPage);
  const [allCanAccessOptionClicked, setAllCanAccessOptionClicked] = useState(false);
  const [mustLogOptionClicked, setMustLogOptionClicked] = useState(false);
  const [idamOrIamCheckboxChecked, setIdamOrIamCheckboxChecked] = useState(false);
  const [iamOrOtherUserNameValue, setIamOrOtherUserNameValue] = useState('');
  const [iamOrOtherPasswordValue, setIamOrOtherPasswordValue] = useState('');
  const [saveChangesLoading, setSaveChangesLoading] = useState(false);
  const [finalClickedAccessRequirements, setFinalClickedAccessRequirements] = useState('');
  const [finalAuthTypeUsed, setFinalAuthTypeUsed] = useState('');

  const updateAssetCredentialRef = useRef<Subscription>();

  const [accoringActiveKeys, setAccoringActiveKeys] = React.useState<string[]>([]);

  useEffect(() => {
    if (autoScaneAuthType === 'idam') {
      setIdamOrIamCheckboxChecked(false);
      setFinalAuthTypeUsed('idamOrIam');
      setFinalClickedAccessRequirements('mustLog');
      setMustLogOptionClicked(true);
      setAllCanAccessOptionClicked(false);
      setIamOrOtherUserNameValue('');
      setIamOrOtherPasswordValue('');
      setAuthTypeData((prevs) =>
        prevs?.map((prev) => ({ ...prev, clicked: prev?.value === 'idamOrIam' ? true : false })),
      );
    } else if (autoScaneAuthType === 'iam') {
      setIdamOrIamCheckboxChecked(false);
      setFinalAuthTypeUsed('iamOrOther');
      setFinalClickedAccessRequirements('mustLog');
      setIamOrOtherUserNameValue(autoScaneUsername);
      setIamOrOtherPasswordValue(autoScanePwd ? autoScanePwd : '');
      setMustLogOptionClicked(true);
      setAllCanAccessOptionClicked(false);
      setAuthTypeData((prevs) =>
        prevs?.map((prev) => ({ ...prev, clicked: prev?.value === 'iamOrOther' ? true : false })),
      );
    } else if (autoScaneAuthType === 'public') {
      setIdamOrIamCheckboxChecked(false);
      setFinalClickedAccessRequirements('allCanAccess');
      setIamOrOtherUserNameValue('');
      setIamOrOtherPasswordValue('');
      setMustLogOptionClicked(false);
      setAllCanAccessOptionClicked(true);
      setAuthTypeData((prevs) => prevs?.map((prev) => ({ ...prev, clicked: false })));
    }
  }, [uuid, autoScaneAuthType]);

  const childContentItemClick = (item: { [x: string]: any }) => {
    setFinalAuthTypeUsed(item?.value);
    setAuthTypeData((prevs) => prevs.map((prev) => ({ ...prev, clicked: prev?.value === item?.value })));
    let param: any = {};
    switch (item?.value) {
      case 'idamOrIam':
        param = {
          imgSrc: LoggedOutSvg,
          h2Ele: 'Additional steps are needed.',
          descEle:
            "To scan products using PwC IdAM or IAM's federated PwC identity service, additional steps are required to grant us access.",
        };
        break;
      case 'iamOrOther':
        param = {
          imgSrc: UpdatedPasswordUpdatedSvg,
          h2Ele: 'Access to your user credentials is needed.',
          descEle:
            'To scan products using IAM or other authorization services, we need access to one of your admin usernames and passwords.',
        };
        break;
      case 'unsure':
        param = {
          imgSrc: ConsultingCyberSecuritySvg,
          h2Ele: 'Reach out to your development team.',
          descEle:
            'If unsure about the type of authentication used on your application, please reach out to your development team for clarification. ',
        };
        break;
      default:
        param = {};
    }
  };

  const getItemIcon = (value: string) => {
    if (value === 'idamOrIam') {
      return 'icon-shield-half-outline';
    }
    if (value === 'iamOrOther') {
      return 'icon-shield-outline';
    }
    if (value === 'unsure') {
      return 'icon-help-question-outline';
    }
  };

  const getExpandPart = (item: any) => {
    let res: any;
    switch (item?.value) {
      case 'idamOrIam':
        res = (
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam">
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms">
              {idamOrIamExpandData?.map((item, idx) => {
                let eles: any;
                if (item?.value === 'addAccounts') {
                  eles = (
                    <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms-single">
                      <span>{idx + 1}.</span>
                      <span>{item.label}</span>
                      <ul>
                        {item.links.map((link, index) => (
                          <li key={`${index}-${link}`}>
                            <a href="#" target="_blank" rel="noopener noreferrer">
                              {link}
                            </a>
                            <span className="Appkit4-icon icon-link-outline editAccessRequirementsModal-accRmts-icon"></span>
                          </li>
                        ))}
                      </ul>
                    </div>
                  );
                } else {
                  eles = (
                    <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-terms-single">
                      <span>{idx + 1}.</span>
                      <span>{item.label}</span>
                    </div>
                  );
                }
                return <div key={`${idx}-${item?.value}`}>{eles}</div>;
              })}
            </div>
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-idamOrIam-checkbox">
              <Checkbox
                checked={idamOrIamCheckboxChecked}
                onChange={(checked: boolean, event: React.SyntheticEvent) => {
                  setIdamOrIamCheckboxChecked(checked);
                  event.stopPropagation();
                }}
              >
                I have added both accounts and granted admin permissions.
              </Checkbox>
            </div>
          </div>
        );
        break;
      case 'iamOrOther':
        res = (
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther">
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther-desc">
              <span>Please provide one of your admin user credentials with full application access.</span>
            </div>
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-iamOrOther-usrInfo">
              <Input
                value={iamOrOtherUserNameValue}
                onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
                  setIamOrOtherUserNameValue(value);
                }}
                type="text"
                title="Username"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
              />
              <Input
                value={iamOrOtherPasswordValue}
                type="password"
                onClick={(event: React.MouseEvent) => event.stopPropagation()}
                onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) =>
                  setIamOrOtherPasswordValue(value)
                }
                title="Password"
              />
            </div>
          </div>
        );
        break;
      // case 'unsure':
      // res = (
      //   <div className="autoPage-content-stepperContent-accessOptionPart-child-content-item-expandPart-unsure">
      //     <div>
      //       <span>1.</span>
      //       <span>Ask your development team the type of user authentication used in your product.</span>
      //     </div>
      //     <div>
      //       <span>2.</span>
      //       <span>Return to resume your process by selecting the correct authentication type.</span>
      //     </div>
      //   </div>
      // );
      // res = '';
      // break;
      default:
        res = <></>;
    }

    return res;
  };

  const getCredentialApiParam = () => {
    const id = curProductInfo?.id || (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    if (finalClickedAccessRequirements === 'allCanAccess') {
      return {
        id,
        authType: 'public',
      };
    }
    if (finalClickedAccessRequirements === 'mustLog') {
      if (finalAuthTypeUsed === 'idamOrIam') {
        return {
          id,
          authType: 'idam',
        };
      }
      if (finalAuthTypeUsed === 'iamOrOther') {
        return {
          id,
          authType: 'iam',
          authUser: iamOrOtherUserNameValue,
          authPass: iamOrOtherPasswordValue,
        };
      }
      if (finalAuthTypeUsed === 'unsure') {
        return {
          id,
          authType: 'unsure',
        };
      }
    }
  };

  const saveChangesHandler = () => {
    setSaveChangesLoading(true);
    updateAssetCredentialRef.current = assetService
      .updateAssetCredential(getCredentialApiParam())
      .pipe(take(1))
      .subscribe((res: any) => {
        if (res?.success) {
          setSaveChangesLoading(false);
          setVisibleFunc(false);
          setNeedRefreshFrom('editAccessRequirementsModal');
        }
      });
  };
  const getSaveChangesBtnDisabledStatus = () => {
    if (finalClickedAccessRequirements === 'mustLog') {
      if (finalAuthTypeUsed === 'idamOrIam') {
        return idamOrIamCheckboxChecked ? false : true;
      } else if (finalAuthTypeUsed === 'iamOrOther') {
        if (iamOrOtherPasswordValue && iamOrOtherUserNameValue) return false;
        return true;
      } else {
        return true;
      }
    }

    return false;
  };

  const onClickAccordion = (activeKeys: string[]) => {
    if (activeKeys[0] === '1') {
      setMustLogOptionClicked(true);
      setAllCanAccessOptionClicked(false);
      setAuthTypeData(authenticationTypesDataForSettingsPage);
      setFinalClickedAccessRequirements('mustLog');
    }
    if (activeKeys[0] === '2') {
      setMustLogOptionClicked(false);
      setAllCanAccessOptionClicked(true);
      setAuthTypeData(authenticationTypesDataForSettingsPage);
      setFinalClickedAccessRequirements('allCanAccess');
    }
    setChildAccoringActiveKeys(['']);
    setAccoringActiveKeys(activeKeys);
  };

  const accordingData = [
    {
      value: 'userMustLogPart',
      header: (
        <div
          className={`editAccessRequirementsModal-stepperContent-accessOptionPart-option ${mustLogOptionClicked ? 'clickedStyle' : ''}`}
          onClick={() => {
            // setMustLogOptionClicked(true);
            // setAllCanAccessOptionClicked(false);
            // setAuthTypeData(authenticationTypesDataForSettingsPage);
            // setFinalClickedAccessRequirements('mustLog');
          }}
        >
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-icon">
            <span className="Appkit4-icon icon-lockclosed-locked-outline editAccessRequirementsModal-accRmts-icon"></span>
          </div>

          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-texts">
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-texts-title">
              <h2>Users must log in or sign up to access my product. </h2>
            </div>
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-texts-desc">
              <span>
                User access in your application is restricted. Most PwC applications, except for global or public sites, fall
                into this category.
              </span>
            </div>
          </div>
        </div>
      ),
    },
    {
      value: 'anyoneCanAccessPart',
      header: (
        <div
          className={`editAccessRequirementsModal-stepperContent-accessOptionPart-option ${allCanAccessOptionClicked ? 'clickedStyle' : ''}`}
          onClick={() => {
            // setMustLogOptionClicked(false);
            // setAllCanAccessOptionClicked(true);
            // setAuthTypeData(authenticationTypesDataForSettingsPage);
            // setFinalClickedAccessRequirements('allCanAccess');
          }}
        >
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-icon">
            <span className="Appkit4-icon icon-lockopen-unlock-outline editAccessRequirementsModal-accRmts-icon"></span>
          </div>
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-texts">
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-texts-title">
              <h2>Anyone can access my product without log in or sign up.</h2>
            </div>
            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-option-texts-desc">
              <span>User access is not restricted in your application. Even external people can access.</span>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const [childAccoringActiveKeys, setChildAccoringActiveKeys] = React.useState<string[]>([]);
  const onChildAccordingClickAccordion = (activeKeys: string[], event: React.MouseEvent) => {
    setChildAccoringActiveKeys(activeKeys);
    const index = Number(activeKeys[0]);
    const item = authTypeData[index];
    childContentItemClick(item);
  };
  const childAccordingHeaders = (item: any) => {
    return (
      <div style={{ display: 'flex' }}>
        <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-icon">
          <span className={`Appkit4-icon ${getItemIcon(item?.value)} editAccessRequirementsModal-accRmts-icon`}></span>
        </div>
        <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-texts">
          <h2 className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-texts-title">
            {item.title}
          </h2>
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-texts-desc">
            <span>{item.desc}</span>
          </div>
        </div>
      </div>
    );
  };

  const modalContent = {
    title: 'Access requirements',
    footer: (
      <>
        <div className="editAccessRequirementsModal-footer-rf">
          <span>Required Fields</span>
        </div>
        <div>
          <Button
            kind="secondary"
            onClick={() => {
              setVisibleFunc(false);
            }}
          >
            Cancel
          </Button>{' '}
          <Button
            loading={saveChangesLoading}
            kind="primary"
            onClick={() => saveChangesHandler()}
            disabled={getSaveChangesBtnDisabledStatus()}
          >
            Save changes
          </Button>
        </div>
      </>
    ),
    body: (
      <div className="editAccessRequirementsModal">
        <div className="editAccessRequirementsModal-stepperContent">
          <div className="editAccessRequirementsModal-stepperContent-accessOptionPart">
            <Accordion
              multiple={false}
              onClick={onClickAccordion}
              activeKeys={accoringActiveKeys}
              showExpandIcon={false}
            >
              <AccordionItem templateHeader={() => accordingData[0].header} itemKey={'1'}>
                <div
                  className={`editAccessRequirementsModal-stepperContent-accessOptionPart-child ${mustLogOptionClicked ? 'show' : ''}`}
                >
                  <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-title">
                    <h3>Authentication type used</h3>
                  </div>
                  <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content">
                    <Accordion
                      multiple={false}
                      onClick={(activeKeys: string[], event: React.MouseEvent<HTMLElement>) => {
                        onChildAccordingClickAccordion(activeKeys, event);
                      }}
                      activeKeys={childAccoringActiveKeys}
                      showExpandIcon={false}
                    >
                      {authTypeData?.map((item: any, idx: number) => (
                        <AccordionItem
                          key={`${idx}-${item?.value}`}
                          templateHeader={() => childAccordingHeaders(item)}
                          itemKey={`${idx}`}
                          className={`${item.clicked ? 'content-clicked' : ''}`}
                        >
                          <div style={{ display: 'flex', width: '100%' }}>
                            <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-icon">
                              <span className={`Appkit4-icon  editAccessRequirementsModal-accRmts-icon`}></span>
                            </div>
                            <div
                              className={`editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart ${item.clicked ? 'show' : ''}`}
                            >
                              <div className="editAccessRequirementsModal-stepperContent-accessOptionPart-child-content-item-expandPart-title">
                                <span>Before continuing</span>
                              </div>
                              {getExpandPart(item)}
                            </div>
                          </div>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </AccordionItem>
              <AccordionItem
                templateHeader={() => accordingData[1].header}
                itemKey={'2'}
                className="as-accordingItem2"
              ></AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    ),
  };

  return (
    <Modal
      visible={visible}
      title={modalContent.title}
      ariaLabel={modalContent.title}
      onCancel={() => setVisibleFunc(false)}
      header={''}
      icons={''}
      footer={modalContent.footer}
      bodyStyle={Object.assign({ minHeight: '92px' }, bodyStyle)}
      className="editAccessRequirementsModal"
      // initialFocus={false}
    >
      {modalContent.body}
    </Modal>
  );
};

export default EditAccessRequirementsModal;
