import { axios } from './axios.service';
import { authAxios } from './auth-axios.service';
import { from, Observable } from 'rxjs';

export const apiService = {
  get: (uri: string): Observable<any> => {
    return from(axios.get(uri));
  },
  post: (uri: string, body: any): Observable<any> => {
    return from(axios.post(uri, body));
  },
  delete: (uri: string): Observable<any> => {
    return from(axios.delete(uri));
  },
  deleteByRequestBody: (uri: string, body: any): Observable<any> => {
    return from(axios.delete(uri, { data: body }));
  },
  postWithContentType: (uri: string, body: any, contentType: string): Observable<any> => {
    return from(axios.post(uri, body, {
      headers: {
        'Content-Type': contentType
      }
    }));
  }
};

export const authApiService = {
  get: (uri: string): Observable<any> => {
    return from(authAxios.get(uri));
  },
  post: (uri: string, body: any): Observable<any> => {
    return from(authAxios.post(uri, body));
  },
  delete: (uri: string): Observable<any> => {
    return from(authAxios.delete(uri));
  },
};
