import React, { useEffect, useRef, useState } from 'react';
import { Avatar, Breadcrumb, BreadcrumbItem, Header, HeaderOptionItem, HeaderProps, NavigationProps } from '@appkit4/react-components';
import Faq from './Faq';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import './page-anchors.scss';
import { addSideAnchorClass, formatUrl, removeHighlight } from '@utils/common';
import {getFAQ} from '../../services/resource.service';
import LoadingComponent from '@components/Loading';

export interface FAQProps {
    headerProps?: HeaderProps;
    navProps?: NavigationProps;
}

const FaqPage = React.forwardRef<HTMLElement, FAQProps>((props: FAQProps, ref) => {
    const navigate = useNavigate();
    const anchorRef = useRef(null);
    const faqRef = useRef(null);
    const itemListRef = React.useRef<{ name: string, top: number, target: HTMLElement }[]>([]);
    const pageContent = React.useRef<HTMLDivElement>(null);
    const [faqList, setFaqList] = useState<any[]>([]);
    const [anchorList, setAnchorList] = useState<any[]>([]);
    const [showAnchors, setShowAnchors] = React.useState(false);
    const [anchorLeft, setAnchorLeft] = React.useState(0);
    const [showLoading, setShowLoading] = useState(false);
    const indexStrRef = useRef(0);
    useEffect(() => {
        setShowLoading(true);
        getFAQ().subscribe((res: any) => {
            setShowLoading(false);
            if (!res) return;
            setFaqList(res);
            setAnchorList(res?.map((item: any, index: number) => {
                return {
                    value: item.category,
                    selected: index === 0 ? true : false
                }
            }));
        })
    }, [])

    const checkIfSelectedExists = () => {
        if (anchorRef.current) {
            const elements = (anchorRef.current! as HTMLElement).querySelectorAll(`[id^=anchor-]`);
            const hasSelected = Array.from(elements).some(element => element.classList.contains('selected'));
            return hasSelected;
        }
        return false;
    };

    useEffect(() => {
        if (anchorList?.length > 0)
            updateItemListRef();
        if (!checkIfSelectedExists())
            (anchorRef.current! as HTMLElement).querySelectorAll(`[id^=anchor-]`)[0]?.classList.add("selected");
    }, [anchorList])

    const anchorEvent = (fragment?: string) => {
        if (anchorList.length === 0) return;
        let str = (fragment ? formatUrl(decodeURI(fragment)) : '');
        let indexStr = anchorList.findIndex((item: any) => formatUrl(item.value) === str);
        if (str === '') indexStr = 0;
        removeHighlight(anchorRef, indexStr);
        const elementTop = fragment ? (document.getElementById(str)?.offsetTop || 0) : 0;
        setTimeout(function () {
            window.scrollTo({
                top: elementTop - 64
            });
        }, 0);
        setAnchorList(data => data.map((item: any, index: number) => {
            if (formatUrl(item.value?.toLowerCase()) === str?.toLowerCase())
                return { ...item, selected: true };
            else if (!fragment && index === indexStr)
                return { ...item, selected: true };
            else return { ...item, selected: false }
        }));
        indexStrRef.current = indexStr;
        if (indexStr === -1)
            setTimeout(function () {
                window.scrollTo({
                    top: - 64
                });
            }, 0);
    }

    const hashChangeEvent = () => {
        anchorEvent(window.location.hash.slice(1));
    }

    const setAnchorsEvent = () => {
        const pageContentElement = (pageContent.current as HTMLElement);
        setAnchorLeft(pageContentElement.getBoundingClientRect().left + pageContentElement.getBoundingClientRect().width + 32);
    }

    const isInViewport = (element: HTMLElement) => {
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
        );
    }

    const scrollEvent = () => { 
        setTimeout(() => {
            const itemList = itemListRef.current;
            let scrollTop = Math.max(document.documentElement.scrollTop, document.body.scrollTop);
            for (let index = 0; index < itemList.length; ++index) {
                const trigger = (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index]?.name.toLowerCase()}]`) as HTMLElement;
                if (scrollTop <= itemList[index].top - 64) {
                    addSideAnchorClass(anchorRef, trigger);
                    break;
                }
                if (itemList[index + 1] && scrollTop > itemList[index].top - 64 && scrollTop <= itemList[index + 1].top - 64) {
                    if (!itemList[index + 2] && (window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight && isInViewport(itemList[index + 1].target)) {
                        addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[index + 1]?.name.toLowerCase()}]`) as HTMLElement);
                        // addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemList[(indexStrRef.current === 0? -1:index) + 1]?.name.toLowerCase()}]`) as HTMLElement);
                    }
                    break;
                } else if (index === itemList.length - 1 && scrollTop >= itemList[index].top - 64) {
                    addSideAnchorClass(anchorRef, trigger);
                    break;
                }
            }
        }, 300);
    }

    useEffect(() => {
        setTimeout(() => {
            if (pageContent.current) {
                removeHighlight(anchorRef);
                setAnchorsEvent();
                setShowAnchors(true);
            }
        }, 100);
        window.addEventListener('hashchange', hashChangeEvent);
        window.addEventListener('resize', setAnchorsEvent);
        window.addEventListener('scroll', scrollEvent);
        window.addEventListener('keyup', keyupEvent);
        return () => {
            window.removeEventListener('hashchange', hashChangeEvent);
            window.removeEventListener('scroll', scrollEvent);
            window.removeEventListener('resize', setAnchorsEvent);
        }
    }, [])

    const keyupEvent = (event: KeyboardEvent) => {
        if (event.keyCode === 9) {
            const accordionList = (faqRef.current! as HTMLElement).querySelectorAll('.ap-accordion-toggle');
            if (!accordionList) return;
            if (Array.from(accordionList).indexOf(document.activeElement!) === accordionList.length - 1)
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[itemListRef.current.length - 1]?.name.toLowerCase()}]`) as HTMLElement);
            if (document.activeElement?.textContent === 'Help center')
                addSideAnchorClass(anchorRef, (anchorRef.current! as HTMLElement).querySelector(`[id=anchor-${itemListRef.current[0]?.name.toLowerCase()}]`) as HTMLElement);
        }
    }

    const updateItemListRef = () => {
        setTimeout(() => {
            let intersectItem = (pageContent.current! as HTMLElement).querySelectorAll('.anchor-target');
            let tempList = [];
            for (let i = 0; i < intersectItem.length; ++i) {
                const item = (intersectItem[i] as HTMLElement);
                tempList.push({ name: item.id, top: item.offsetTop, target: item });
            }
            itemListRef.current = tempList;
            setAnchorsEvent();
            resetTabForElements();
        }, 300);
    }

    const onAnchorClick = (value?: string) => {
        anchorEvent(value);
    }

    const onClickResource = () => {
        navigate('/v2/help-center');
    }

    const onKeyDown = (event: React.KeyboardEvent<HTMLElement>, value?: string) => {
        const { key } = event;
        if (key === 'Enter') {
            onAnchorClick?.(value)
        }
    }

    const resetTabForElements = () => {
        const accordionDiv = document.querySelectorAll('.ap-pattern-faq-list.ap-help-center-faq-list .ap-accordion-content.ap-accordion-cursor-default');
            if (accordionDiv) {
                accordionDiv.forEach((element: any) => {
                    const focusableElements = element.querySelectorAll('a, button, input, textarea, select, [tabindex]');
                    if (element.classList.contains('hidden')) {
                        focusableElements.forEach((element: any) => {
                            element.setAttribute('tabindex', '-1');
                        })
                    } else {
                        focusableElements.forEach((element: any) => {
                            element.removeAttribute('tabindex');
                        })
                    }
                });
            }
    }

    useEffect(() => {
        const handleDOMContentLoaded = () => {
            // Select all anchor tags within paragraphs
            const links = document.querySelectorAll('.ap-pattern-faq-list.ap-help-center-faq-list a');
            links.forEach(link => {
                // Add target="_blank" to each link
                link.setAttribute('target', '_blank');
            });

            resetTabForElements();
        };
    
        const executeWhenReady = () => {
          if (document.readyState === 'loading') {
            // Document is still loading, wait for DOMContentLoaded
            document.addEventListener('DOMContentLoaded', handleDOMContentLoaded);
          } else {
            // Document is already loaded, execute immediately
            handleDOMContentLoaded();
          }
        };
    
        const timeoutId = setTimeout(() => {
          if (!showLoading) {
            executeWhenReady();
          }
        }, 100);
    
        // Clean up the timeout and event listener
        return () => {
          clearTimeout(timeoutId);
          document.removeEventListener('DOMContentLoaded', handleDOMContentLoaded);
        };
      }, [showLoading]);

    return (
        <>
            <div className="pattern-content help-center-faq-content">
                <div className="ap-page ap-faqs has-anchor">
                    <div className="ap-page-content" ref={pageContent}>
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <span onClick={onClickResource} tabIndex={0}>Help center</span>
                            </BreadcrumbItem>
                            <BreadcrumbItem>
                                <span>FAQs</span>
                            </BreadcrumbItem>
                        </Breadcrumb>
                        <div className="ap-faqs-header">
                            <div className="ap-faqs-header-title">FAQs</div>
                        </div>

                        <>
                            <nav ref={anchorRef} className={classNames('ap-pattern-page-anchor', "anchor-list", { 'hidden': !showAnchors })} style={{ left: `${anchorLeft}px` }}>
                                {/* {headingFunc()} */}
                                {anchorList?.length > 0 && <ul>
                                    {
                                        anchorList.map(
                                            (anchor: any, index: number) => {
                                                const hrefValue = window.location.href.indexOf("#") > -1 ? window.location.href.substring(0, window.location.href.indexOf("#")) : window.location.href;
                                                const hrefProp = true ? { href: encodeURI(hrefValue + '#' + formatUrl(anchor.value!)) } : null;
                                                return <li key={index} onClick={(event) => onAnchorClick?.(anchor.value)} onKeyDown={(event) => onKeyDown(event, anchor.value)}>
                                                    <a tabIndex={0} role="link" aria-current={anchor.selected ? 'page' : undefined} {...hrefProp} id={'anchor-' + formatUrl(anchor.value!)}
                                                        className={classNames("anchor-trigger", { 'selected': anchor.selected })}>
                                                        {anchor.externalLink && <span className={anchor.externalLinkIcon || "Appkit4-icon icon-open-in-new-outline"}></span>}
                                                        {anchor.value}
                                                    </a>
                                                </li>
                                            }
                                        )
                                    }
                                </ul>}
                            </nav>
                        </>
                        {showLoading ? <LoadingComponent></LoadingComponent> : <Faq ref={faqRef} className="ap-help-center-faq-list" faqList={faqList} onClickAccordion={() => updateItemListRef()}></Faq>}
                    </div>
                </div>
            </div>
        </>
    )
})

export default FaqPage