import React, { useState, useEffect, useRef, useContext } from 'react';
import './index.scss';
import CommonModal from '@components/modals/CommonModal';
import { Table, Column } from '@appkit4/react-components/table';
import { Input } from '@appkit4/react-components/field';
import { Pagination } from '@appkit4/react-components/pagination';
import { wsService } from '@services/websocket.service';
import { catchError } from 'rxjs/operators';
import _ from 'lodash';
import SuccessSvg from '@assets/icons/Success.svg';
import PartiallyCompleteSvg from '@assets/icons/partiallyComplete.svg';
import RunningWithProblemSvg from '@assets/icons/RunningWithProblems.svg';
import CouldNotBeCompleteSvg from '@assets/icons/AccessDenied.svg';
import ScanInProgressSvg from '@assets/icons/scanInProgress.svg';
import { Button } from '@appkit4/react-components/button';
import { useNavigate, useLocation } from 'react-router-dom';
import { getClickableLink, validateUrlForAutoScan } from '@utils';
import { Observable, Subscription, of } from 'rxjs';
import { take, switchMap, concatMap } from 'rxjs/operators';
import { assetService } from '@services/asset.service';
import { productService } from '@services';
import { TextArea } from '@appkit4/react-components/field';
import { decryptData, getCookie, getProductInfoCookie, mergeSameEleInTwoArrays, safelyParseJSON } from '@utils/common';
import ReRunScanSvg from '@assets/icons/RerunScan.svg';
import { Notification, toaster } from '@appkit4/react-components/notification';
import { EntryContext } from '@layout/Entry';

export const InprogressCloseBtn = (props: any) => {
  const { setModalFunc, setCloseScanModalStatus } = props;
  return (
    <Button
      kind="secondary"
      onClick={() => {
        setModalFunc && setModalFunc(false);
        setCloseScanModalStatus && setCloseScanModalStatus(true);
      }}
    >
      Close
    </Button>
  );
};

const ScanModal = (props: any) => {
  const {
    latestAssessmentResult,
    setCloseScanModalStatus,
    setScanModalFrom,
    setCloseScanModalMethod,
    setReScanBtnDisabled,
    showVerificationCode,
    setOriginTData
  } = useContext(EntryContext);
  const pgShowCnt = 5;
  const navigate = useNavigate();
  const location = useLocation();

  const {
    curProductInfo,
    footer,
    visible,
    setVisibleFunc,
    className = '',
    bodyStyle = {},
    title,
    imgSrc,
    boldText,
    descText,
    originTableData,
    isAssetAssessmentScanning,
    setIsAssetAssessmentScanning,
    origin,
    reload,
    uuid,
    setStep3AllTableData,
    setStep3TableData,
    step3tableData,
    setStartScanBtnDisabled,
    setStep3CurrentPageNumber,
    setShowStep3UnreachableLabel,
    needGetAssetUrls = false
  } = props;
  const [tableData, setTableData] = useState<any[]>([]);
  const originTDataRef = useRef<any[]>([]);
  const [finalBoldText, setFinalBoldText] = useState('');
  const [finalDescText, setFinalDescText] = useState('');
  const [finalImgSrc, setFinalImgSrc] = useState('');
  const [finalFooter, setFinalFooter] = useState<any>(<></>);
  const [finalClassName, setFinalClassName] = useState<any>('');
  const [reRunInputval, setReRunInputval] = useState<any>('');
  const [startScan, setStartScan] = useState<any>(false);
  const [modalFrom, setModalFrom] = useState<any>(origin);
  const [modalTitle, setModalTitle] = useState<string>(title);

  const [textAreaError, setTextAreaError] = useState<any>();

  const autoScanTaskCreateRef = useRef<Subscription>();

  const [wsAssessmentJobs, setWsAssessmentJobs] = useState<any>();

  useEffect(() => {
    if (modalFrom !== 'scanInprogress') {
      if (origin === 'reRun') {
        setFinalFooter(
          <>
            <Button
              kind="secondary"
              onClick={() => {
                setVisibleFunc(false);
              }}
            >
              Cancel
            </Button>
            <Button kind="primary" onClick={startScanClick} disabled={tableData.length === 0}>
              Confirm and scan pages
            </Button>
          </>,
        );
      } else {
        setFinalFooter(footer);
      }
    } else {
      if (modalFrom === 'scanInprogress') {
        setFinalFooter(
          <InprogressCloseBtn setModalFunc={setVisibleFunc} setCloseScanModalStatus={setCloseScanModalStatus} />,
        );
      } else {
        setFinalFooter(footer);
      }
    }
  }, [tableData])

  useEffect(() => {
    if (visible) {
      if (wsAssessmentJobs?.length > 0) {
        modalTextImghandler(wsAssessmentJobs);
      } else {
        if (modalFrom !== 'scanInprogress') {
          setModalFrom(origin);
          if (origin === 'reRun') {
            // setFinalImgSrc(ReRunScanSvg);
            setFinalBoldText(boldText);
            setFinalDescText(descText);
            setFinalClassName(className);
            setFinalFooter(
              <>
                <Button
                  kind="secondary"
                  onClick={() => {
                    setVisibleFunc(false);
                  }}
                >
                  Cancel
                </Button>
                <Button kind="primary" onClick={startScanClick} disabled={tableData.length === 0}>
                  Confirm and scan pages
                </Button>
              </>,
            );
          } else {
            setFinalFooter(footer);
            setFinalImgSrc(imgSrc);
          }
        } else {
          if (modalFrom === 'scanInprogress') {
            setFinalBoldText('Scan in progress.');
            setFinalDescText(
              origin === 'reRun'
                ? ' You can close this window. We will notify you when the scan is completed. '
                : 'Feel free to close this window. We’ll notify you once the scan is completed. ',
            );
            setFinalImgSrc(ScanInProgressSvg);
            setFinalFooter(
              <InprogressCloseBtn setModalFunc={setVisibleFunc} setCloseScanModalStatus={setCloseScanModalStatus} />,
            );
            setFinalClassName('scanInprogressModal');
          } else {
            setFinalImgSrc(imgSrc);
            setFinalFooter(footer);
          }
        }
      }
      setCurrentPage(1);
    }
  }, [wsAssessmentJobs, visible, modalFrom, origin]);

  useEffect(() => {
    if (!needGetAssetUrls) {
      setTableData([...originTableData.map((d: any) => ({ ...d, page: d.pageTitle }))].slice(0, pgShowCnt));
      originTDataRef.current = originTableData.map((d: any) => ({ ...d, page: d.pageTitle }));
    } else {
      const productId = (getProductInfoCookie() && safelyParseJSON(getProductInfoCookie())?.id) || sessionStorage.getItem('productId');
      let resSubs: any;
      if (productId) {
      resSubs = assetService
        .getAssetUrls(productId)
        .pipe(take(1))
        .subscribe((res) => {
          if (res.status === 1) {
            originTDataRef.current = res.data.map((url: any, idx: number) => ({
              ...url,
              id: idx + 1,
              pageTitle: url?.nickName,
              url: url?.siteUrl,
              scanStatus: url?.scanStatus,
              action: 'Delete',
            })).map((d: any) => ({ ...d, page: d.pageTitle }));
            setTableData(originTDataRef.current.slice(0, pgShowCnt));
          }
        });
      }
      return () => {
        resSubs?.unsubscribe();
      };
    }
  }, [originTableData]);

  const unReachableLabelHandler = (assessmentJobs: any[] = []) => {
    const failedJobs = assessmentJobs?.filter((job) => job.scanStatus < 0);
    if (failedJobs.length > 0 && failedJobs.length <= assessmentJobs.length) {
      setShowStep3UnreachableLabel && setShowStep3UnreachableLabel(true);
    }
  };

  useEffect(() => {
    const assessmentJobs = latestAssessmentResult?.assessment?.assessmentJobs;
    if (assessmentJobs) {
      unReachableLabelHandler(assessmentJobs);
      setIsAssetAssessmentScanning(false);
      originTDataRef.current = mergeSameEleInTwoArrays(originTDataRef.current, assessmentJobs);
      setTableData(originTDataRef.current?.slice(0, pgShowCnt));
      setCurrentPage(1);
      setStep3CurrentPageNumber && setStep3CurrentPageNumber(1);
      setStep3TableData && setStep3TableData([...originTDataRef?.current?.slice(0, 10)]);
      setStep3AllTableData && setStep3AllTableData(mergeSameEleInTwoArrays(originTDataRef.current, assessmentJobs));
      setStartScanBtnDisabled && setStartScanBtnDisabled(false);
    }
  }, [latestAssessmentResult]);

  useEffect(() => {
    if (setScanModalFrom) {
      setScanModalFrom(origin);
      localStorage.setItem('scanModalFrom', origin);
    }
  }, [origin, setScanModalFrom]);

  // useEffect(() => {
  //   let lastestAssessmentMsgSub: any;

  //   wsService.subscriber.next({
  //     event: 'lastestAssessment',
  //     value: curProductInfo?.id || (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId'),
  //   });

  //   lastestAssessmentMsgSub = wsService
  //     .listener('lastestAssessmentMsg')
  //     .pipe(
  //       catchError((err: any) => {
  //         setFinalBoldText(`We ran with technical issues while scanning ${curProductInfo?.name}.`);
  //         setFinalClassName('scanTechnicalIssue');
  //         setFinalDescText('Please try again later. If the issue persists contact us for assistance.');
  //         setFinalImgSrc(RunningWithProblemSvg);
  //         setFinalFooter(
  //           <Button type="primary" onClick={contactUsHandler}>
  //             Contact us
  //           </Button>,
  //         );
  //         return of(null);
  //       }),
  //     )
  //     .subscribe((res: any) => {
  //       const assessmentJobs = res?.assessment?.assessmentJobs;
  //       setWsAssessmentJobs(assessmentJobs);
  //       console.log('--ws--:', res);
  //       if (assessmentJobs) {
  //         modalTextImghandler(assessmentJobs);
  //         setIsAssetAssessmentScanning(false);
  //         originTDataRef.current = mergeSameEleInTwoArrays(originTDataRef.current, assessmentJobs);
  //         setTableData(originTDataRef.current?.slice(0, pgShowCnt));
  //         setCurrentPage(1);
  //         setStep3CurrentPageNumber && setStep3CurrentPageNumber(1);
  //         setStep3TableData && setStep3TableData([...originTDataRef?.current?.slice(0, 10)]);
  //         setStep3AllTableData && setStep3AllTableData(mergeSameEleInTwoArrays(originTDataRef.current, assessmentJobs));
  //         setStartScanBtnDisabled && setStartScanBtnDisabled(false);
  //       }

  //       if (assessmentJobs && res?.type === 'completed') {
  //         let failedJobs = assessmentJobs?.filter((job: any) => job.scanStatus > 0);
  //         if (failedJobs.length > 0) {
  //           let projectName = res?.assessment?.productName;
  //           toaster.notify(
  //             <Notification
  //               title={`${projectName} successfully scanned.`}
  //               message="See the scan results on the product’s dashboard."
  //               status="success"
  //               hyperLink={
  //                 <a
  //                   tabIndex={0}
  //                   role="link"
  //                   className="ap-notification-hyperlink ap-link height"
  //                   onClick={goToDashboardHandler}
  //                 >
  //                   Go to dashboard
  //                 </a>
  //               }
  //             />,
  //             {
  //               position: 'bottomLeft',
  //               duration: 0,
  //               onClose: () => {},
  //             },
  //           );
  //         }
  //       }
  //     });
  //   return () => {
  //     if (wsService) {
  //       wsService.unSubscriber('lastestAssessment');
  //     }
  //     if (lastestAssessmentMsgSub) {
  //       lastestAssessmentMsgSub.unsubscribe();
  //     }
  //   };
  // });

  const startScanClick = () => {
    setReScanBtnDisabled && setReScanBtnDisabled(true);

    setModalTitle('Scan in progress');

    setModalFrom('scanInprogress');
    setStartScan(true);
    setCloseScanModalStatus && setCloseScanModalStatus(false);
    setCloseScanModalMethod && setCloseScanModalMethod(false);
    setFinalBoldText('Scan in progress.');
    setFinalDescText("Feel free to close this window. We'll notify you once the scan is completed.");
    setFinalImgSrc(ScanInProgressSvg);
    setFinalFooter(
      <InprogressCloseBtn setModalFunc={setVisibleFunc} setCloseScanModalStatus={setCloseScanModalStatus} />,
    );
    setFinalClassName('scanInprogressModal');

    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    setIsAssetAssessmentScanning(true);

    const param = {
      productId: String(curProductInfo?.id || productId),
      urls: originTDataRef?.current.map((data: any) => ({
        siteUrl: data?.url,
        nickName: data?.pageTitle,
      })),
    };
    setOriginTData?.(originTDataRef.current);
    autoScanTaskCreateRef.current = productService
      .v2SaveAutoScanUrls(param)
      .pipe(
        take(1),
        concatMap((res: any) => {
          if (res) {
            return assetService.createAssessmentWork(curProductInfo?.id || productId, null);
          }
          return of(null);
        }),
        take(1),
      )
      .subscribe((createAssessmentWorkRes: any) => {
        if (createAssessmentWorkRes && createAssessmentWorkRes.status === 1) {
          setStartScan(false);
        } else {
          console.error('create assessment failed');
        }
      });
  };

  const onActionDeleteClick = (row: any) => {
    const pgCnt = pgShowCnt;
    originTDataRef.current = originTDataRef?.current.filter((item: any) => item.siteUrl !== row.siteUrl);
    const curRestData = tableData.filter((item) => item.siteUrl !== row.siteUrl);

    if (curRestData.length === 0) {
      setCurrentPage((prev) => prev - 1);
      setTableData(originTDataRef.current.slice((currentPage - 2) * pgCnt, currentPage * pgCnt));
    } else {
      setTableData(originTDataRef.current.slice((currentPage - 1) * pgCnt, currentPage * pgCnt));
    }
  };

  const onDeleteIconKeyDown = (event: React.KeyboardEvent, row: any) => {
    const key = event.key.toLowerCase();
    if (key === 'enter') {
      onActionDeleteClick(row);
    }
  };

  const renderToActionCell = (row: any, field: string) => {
    return (
      <span
        className="Appkit4-icon icon-delete-outline"
        onClick={() => {
          onActionDeleteClick(row);
        }}
        tabIndex={0}
        aria-label="Delete"
        role='button'
        onKeyDown={(event: React.KeyboardEvent) => onDeleteIconKeyDown(event, row)}
      ></span>
    );
  };

  const renderToUrlCell = (row: any, field: string) => {
    return (
      <a href={row.url} target="_blank" rel="noopener noreferrer">
        {row.url}
      </a>
    );
  };

  const [currentPage, setCurrentPage] = React.useState(1);

  const renderToScanStatusCell = (row: any, field: string) => {
    const { scanStatus } = row;

    const getStatusColor = () => {
      if (scanStatus >= 3) return { color: 'scanModal-completeColor', text: 'Completed' };
      if (scanStatus >= 2 || isAssetAssessmentScanning) return { color: 'scanModal-scaningColor', text: 'Scanning...' };
      if (scanStatus >= 1) return { color: 'scanModal-pendingColor', text: 'Pending' };
      if (scanStatus < 0) return { color: 'scanModal-errorColor', text: 'Error' };
      return { color: 'scanModal-pendingColor', text: 'Pending' };
    };

    return (
      <div className="scanModal-table-ScanStatus">
        <span className={`Appkit4-icon icon-hb-full-fill ${getStatusColor()?.color}`}></span>
        <span>{getStatusColor()?.text}</span>
      </div>
    );
  };

  const contactUsHandler = () => {
    window.open(
      'https://forms.office.com/Pages/ResponsePage.aspx?id=oJQyUSA-skGpcG0wvxVG-qJtLN_BonZFrxiPNTnvCsRUNFUzWUwySEdIUjJWN1RXNkhENjYwUjhNNC4u',
      '_blank',
    );
  };

  const goToDashboardHandler = () => {
    if (location?.pathname === '/v2/dwrapper/board') {
      setVisibleFunc(false);
      reload && reload();
      return;
    }
    navigate(
      `/v2/dwrapper/board?appId=${curProductInfo?.appId || (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.appId)}`,
    );
  };

  const modalTextImghandler = (assessmentJobs: any[] = []) => {
    const failedJobs = assessmentJobs?.filter((job) => job.scanStatus < 0);
    let [_boldText, _descText, _imgSrc, _footer, _className] = ['', '', '', <></>, ''];
    if (failedJobs.length === 0) {
      _boldText = 'Scan complete!';
      _className = 'scanCompleteModal';
      _descText = 'Go to your dashboard to see the scan results.';
      _imgSrc = SuccessSvg;
      _footer = (
        <Button type="primary" onClick={goToDashboardHandler}>
          Go to dashboard
        </Button>
      );
    } else if (failedJobs.length === assessmentJobs.length) {
      _boldText = `Scan couldn't be completed on ${curProductInfo?.name}.`;
      _className = 'scanCouldnNotBeCompleted';
      _descText = 'Please contact us for further assistance.';
      _imgSrc = CouldNotBeCompleteSvg;
      _footer = (
        <Button type="primary" onClick={contactUsHandler}>
          Contact us
        </Button>
      );
    } else if (failedJobs.length < assessmentJobs.length) {
      _boldText = `Scan partially completed on ${curProductInfo?.name}.`;
      _className = 'scanPartCompleted';
      _descText = 'See the results of the scanned pages on your dashboard. Contact us for support with scan errors.';
      _imgSrc = PartiallyCompleteSvg;
      _footer = (
        <>
          <Button type="secondary" onClick={contactUsHandler}>
            Contact us
          </Button>
          <Button type="primary" onClick={goToDashboardHandler}>
            Go to dashboard
          </Button>
        </>
      );
    }
    setFinalBoldText(_boldText);
    setFinalDescText(_descText);
    setFinalImgSrc(_imgSrc);
    setFinalFooter(_footer);
    setFinalClassName(_className);
  };

  const onPageChange = (page: number) => {
    let temp = page <= 0 ? 1 : page;
    setCurrentPage(temp);
    setTableData(originTDataRef.current.slice((page - 1) * pgShowCnt, page * pgShowCnt));
  };

  const getShowingParam = () => {
    let start = pgShowCnt * currentPage - (pgShowCnt - 1);
    let end =
      originTDataRef.current.length < currentPage * pgShowCnt ? originTDataRef.current.length : currentPage * pgShowCnt;
    return {
      start,
      end,
    };
  };

  const onPageUrlInputChange = (value: string, event: any) => {
    let temp: string = '';
    if (value.includes(',')) {
      const _urls = value.split(',');
      temp = _urls.map((url) => getClickableLink(url)).join('\n');
    } else if (value.includes('\n')) {
      const _urls = value.split('\n').filter((itm) => itm !== 'https://');
      temp = _urls.map((url) => getClickableLink(url)).join('\n');
    } else if (value === 'https://') {
      temp = '';
    } else {
      temp = getClickableLink(value);
    }
    setReRunInputval(temp);

    const checkedData = temp.split('\n');
    const validateRes = validateUrlForAutoScan(
      checkedData,
      originTDataRef?.current?.map((xx: any) => xx?.url),
    );
    if (validateRes) {
      setTextAreaError({ ...validateRes, errorFlag: true });
    } else {
      setTextAreaError({ errorFlag: false, errMsg: '' });
    }
  };

  const renderToPageTitleCell = (row: any, field: string) => {
    const pgCnt = pgShowCnt;

    return modalFrom === 'scanInprogress' ? (
      <div>
        <span>{row.pageTitle?row.pageTitle:"[No title provided]"}</span>
      </div>
    ) : (
      <div>
        <Input
          type="text"
          hideTitleOnInput={true}
          value={row.pageTitle}
          title={"Enter page title"}
          // defaultValue={row.pageTitle}
          onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
            originTDataRef.current = originTDataRef?.current.map((ele: any) =>
              ele.url === row.url ? { ...ele, page: value, pageTitle: value } : { ...ele },
            );
            setTableData(
              originTDataRef?.current
                .slice((currentPage - 1) * pgCnt, currentPage * pgCnt)
                .map((ele: any) => (ele.url === row.url ? { ...ele, page: value, pageTitle: value } : ele)),
            );
          }}
        />
      </div>
    );
  };

  const addUrlBtnCLickHandler = () => {
    let _data: any[];
    if (modalFrom === 'reRun') {
      _data = reRunInputval.split('\n').map((url: string, idx: number) => ({
        id: idx + 1,
        pageTitle: '',
        url,
        scanStatus: 'Pending',
        action: 'Delete',
      }));
    } else {
      _data = reRunInputval.split('\n').map((url: string, idx: number) => ({
        id: idx + 1,
        page: '',
        url,
        scanStatus: 'Pending',
        action: 'Delete',
      }));
    }

    const _res = [..._data, ...originTDataRef?.current]
      .map((ele, idx) => ({ ...ele, id: idx + 1 }))
      .filter((ele) => ele.url);

    originTDataRef.current = _res;

    setCurrentPage(1);

    const _temp = originTDataRef.current.map((ele: any, idx: number) => ({ ...ele, id: idx + 1 })).slice(0, pgShowCnt);
    setTableData(_temp);

    setReRunInputval('');
  };

  const scanInprogressModalTableHeaders = [
    {
      name: modalFrom === 'reRun' ? 'Page title' : 'Page',
      value: modalFrom === 'reRun' ? 'pageTitle' : 'page',
      field: modalFrom === 'reRun' ? 'pageTitle' : 'page',
      renderCell: renderToPageTitleCell,
    },
    {
      name: 'URL',
      value: 'url',
      field: 'url',
      renderCell: renderToUrlCell,
    },
    {
      name: modalFrom === 'reRun' ? 'Action' : 'Scan status',
      value: modalFrom === 'reRun' ? 'action' : 'scanStatus',
      field: modalFrom === 'reRun' ? 'action' : 'scanStatus',
      renderCell: modalFrom === 'reRun' ? renderToActionCell : renderToScanStatusCell,
    },
  ];

  const modalContent = {
    title: modalTitle,
    footer: finalFooter,
    body: (
      <div className="scanModal">
        {/* <div className={`scanModal-imgPart ${modalFrom === 'reRun' ? 'reRunImg' : ''}`}>
          <img src={finalImgSrc} alt="decorative" />
        </div> */}
        <div className="scanModal-textPart">
          {/* <h2>{finalBoldText}</h2> */}
          <span>{finalDescText}</span>
        </div>
        <div className="ap-page-section-desc ap-mt-spacing-3 notification-panel">
          <span className="Appkit4-icon icon-information-outline"></span>
          <div>When scanning external pages (websites or URLs outside of PwC’s internal network), please remember to start by submitting a <a href="https://forms.office.com/r/TGReUVqfwc" target="_blank">whitelisting request</a>. </div>
        </div>
        {modalFrom && modalFrom === 'reRun' && !startScan && (
          <div className="scanModal-enterPart">
            <Input
              error={textAreaError?.errorFlag}
              errorNode={<span>{textAreaError?.errMsg}</span>}
              placeholder={'Enter page URL (one per line or comma separated)'}
              hideTitleOnInput={true}
              type={'text'}
              value={reRunInputval}
              onChange={onPageUrlInputChange}
            />{' '}
            <Button
              kind="secondary"
              onClick={addUrlBtnCLickHandler}
              disabled={!reRunInputval || textAreaError?.errorFlag}
            >
              Add URL
            </Button>
          </div>
        )}
        <div className="scanModal-tablePart">
          <div
            className={`scanModal-tablePart-showAndUnreachPart ${originTDataRef?.current?.length >= pgShowCnt ? '' : 'flexRevert'}`}
          >
            {originTDataRef?.current?.length >= pgShowCnt ? (
              <div className="scanModal-tablePart-showingPart">
                Showing {getShowingParam().start}-{getShowingParam().end} of {originTDataRef?.current.length}
              </div>
            ) : null}
            {originTDataRef?.current?.filter((u: any) => u.scanStatus < 0)?.length > 0 ? (
              <div className="scanModal-tablePart-showAndUnreachPart-showingPart">
                <span
                  className={`Appkit4-icon icon-hb-full-fill scanModal-tablePart-showAndUnreachPart-erorColor`}
                ></span>
                <span className={`scanModal-tablePart-showAndUnreachPart-spanLast`}>Unreachable URL.</span>
              </div>
            ) : null}
          </div>
          <div className={'scanModal-tablePart-table'}>
            <Table originalData={tableData} hasTitle striped condensed>
              {scanInprogressModalTableHeaders.map((header: any, idx: number) => (
                <Column
                  key={`${idx}-${header.value}`}
                  field={header.field}
                  sortKey={header.sortKey}
                  sortFunc1={header.sortFunc1}
                  sortFunc2={header.sortFunc2}
                  renderCell={header.renderCell}
                >
                  {header.name}
                </Column>
              ))}
            </Table>
          </div>
          {originTDataRef?.current.length >= pgShowCnt ? (
            <div className="scanModal-tablePart-pagnation">
              <Pagination
                current={currentPage}
                total={Math.ceil(originTDataRef?.current.length / pgShowCnt)}
                onPageChange={onPageChange}
                toPreviousPage=""
                toNextPage=""
              ></Pagination>
            </div>
          ) : null}
        </div>
      </div>
    ),
  };

  const onModalClose = () => {
    setModalFrom('');
    if (wsAssessmentJobs && wsAssessmentJobs?.length > 0) {
      reload && reload();
    }
    setWsAssessmentJobs([]);
    setCloseScanModalStatus && setCloseScanModalStatus(true);
  };

  return (
    <CommonModal
      visible={visible&&!showVerificationCode}
      modalContent={modalContent}
      setVisibleFunc={setVisibleFunc}
      className={finalClassName}
      bodyStyle={bodyStyle}
      onClose={onModalClose}
    />
  );
};

export default ScanModal;
