import React, { useEffect, useState } from 'react';
import './index.scss';
import { getNameAbbrev, capitalizeFirstLetter } from '@utils/common';
import { Avatar } from '@appkit4/react-components/avatar';

const AvatarNew = (props: any) => {
  const { userInfo, tabIndex = 0 } = props;
  const [finalUserInfo, setFinalUserInfo] = useState<any>(userInfo);
  useEffect(() => {
    const namePart = userInfo?.id?.split('@')[0];
    const firstName = capitalizeFirstLetter(userInfo.firstName ? userInfo.firstName : namePart?.split('.')[0]);
    const lastName = capitalizeFirstLetter(userInfo.lastName ? userInfo.lastName : namePart?.split('.')[1]);
    setFinalUserInfo({
      ...userInfo,
      firstName,
      lastName,
      formattedName: userInfo?.formattedName
        ? userInfo.formattedName
        : `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`,
    });
  }, [userInfo]);
  return (
    <div className={'avatarNew-assignedUserPart-usrContainer'}>
      <div className={'avatarNew-assignedUserPart-usrContainer-nickName'}>
        <Avatar
          tabIndex={tabIndex}
          disabled={false}
          role="button"
          label={getNameAbbrev(finalUserInfo?.firstName, finalUserInfo?.lastName)}
          ariaLabel={`User Profile,${finalUserInfo?.firstName} ${finalUserInfo?.lastName}`}
        />
      </div>
      <div className={'avatarNew-assignedUserPart-usrContainer-nameEmail'}>
        <span>{finalUserInfo.formattedName}</span>
        {/*<span>{userInfo.id}</span>*/}
      </div>
    </div>
  );
};
export default AvatarNew;
