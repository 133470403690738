import { AuthUserType, ResponseStatus } from '@models';
import { Observable } from 'rxjs';
import { apiService, authApiService } from './rxjs.service';
import { getCookie, setCookie } from '@utils/common';

function getAuthUserInfo(): Observable<AuthUserType> {
  return authApiService.get(`/.auth/me`);
}

function refreshAppServiceAuthSession(): Observable<any> {
  return authApiService.get(`/.auth/refresh`);
}

function logout(onlyRevokeAction = true): void {
  const appUrl = process.env.REACT_APP_app_url || 'https://pisc-accessibility-dev.azurewebsites.net';
  const revokeAction = () => {
    setCookie('idToken', '');
    setCookie('azureAuthUser', '');
    setCookie('checkSuccess', '');
    sessionStorage.setItem('loginType', '');
    // sessionStorage.setItem('issueFilterConfig', '[]');
    window.localStorage.clear();
    window.location.href = `${appUrl}/.auth/logout`;
    const env = process.env.REACT_APP_env;
    if (env !== 'local') {
      sessionStorage.setItem('currentUrl', window.location.href);
    }
    window.location.href = appUrl.endsWith('/')?  `${appUrl}v2/` : `${appUrl}/v2/`;
  }
  if(onlyRevokeAction)
    revokeAction();
  else
    revokeIdToken().subscribe(res => {
      revokeAction();
    });
}

const getIAMCode = (): Observable<any> => {
  return apiService.get(`https://login-stg.pwc.com/openam/oauth2/authorize?response_type=code&client_id=urn:dev.accessibility.pwcinternal.com&
  redirect_uri=https://dev.accessibility.pwcinternal.com/v2/signin&client_secret=EtwlR5Gh1pBDqYJLUCUl&scope=profile+openid+email`);
};

const getAccessToken = (code: string): Observable<any> => {
  const body: any = {
    code,
    grant_type: 'authorization_code',
    redirect_uri: `${process.env.REACT_APP_app_redirect_url}/signin`,
    client_id: process.env.REACT_APP_app_client_id,
    client_secret: process.env.REACT_APP_app_client_secret
  };
  return apiService.postWithContentType(`https://login-stg.pwc.com/openam/oauth2/access_token`, 
    Object.keys(body).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`).join('&'),
    'application/x-www-form-urlencoded'
    );
}

const revokeIdToken = (): Observable<any> => {
  return apiService.post(`/user/accessToken/logout`, {});
}

const revokeAccessToken = (): Observable<any> => {
  const body: any = {
    token: getCookie('idToken'),
    client_id: process.env.REACT_APP_app_client_id||'urn:dev.accessibility.pwcinternal.com',//process.env.REACT_APP_app_client_id,
    client_secret: process.env.REACT_APP_app_client_secret//process.env.REACT_APP_app_client_secret
  };
  // return apiService.post(`https://login-stg.pwc.com/openam/oauth2/token/revoke`, body);
  return apiService.postWithContentType(`https://login-stg.pwc.com/openam/oauth2/token/revoke`, 
    Object.keys(body).map(key => `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}`).join('&'),
    'application/x-www-form-urlencoded'
    );
}

const login = (code: string): Observable<any> => {
  const body: any = {
    code,
    from: sessionStorage.getItem('loginType')
  };
  return apiService.post(`/user/accessToken/login`, body);
}

const refreshToken = (): Observable<any> => {
  const body: any = {};
  return apiService.post(`/user/accessToken/refresh`, body);
}

export const authService = {
  getAuthUserInfo,
  refreshAppServiceAuthSession,
  logout,
  getIAMCode,
  getAccessToken,
  revokeAccessToken,
  login,
  refreshToken
};
