import React, { useEffect, useState, useRef } from 'react';
import { Table, Column } from '@appkit4/react-components/table';
import { Input } from '@appkit4/react-components/field';
import { Pagination } from '@appkit4/react-components/pagination';
import { Button } from '@appkit4/react-components/button';
import { TextArea } from '@appkit4/react-components/field';
import './index.scss';
import { Modal } from '@appkit4/react-components/modal';
import { getClickableLink, validateUrlForAutoScan } from '@utils';
import { productService } from '@services';
import { take } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { isEqual } from 'lodash';

const EditPagesToScanModal = (props: any) => {
  const {
    visible = true,
    setVisibleFunc,
    bodyStyle = {},
    paramTblData,
    saveChangesClickHandler,
    saveChangesLoading,
  } = props;
  const textAreaRef = useRef<any>();
  const [pageUrlInputVal, setPageUrlInputVal] = useState('');
  const latestTableDataRef = useRef<any[]>(paramTblData);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [tableData, setTableData] = useState<any[]>([]);
  const editPagesToScanModalRef = useRef<any>();
  const finalPgSnt = 5;

  useEffect(() => {
    setTableData([...paramTblData.map((d: any) => ({ ...d, page: d.pageTitle }))].slice(0, finalPgSnt));
    latestTableDataRef.current = paramTblData.map((d: any) => ({ ...d, page: d.pageTitle }));
  }, [paramTblData]);

  // useEffect(() => {
  //   if(visible){
  //     setTableTabIndex(editPagesToScanModalRef);
  //   }
  // }, [visible])

  const [textAreaError, setTextAreaError] = useState<any>();

  const onPageUrlInputChange = (value: string, event: any) => {
    let temp: string = '';
    if (value.includes(',')) {
      const _urls = value.split(',');
      temp = _urls.map((url) => getClickableLink(url)).join('\n');
    } else if (value.includes('\n')) {
      const _urls = value.split('\n').filter((itm) => itm !== 'https://');
      temp = _urls.map((url) => getClickableLink(url)).join('\n');
    } else if (value === 'https://') {
      temp = '';
    } else {
      temp = getClickableLink(value);
    }
    setPageUrlInputVal(temp);

    const checkedData = temp.split('\n');
    const validateRes = validateUrlForAutoScan(
      checkedData,
      latestTableDataRef?.current?.map((xx) => xx?.url),
    );
    if (validateRes) {
      setTextAreaError({ ...validateRes, errorFlag: true });
    } else {
      setTextAreaError({ errorFlag: false, errMsg: '' });
    }

    textAreaRef.current.style.height = 'auto';
    setTimeout(() => {
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';
    }, 0);

    // todo 1 去校验是否名字重复
    // setFormProductNameInputError(true);
    // todo 2 是否为空 等等
  };

  const addUrlBtnCLickHandler = () => {
    const _data = pageUrlInputVal.split('\n').map((url, idx) => ({
      id: idx + 1,
      pageTitle: '',
      url,
      scanStatus: 'Pending',
      action: 'Delete',
    }));

    const _res = [..._data, ...latestTableDataRef?.current]
      .map((ele, idx) => ({ ...ele, id: idx + 1 }))
      .filter((ele) => ele.url);

    latestTableDataRef.current = _res;

    setCurrentPage(1);

    const _temp = latestTableDataRef.current.map((ele, idx) => ({ ...ele, id: idx + 1 })).slice(0, finalPgSnt);
    setTableData(_temp);
    setPageUrlInputVal('');

    textAreaRef.current.style.height = '20px';
    textAreaRef.current.style['min-height'] = '20px';
  };

  const getShowingParam = () => {
    const pgCnt = finalPgSnt;
    let start = pgCnt * currentPage - (pgCnt - 1);
    let end =
      latestTableDataRef?.current.length < currentPage * pgCnt
        ? latestTableDataRef?.current.length
        : currentPage * pgCnt;
    return {
      start,
      end,
    };
  };

  const onActionDeleteClick = (row: any) => {
    const pgCnt = finalPgSnt;
    latestTableDataRef.current = latestTableDataRef?.current.filter((item) => item.url !== row.url);
    const curRestData = tableData.filter((item) => item.url !== row.url);

    if (curRestData.length === 0) {
      setCurrentPage((prev) => prev - 1);
      setTableData(latestTableDataRef.current.slice((currentPage - 2) * pgCnt, currentPage * pgCnt));
    } else {
      setTableData(latestTableDataRef.current.slice((currentPage - 1) * pgCnt, currentPage * pgCnt));
    }
  };

  const onDeleteIconKeyDown = (event: React.KeyboardEvent, row: any) => {
    const key = event.key.toLowerCase();
    if (key === 'enter') {
      onActionDeleteClick(row);
    }
  };

  const renderToActionCell = (row: any, field: string) => {
    return (
      <span
        className="Appkit4-icon icon-delete-outline"
        onClick={() => {
          onActionDeleteClick(row);
        }}
        tabIndex={0}
        aria-label="Delete"
        role='button'
        onKeyDown={(event: React.KeyboardEvent) => onDeleteIconKeyDown(event, row)}
      ></span>
    );
  };

  const renderToUrlCell = (row: any, field: string) => {
    return (
      <a href={row.url} target="_blank" rel="noopener noreferrer">
        {row.url}
      </a>
    );
  };
  const renderToPageTitleCell = (row: any, field: string) => {
    const pgCnt = finalPgSnt;
    return (
      <div>
        <Input
          type="text"
          hideTitleOnInput={true}
          value={row.pageTitle}
          title={"Enter page title"}
          onChange={(value: string, event?: React.ChangeEvent<HTMLInputElement>) => {
            latestTableDataRef.current = latestTableDataRef?.current.map((ele) =>
              ele.url === row.url ? { ...ele, pageTitle: value } : { ...ele, pageTitle: ele.pageTitle },
            );
            setTableData(
              latestTableDataRef?.current
                .slice((currentPage - 1) * pgCnt, currentPage * pgCnt)
                .map((ele) => (ele.url === row.url ? { ...ele, pageTitle: value } : ele)),
            );
          }}
        />
      </div>
    );
  };

  const pagesToScanModalTableHeaders = [
    {
      name: 'Page title',
      value: 'pageTitle',
      field: 'pageTitle',
      // sortKey: 'pageTitle',
      // sortFunc1: '',
      // sortFunc2: '',
      renderCell: renderToPageTitleCell,
    },
    {
      name: 'URL',
      value: 'url',
      field: 'url',
      // sortKey: 'url',
      // sortFunc1: '',
      // sortFunc2: '',
      renderCell: renderToUrlCell,
    },
    {
      name: 'Action',
      value: 'action',
      field: 'action',
      // sortKey: 'action',
      // sortFunc1: '',
      // sortFunc2: '',
      renderCell: renderToActionCell,
    },
  ];

  const onPageChange = (page: number, pgCnt: number) => {
    let temp = page <= 0 ? 1 : page;
    setCurrentPage(temp);
    setTableData(latestTableDataRef.current.slice((page - 1) * pgCnt, page * pgCnt));
  };

  const modalContent = {
    title: 'Pages to scan',
    footer: (
      <>
        <Button
          kind="secondary"
          onClick={() => {
            setVisibleFunc(false);
          }}
        >
          Cancel
        </Button>{' '}
        <Button
          loading={saveChangesLoading}
          kind="primary"
          onClick={() => saveChangesClickHandler(latestTableDataRef?.current)}
          disabled={isEqual(latestTableDataRef?.current.map(({ id,selected,_initialSortIndex,_previousSortIndex,_nextSortIndex, ...rest }: any) => ({ ...rest })), paramTblData.map(({ id, ...rest }: any) => ({ ...rest, page: rest.pageTitle })))}
        >
          Save changes
        </Button>
      </>
    ),
    body: (
      <div className="editPagesToScanModal" ref={editPagesToScanModalRef}>
        <div className="editPagesToScanModal-enterPart">
          <TextArea
            title={'Enter page URL (one per line or comma separated)'}
            required={true}
            inputRef={textAreaRef}
            value={pageUrlInputVal}
            onChange={onPageUrlInputChange}
            autosize={true}
            error={textAreaError?.errorFlag}
            errorNode={<span>{textAreaError?.errMsg}</span>}
            onBlur={() => {
              if (!pageUrlInputVal) {
                textAreaRef.current.style.height = '20px';
                textAreaRef.current.style['min-height'] = '20px';
              }
            }}
          ></TextArea>
          <Button
            kind="secondary"
            onClick={addUrlBtnCLickHandler}
            disabled={!pageUrlInputVal || textAreaError?.errorFlag}
          >
            Add URL
          </Button>
        </div>
        <div className="editPagesToScanModal-tablePart">
          {latestTableDataRef?.current?.length >= finalPgSnt ? (
            <div className="editPagesToScanModal-tablePart-showingPart">
              Showing {getShowingParam().start}-{getShowingParam().end} of {latestTableDataRef?.current.length}
            </div>
          ) : null}
          <div className={'editPagesToScanModal-tablePart-table'}>
            <Table originalData={tableData} hasTitle striped condensed>
              {pagesToScanModalTableHeaders.map((header: any, idx: number) => (
                <Column
                  key={`${idx}-${header.value}`}
                  field={header.field}
                  sortKey={header.sortKey}
                  sortFunc1={header.sortFunc1}
                  sortFunc2={header.sortFunc2}
                  renderCell={header.renderCell}
                >
                  {header.name}
                </Column>
              ))}
            </Table>
          </div>
          {latestTableDataRef?.current?.length >= finalPgSnt ? (
            <div className="editPagesToScanModal-tablePart-pagnation">
              <Pagination
                current={currentPage}
                total={Math.ceil(latestTableDataRef?.current.length / finalPgSnt)}
                onPageChange={(page) => onPageChange(page, finalPgSnt)}
                toPreviousPage=""
                toNextPage=""
              ></Pagination>
            </div>
          ) : null}
        </div>
      </div>
    ),
  };
  return (
    <Modal
      visible={visible}
      title={modalContent.title}
      ariaLabel={modalContent.title}
      onCancel={() => setVisibleFunc(false)}
      modalStyle={{ width: '33.75rem' }}
      // footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
      header={''}
      icons={''}
      footer={modalContent.footer}
      bodyStyle={Object.assign({ minHeight: '92px' }, bodyStyle)}
      className="editPageToScanModal"
      onClose={() => {
        setPageUrlInputVal('');
        setCurrentPage(1);
        setTableData([...paramTblData.map((d: any) => ({ ...d, page: d.pageTitle }))].slice(0, finalPgSnt));
        latestTableDataRef.current = paramTblData.map((d: any) => ({ ...d, page: d.pageTitle }));
      }}
      // initialFocus={false}
    >
      {modalContent.body}
    </Modal>
  );
};

export default EditPagesToScanModal;
