import React, { useRef, useState } from 'react';
import { Notification, toaster } from '@appkit4/react-components/notification';
import './notification.scss';

const NotificationNew = (props: any) => {
  const { title = '', message = '', status = '' } = props;
  const [notificationShow, setNotificationShow] = useState(true);
  const closeNotification = () => {
    setNotificationShow(false);
  };
  // return (
  //   <div className={`notification ${notificationShow ? '' : 'notification-hide'}`}>
  //     <div className="iconDescPart">
  //       <div className="notification-header-icon">
  //         <span className="Appkit4-icon icon-circle-checkmark-fill"></span>
  //       </div>
  //       <div className="notification-desc">
  //         <span>The issue has been successfully [closed / reopened].</span>
  //       </div>
  //     </div>

  //     <div className="notification-close" onClick={closeNotification}>
  //       <span className="Appkit4-icon icon-close-outline"></span>
  //     </div>
  //   </div>
  // );

  return <Notification title={title} message={message} status={status} />;
};

export default NotificationNew;
