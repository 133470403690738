import React, { useEffect, useRef, useState } from 'react';
import './index.scss';
import { Tabs, Tab } from '@appkit4/react-components/tabs';
import { TextEditor } from '@appkit4/react-text-editor';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Upload } from '@appkit4/react-components/upload';
import { Button } from '@appkit4/react-components';
import { issueService } from '@services/issue.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { axios } from '@services/axios.service';
import { Notification, toaster } from '@appkit4/react-components/notification';
import { getCookie } from '@utils/common';

const tabLabels = [
  { name: 'Write', value: 'write' },
  { name: 'Preview', value: 'preview' },
];

const FeedbackCommentComponent = (props: any) => {
  const { issue, curIssueTypeTabIdx, memberInfo } = props;
  const [commentActiveIndex, setCommentActiveIndex] = useState(0);
  const [showSmallTitle, setShowSmallTitle] = useState(false);
  const [editorFocusStatus, setEditorFocusStatus] = useState(false);
  const [editorValue, setEditorValue] = useState('');
  const [btnIsLoading, setBtnIsLoading] = useState(false);
  const tooltipContent =
    'In addition, do not enter in free text or upload (by means of screenshot or attached docuement) sensitive personal information, including but not limited to, that reveals racial or ethnic origin, political opinions, religious or philosophical beliefs, trade union membership, health or sexual orientation. Please click here to review PwC Data Classification Standard for additional information.';
  const editorContainerRef = useRef<any>(null);
  // const focusEvent = () => {
  //   const targetElement = editorContainerRef.current?.querySelectorAll('.ck.ck-button.ck-off')?.[0];
  //         if (targetElement) {
  //           const handleKeyDown = (event: any) => {
  //             if (event.shiftKey && event.key === 'Tab') {
  //               event.preventDefault();
  //               editorContainerRef.current?.querySelectorAll('.ap-tabset-toggle.has-label.active')?.[0]?.focus();
  //             }
  //           };
  
  //           targetElement.addEventListener('keydown', handleKeyDown);
  
  //           // Cleanup event listener on component unmount
  //           return () => {
  //             targetElement.removeEventListener('keydown', handleKeyDown);
  //           };
  //         }
  // }

  // useEffect(() => {
  //   setTimeout(() => {
  //     focusEvent();
  //   }, 0);
  // }, [])

  const onTabChange = (index: number) => {
    setCommentActiveIndex(index);
    if(index === 1) {
      setTimeout(() => {
        editorContainerRef.current?.querySelectorAll('.ck.ck-button.ck-disabled.ck-off')?.[0].setAttribute('tabindex', '-1');
      }, 0);
    } else {
      setTimeout(() => {
        editorContainerRef.current?.querySelectorAll('.ck.ck-button.ck-off')?.[0].setAttribute('tabindex', '0');
        // focusEvent();
      }, 0);
    }
  };
  const maxFileSize = 1 * 1024 * 1024;
  const idToken = getCookie('idToken') ? 'Bearer ' + getCookie('idToken') : '';
  const customUploader = {
    autoUpload: true,
    url: ``,
    headers: [{ name: 'Authorization', value: '' }],
    maxFileSize,
    queueLimit: 5,
  };
  const UploadRef = useRef<any>(null);
  const mentionTimmer = useRef<any>(null);
  const writeTimer = useRef<any>(null);
  const commentAssignUser = useRef<any[]>([]);
  const editorRef = useRef<any>(null);
  const customItemRenderer = (item: any) => {
    const divEle = document.createElement('div');
    divEle.classList.add('edit-list-container');
    divEle.insertAdjacentHTML('afterbegin', `
      <div tabIndex="0" class="list-content">
          <div class="avatar-name-box">
            <span class="name-text">${item.firstName?.charAt(0)}${item.lastName?.charAt(0)}</span>
          </div>
          <div class="userinfo-box">
            <span class="userinfo-full-name">${item.fullName}</span>
            <span class="userinfo-email">${item.userId}</span>
          </div>
      </div>
    `);//&nbsp;${item.country?"("+item.country+")":""}
    //aria-label=${item.firstName?.charAt(0)}${item.lastName?.charAt(0)}
    return divEle;
  }
  const getFeedItems = (queryText: any) => {
    if (mentionTimmer.current) {
      clearTimeout(mentionTimmer.current);
    }
    return new Promise(resolve => {
      mentionTimmer.current = setTimeout(() => {
          const itemsToDisplay = memberInfo.filter((item: any) => {
            const str = queryText.toLowerCase();
            return (
              item.fullName.toLowerCase().includes( str ) ||
              item.userId.toLowerCase().includes( str )
            );
          });
          resolve(itemsToDisplay);
        }, 100);
    });
  }
  // useEffect(() => {
  //   const handleDropdownFocus = () => {
  //     const mentionDropdown = document.querySelector('.ck-list.ck-mentions');
  //     if (mentionDropdown && (mentionDropdown as any).style.display !== 'none') {
  //       const focusableItems = mentionDropdown.querySelectorAll('.list-content');
  //       if (focusableItems.length > 0) {
  //         focusableItems[0].setAttribute('tabindex', '0'); // Make items focusable
  //         (focusableItems[0] as any).focus(); // Set focus to the first item
  //       }
  //     }
  //   };

  //   const handleEditorEvent = () => {
  //     setTimeout(handleDropdownFocus, 0);
  //   };

  //   const handleKeyDown = (event: any) => {
  //     if (event.key === 'Enter') {
  //       const focusedElement = document.activeElement;
  //       if (focusedElement && focusedElement.getAttribute('tabindex') === '0'
  //       && focusedElement.getAttribute('class') === 'list-content') {
  //         (focusedElement as any).click();
  //       }
  //     }
  //   };

  //   const editorElement = document.querySelector('.ck.ck-content.ck-editor__editable');
  //   if (editorElement) {
  //     editorElement.addEventListener('keyup', handleEditorEvent);
  //     window.addEventListener('keydown', handleKeyDown);
  //   }

  //   return () => {
  //     if (editorElement) {
  //       editorElement.removeEventListener('keyup', handleEditorEvent);
  //       window.removeEventListener('keydown', handleKeyDown);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    clearTimeout(writeTimer.current);
    writeTimer.current = setTimeout(() => {
      let result: any = [];
      let reg = new RegExp('(data-mention=").*?(")', 'g');
      result = editorValue.match(reg);
  
      // Clear the commentAssignUser array
      commentAssignUser.current = [];
  
      if (result && result.length > 0) {
        for (let i = 0; i < result.length; i++) {
          let rid = result[i].replace('data-mention="', '');
          let rstr = rid.replace('"', '');
          let mobj = memberInfo.filter((item: any) => item.id === rstr);
          let userEmailIndex = commentAssignUser.current.findIndex((f: any) => f?.userId === mobj[0].userId);
          if (userEmailIndex === -1) {
            commentAssignUser.current.push(mobj[0]);
          }
        }
      }
    }, 500);
  }, [editorValue]);
  const fileuploadConfig = {
    trigger: false,
    type: 'inline',
    size: true,
  };

  const mockTexteditorSampleConfig = {
    toolbar: ['bold', 'italic', 'link', 'bulletedList', 'numberedList', 'codeblock'],
    mention: {
      dropdownLimit: 20,
      feeds: [
        {
          marker: '@',
          feed: (searchUser: string) => getFeedItems(searchUser),
          itemRenderer: (item: any) => customItemRenderer(item)
        },
      ],
    },
    link: {
      defaultProtocol: 'https://',
      decorators: {
        addTargetToExternalLinks: {
          mode: 'automatic',
          callback: (url: any) => /^(https?:)?\/\//.test(url),
          attributes: {
            target: '_blank',
            rel: 'external url link',
          },
          name: 'a',
          classes: ['ap-link'],
        },
      },
    },
  };

  const onLabelFocus = () => {
    setShowSmallTitle(editorValue ? true : false);
  };

  const onEditorFocus = () => {
    setEditorFocusStatus(true);
    setShowSmallTitle(true);
  };

  const onEditorBlur = () => {
    setShowSmallTitle(editorValue ? true : false);
    setEditorFocusStatus(false);
  };

  const onWriteChange = (event: any, editor: any, data: any) => {
    if (data) {
      setShowSmallTitle(true);
    } else {
      setShowSmallTitle(editorFocusStatus ? true : false);
    }

    setEditorValue(data);
  };

  const onUploadChange = (file: File, fileList: FileList): void => {
    // console.log('onChange', file, fileList);
  };
  const uploadFiles = (fileList: any) => {
    console.log(fileList);
  };

  const onSubmitComment = () => {
    setBtnIsLoading(true);
    let addCommentSub: Subscription;
    const param = {
      productId: Number(sessionStorage.getItem('productId')),
      assessmentId: Number(localStorage.getItem('assessmentId')),
      number: issue.acNumber,
      comment: editorValue,
      mentionUserIds: commentAssignUser.current.map((item: any) => item.userId)
    };
    addCommentSub = issueService
      .addComment(param)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          setEditorValue('');
          setBtnIsLoading(false);
          // UploadRef.current.handleAllRemoveFile();
          const deleteIcon = document.querySelector('.ap-fileupload-footer-delete-attachment span[role="button"]');
          if (deleteIcon) {
            (deleteIcon as HTMLElement).click();
          }
          props.reloadParentComments();
        }
      });
    return () => {
      if (addCommentSub) addCommentSub.unsubscribe();
    };
  };
  const onCloseComment = () => {
    // setBtnIsLoading(true);
    let closeIssueSub: Subscription;
    const param = {
      productId: Number(sessionStorage.getItem('productId')),
      number: issue.acNumber,
      status: issue.issueType === 'close' ? 1 : 0,
      comment: editorValue,
    };
    closeIssueSub = issueService
      .setManualStatus(param)
      .pipe(take(1))
      .subscribe((res) => {
        if (res) {
          setEditorValue('');
          // setBtnIsLoading(false);
          // UploadRef.current.handleAllRemoveFile();
          const deleteIcon = document.querySelector('.ap-fileupload-footer-delete-attachment span[role="button"]');
          if (deleteIcon) {
            (deleteIcon as HTMLElement).click();
          }
          props.reloadParentComments();
          setTimeout(() => {
            toaster.notify(
              <Notification
                title=""
                message={`The issue has been successfully ${issue.issueType === 'close' ? 'reopened' : 'closed'}.`}
                status="success"
              />,
              { position: 'bottomLeft', duration: 5000 },
            );
          }, 500);
        }
      });
    return () => {
      if (closeIssueSub) closeIssueSub.unsubscribe();
    };
  };

  const onUploadSuccess = (file: any) => {
    // console.log('upload success');
    const formData = new FormData();
    formData.append('file', file.originFile);

    axios({
      method: 'post',
      url: '/questionnaire/issuePages/uploadFiles/v2',
      // headers: { User_id: "jiao.j.wang@pwc.com", authorization: idToken },
      data: formData,
    }).then((res: any) => {
      const url = res.url;
      const fullUrladdress = `<img alt="${file.name}" class="upload-image" src="${url}">`;
      setEditorValue((prevValue: string) => {
        return prevValue + '<br/>' + fullUrladdress;
      });
    });
  };

  const onUploadFail = (file: File) => {
    console.log('onUploadFail', file);
  };
  const onRemoveFile = (file: File, fileList: FileList) => {
    console.log('onUploadFail', file);
  };

  const btnElements = React.useMemo(() => {
    return (
      <>
        {curIssueTypeTabIdx === 0 ? null : (
          <Button kind="secondary" onClick={onCloseComment}>
            {issue.issueType === 'close' ? 'Reopen issue' : 'Close issue'}
          </Button>
        )}
        <Button
          kind="primary"
          className="comment-submit"
          loading={btnIsLoading}
          disabled={!editorValue}
          onClick={onSubmitComment}
          aria-label="Comment"
        >
          Comment
        </Button>
      </>
    );
  }, [curIssueTypeTabIdx, editorValue, issue, btnIsLoading]);

  const onUpload = (fileList: any) => {
    console.log('--fileList--:', fileList);
  };

  return (
    <div className="ap-feedback-comment" ref={editorContainerRef}>
      <div className="ap-feedback-comment-texteditor-area">
        <div className="tab-container">
          {
            <Tabs type="filled" activeIndex={commentActiveIndex} onTabChange={onTabChange}>
              {tabLabels.map((label: { name: string; value: string }, i: number) => (
                <Tab label={label.name} value={label.value} key={`${i}-${label.value}`}></Tab>
              ))}
            </Tabs>
          }
        </div>
        <div className={`comment-title ${showSmallTitle ? 'comment-title-small' : ''}`} onClick={onLabelFocus}>
          <span>Leave your comments</span>
        </div>
        <div className="write-container">
          {commentActiveIndex === 0 ? (
            <TextEditor
              onFocus={onEditorFocus}
              onBlur={onEditorBlur}
              config={mockTexteditorSampleConfig}
              data={editorValue}
              onChange={onWriteChange}
              ref={editorRef}
            />
          ) : (
            <TextEditor disabled={true} config={mockTexteditorSampleConfig} data={editorValue} ref={editorRef} />
          )}
        </div>
        <div className="notes">
          <span>No client information is permitted (i.e. client documents or work applications uploaded).</span>
          <Tooltip
            trigger="hover"
            position="top"
            distance={4}
            appendAfterTarget={false}
            hideTooltipOnBlur={true}
            content={tooltipContent}
            className="tooltip-newIssue"
          >
            <span className="Appkit4-icon ap-field-demo-icon-btn icon-information-outline"></span>
          </Tooltip>
        </div>
      </div>
      <div className={`ap-feedback-comment-upload-area`}>
        <div className="fileupload-demo-wrapper">
          <div className={`${commentActiveIndex === 1 ? 'hide' : ''}`}>
            <Upload
              ref={UploadRef}
              className="comment-upload"
              config={fileuploadConfig}
              onChange={onUploadChange}
              onSuccess={onUploadSuccess}
              onError={onUploadFail}
              onRemove={onRemoveFile}
              uploadInstruction="You can upload JPG or PNG file."
              acceptFileType=".JPG,.PNG"
              withCredentials={true}
              multiple={true}
              autoUpload={true}
              onUpload={onUpload}
              // headers={[
              //   { name: 'Authorization', value: idToken },
              //   { name: 'User_id', value: 'jiao.j.wang@pwc.com' },
              // ]}
              maxFileSize={10 * 1024 * 1024}
              // action="https://dev.accessibility.pwcinternal.com/api/questionnaire/issuePages/uploadFiles/v2"
            ></Upload>
            <div className="desc-upload-new">
              <span>You can upload JPG or PNG files. The max file size is 10mb.</span>
            </div>
          </div>
          <div className="opertaion-container">{btnElements}</div>
        </div>
      </div>
    </div>
  );
};

export default FeedbackCommentComponent;
