import { Subject } from 'rxjs';
import { Manager } from 'socket.io-client';
import { wsServiceType } from '@models';
import { authService } from './auth.service';

const uri = process.env.REACT_APP_websocket_url || 'http://localhost:8000';

const wsServiceListeners: { [name: string]: Subject<any> } = {};
const wsServiceListener = (event: string): Subject<any> => {
  if (!(!!wsServiceListeners && !!wsServiceListeners[event])) {
    wsServiceListeners[event] = new Subject();
  }
  return wsServiceListeners[event] as Subject<any>;
};

interface WsServiceSubscriberEvent {
  event: string;
  value: any;
}
let wsServiceSubscriberEvents: Array<WsServiceSubscriberEvent> = [];
const wsServiceSubscriber = new Subject<WsServiceSubscriberEvent>();
wsServiceSubscriber.subscribe((content: WsServiceSubscriberEvent) => {
  const _wsServiceSubscriberEvent = wsServiceSubscriberEvents.find(
    (eventContent) => eventContent.event === content.event,
  );
  if (!!_wsServiceSubscriberEvent) {
    ws.emit(content.event, `leave:${_wsServiceSubscriberEvent.value}`);
    _wsServiceSubscriberEvent.value = content.value;
  } else {
    wsServiceSubscriberEvents.push(content);
  }
  ws.emit(content.event, content.value);
});

const wsServiceUnSubscriber = (event: string) => {
  const _listenerEvent = `${event}Msg`;
  const _wsServiceSubscriberEvent = wsServiceSubscriberEvents.find((eventContent) => eventContent.event === event);
  if (!!wsServiceListeners && !!wsServiceListeners[_listenerEvent]) {
    wsServiceListeners[_listenerEvent].unsubscribe();
    delete wsServiceListeners[_listenerEvent];
  }
  if (!!_wsServiceSubscriberEvent) {
    ws.emit(_wsServiceSubscriberEvent.event, `leave:${_wsServiceSubscriberEvent.value}`);
    wsServiceSubscriberEvents = wsServiceSubscriberEvents.filter((eventContent) => eventContent.event !== event);
  }
};

export const wsService: wsServiceType = {
  listener: wsServiceListener,
  subscriber: wsServiceSubscriber,
  unSubscriber: wsServiceUnSubscriber,
};

const wsManager = new Manager(uri, {
  path: '/api/socket.io',
  autoConnect: false,
  reconnectionDelayMax: 10000,
  transports: ['websocket'],
});

const ws = wsManager.socket('/');
ws.connect();
wsManager.on('reconnect_attempt', (attempt: number) => {
  if (attempt > 10) {
    authService.logout();
  }
});
wsManager.on('reconnect_attempt', (attempt: number) => {
  wsServiceSubscriberEvents.forEach((eventContent) => {
    ws.emit(eventContent.event, eventContent.value);
  });
});

ws.onAny((event, value) => {
  if (!wsServiceListeners || !wsServiceListeners[event]) {
    wsServiceListeners[event] = new Subject();
  }

  wsServiceListeners[event].next(value);
});
