import React from 'react';
import AccessDeniedSvg from '@assets/icons/AccessDenied.svg';
import { Avatar } from '@appkit4/react-components/avatar';
import { getNameAbbrev, getRoleValue, capitalizeFirstLetter } from '@utils/common';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from '@appkit4/react-components';

export const TeamMemeberInfoBoard = (props: any) => {
  const { user } = props;
  const getAbbrevname = (): string | undefined => {
    return getNameAbbrev(user?.firstName, user?.lastName);
  };
  return (
    <div className="teamMemeberInfoBoard">
      <div className="teamMemeberInfoBoard-avatar">
        <Avatar
          tabIndex={0}
          disabled={false}
          role="button"
          label={getAbbrevname()}
          ariaLabel={`User Profile,${user?.firstName} ${user?.lastName}`}
        />
      </div>
      <div className="teamMemeberInfoBoard-userInfo">
        <span>{user?.formattedName}(US)</span>
        <span title={user?.email}>{user?.email || user?.id}</span>
      </div>
    </div>
  );
};

const ForbiddenPage = () => {
  const location = useLocation();
  const productName = location.state?.productName;
  const ownerEmail = location.state?.ownerEmail;
  const navigate = useNavigate();

  return (
    <div className="forbiddenPage">
      <div className="fb-bckgImg">
        <img src={AccessDeniedSvg} alt="Access denied" />
      </div>
      <div className="fb-boldText">
        <h2>Restricted access.</h2>
      </div>
      <div className="fb-descText">
        <div>Only team members added have access to {productName}.</div>
        <div>
          {' '}
          To request access to this product, please contact &nbsp;
          <a href={`mailto: ${ownerEmail}`} target="_blank" rel="noreferrer">
            {ownerEmail}
          </a>
        </div>
      </div>
      <div className="fb-btn">
        <Button
          kind="primary"
          onClick={() => {
            navigate('/v2/');
          }}
        >
          Go to home page
        </Button>
      </div>
    </div>
  );
};
export default ForbiddenPage;
