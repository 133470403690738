import React, { FC, memo, useState, useRef, useMemo, useEffect, useContext } from 'react';
import { Tag } from '@appkit4/react-components/tag';
import { Button } from '@appkit4/react-components/button';
import { IIssueDashboardType } from './types';
import './IssueDashboard.scss';
import { DropdownButton } from '@appkit4/react-components/dropdown';
import { Tooltip } from '@appkit4/react-components/tooltip';
import CommonModal from '@components/modals/CommonModal';
import { TextArea } from '@appkit4/react-components/field';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { issueService } from '@services/issue.service';
import { Subscription } from 'rxjs';
import { of } from 'rxjs';
import { catchError, concatMap, take } from 'rxjs/operators';
import { productService } from '@services';
import { Notification, toaster } from '@appkit4/react-components/notification';
import { decryptData, getCookie, getProductInfoCookie, safelyParseJSON } from '@utils/common';
import Manualpage from '@layout/ManualReview';
import { DeleteIssueConfirmation } from '@layout/ManualReview/DeleteIssueConfirmation';
import IconBadge from './IconBadge';
import { Badge } from '@appkit4/react-components';
import classNames from 'classnames';
import { EntryContext } from '@layout/Entry';
const IssueDashboard: FC<IIssueDashboardType> = memo((props) => {
  const navigate = useNavigate();
  const { issue, curIssueTypeTabIdx, onDeleteIssue, isPinned, reloadPage, isManualLastOne } = props;
  const { setMessageResult } = useContext(EntryContext);
  const manualPageRef = useRef<any>();
  const [reopenModalVisible, setReopenModalVisible] = useState(false);
  const [reopenModalCommentVal, setReopenModalCommentVal] = useState('');
  const [openOrCloseBtnLoading, setOpenOrCloseBtnLoading] = useState(false);
  const [visibleDeleteConfirmation, setVisibleDeleteConfirmation] = useState(false);
  const [search] = useSearchParams();
  const _appId = search.get('appId') ? search.get('appId') : '';

  const threeDropdownBtnRef = React.useRef<HTMLDivElement>(null);
  const pinIssueRef = React.useRef<any>(null);
  useEffect(() => {
    if (threeDropdownBtnRef.current) {
      const target = threeDropdownBtnRef.current.querySelector('.custom-node')!;
      target?.setAttribute('aria-label', 'Options');
    }
  }, [threeDropdownBtnRef]);

  const getBadgeType = () => {
    const curLevel = issue.issueLevel.toLowerCase();
    if (curLevel === 'critical') return 'danger-outlined';
    if (curLevel === 'moderate') return 'primary-outlined';
    if (curLevel === 'minor') return 'primary-outlined';
    if (curLevel === 'serious') return 'primary-outlined';
  };
  const getTagLevelClsName = () => {
    const curLevel = issue.issueLevel.toLowerCase();
    if (curLevel === 'critical') return 'issue-info-header-type-danger';
    if (curLevel === 'moderate') return 'issue-info-header-type-general ';
    if (curLevel === 'minor') return 'issue-info-header-type-installation';
    if (curLevel === 'serious') return 'issue-info-header-type-design';
  };

  const onPinIssue = (issue: any, isPin: boolean) => {
    const param = {
      issueId: issue.id,
      isPin
    };
    pinIssueRef.current = issueService
      .pinIssue(param)
      .pipe(take(1))
      .subscribe(
        (res) => {
          if (res.code === 1) {
            reloadPage?.();
          }
        },
        (error) => {
          console.log(error);
        },
      );
  };

  const dropdownSelecthandler = (value: any, item: any, event: any) => {
    event.stopPropagation();
    if (['cplink', 'mcplink'].includes(value)) {
      const tempHost = process?.env?.REACT_APP_app_url;
      const hostArr = tempHost?.split('.');
      if (hostArr) {
        const host = hostArr[hostArr.length - 1].includes('/') ? tempHost : `${tempHost}/`;
        const url = `${host}v2/dwrapper/issuedetail?appId=${_appId}&id=${issue.acNumber}`;
        navigator.clipboard.writeText(url);
        setTimeout(() => {
          setMessageResult?.(`Link copied`);
          setTimeout(() => setMessageResult?.(''), 100);
        }, 2000);
        return;
      }
    }
    if (['reopenissue', 'closeissue'].includes(value)) {
      setReopenModalVisible(true);
    }
    if (['editissue'].includes(value)) {
      manualPageRef.current.showEditModal(issue.auditItemId, issue.id);
    }
    if (['deleteissue'].includes(value)) {
      setVisibleDeleteConfirmation(true);
    }
    if(['pinissue'].includes(value)) {
      onPinIssue(issue, true)
    }
    if(['unpinissue'].includes(value)) {
      onPinIssue(issue, false)
    }
  };

  const getDropDownContent = (): any => {
    if (issue.auditType === 'auto')
      return [{
        label: 'Automated scan issue', type: 'group', children:
        [{
          value: 'cplink',
          label: 'Copy link',
          iconName: 'icon-link-outline',
        },
        {
          value: isPinned? 'unpinissue' : 'pinissue',
          label: isPinned? 'Unpin issue' : 'Pin issue',
          // value: 'unpinissue',
          // label: 'Unpin issue',
          iconName: 'icon-pin-outline',
        }
      ],
      }];
    if (issue.auditType === 'manual')
      return [{
        label: 'Manual review issue', type: 'group', children:
          [{
            value: 'mcplink',
            label: 'Copy link',
            iconName: 'icon-link-outline',
          },
          {
            value: 'editissue',
            label: 'Edit issue',
            iconName: 'icon-edit-outline'
          },
          {
            value: issue.issueType === 'open' ? 'closeissue' : 'reopenissue',
            label: issue.issueType === 'open' ? 'Close issue' : 'Reopen issue',
            iconName: issue.issueType === 'open' ? 'icon-circle-checkmark-outline' : 'icon-refresh-outline',
          },
          {
            value: isPinned? 'unpinissue' : 'pinissue',
            label: isPinned? 'Unpin issue' : 'Pin issue',
            iconName: 'icon-pin-outline',
          },
          {
            value: 'deleteissue',
            label: 'Delete issue',
            iconName: 'icon-delete-outline'
          }
        ]
      }];
  };

  const getTooltipContent = () => {
      return {
        content: issue.issueType === 'open' ? 'Open issue' : 'Closed issue',
        icon:
          issue.issueType === 'open' ? (
            <span className="Appkit4-icon icon-circle-radio-outline openRadioColor" role='img' aria-label='open issue'></span>
          ) : (
            <span className="Appkit4-icon icon-circle-checkmark-outline closedCheckmarkColor" role='img' aria-label='closed issue'></span>
          ),
      };
  };

  const onReopenIssueModalTextChange = (value: string, event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setReopenModalCommentVal(value);
  };

  const v2SetManualStatusRef = useRef<any>();

  const issueActionHandler = () => {
    setOpenOrCloseBtnLoading(true);
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    const issueType = issue?.issueType;
    let param: any;
    if (issueType === 'open') {
      param = {
        productId: productId,
        number: issue?.acNumber,
        status: 0,
        comment: reopenModalCommentVal,
      };
    } else {
      param = {
        productId: productId,
        number: issue?.acNumber,
        status: 1,
        comment: reopenModalCommentVal,
      };
    }
    v2SetManualStatusRef.current = productService
      .v2SetManualStatus(param)
      .pipe(take(1))
      .subscribe((res) => {
        setOpenOrCloseBtnLoading(false);
        // setReopenModalVisible(false);
        // window.location.reload();
        props.reloadPage();
        setTimeout(() => {
          toaster.notify(
            <Notification
              title=""
              message={`The issue has been successfully ${issueType === 'close' ? 'reopened' : 'closed'}.`}
              status="success"
            />,
            { position: 'bottomLeft', duration: 5000 },
          );
        }, 500);
      });
  };

  useEffect(() => {
    return () => {
      v2SetManualStatusRef?.current?.unsubscribe();
      pinIssueRef?.current?.unsubscribe();
    };
  }, []);

  const reopenModalInfo = {
    title: issue.issueType === 'open' ? 'Close issue' : 'Reopen issue',
    body: (
      <div className="modalBody">
        <span className="modalDescription">
          Please provide a brief explanation if necessary for {issue.issueType === 'open' ? 'closing' : 'reopening'} the
          issue.
        </span>
        <div className="textAreaPart">
          <TextArea
            title="Comment"
            // maxLength={420}
            hideTitleOnInput={true}
            value={reopenModalCommentVal}
            onChange={onReopenIssueModalTextChange}
          ></TextArea>
        </div>
      </div>
    ),
    footer: (
      <>
        <Button onClick={() => setReopenModalVisible(false)} kind="secondary">
          Cancel
        </Button>
        <Button
          loading={openOrCloseBtnLoading}
          onClick={() => {
            issueActionHandler();
          }}
        >
          {issue.issueType === 'open' ? 'Close issue' : 'Reopen issue'}
        </Button>
      </>
    ),
  };

  const goToIssueDetail = () => {
    navigate(`/v2/dwrapper/issuedetail?appId=${_appId}&id=${issue.acNumber}`, {
      state: {
        issue,
        curIssueTypeTabIdx,
      },
    });
  };

  const handleKeydownIssueContainer = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      goToIssueDetail();
    }
  };

  const handleCancelDelete = () => {
    setVisibleDeleteConfirmation(false);
  }

  const handleDelete = (event: any) => {
    onDeleteIssue?.();
    setVisibleDeleteConfirmation(false);
  }

  return (
    <>
      <div
        className="issueDashboardContainer"
        onClick={goToIssueDetail}
        tabIndex={0}
        onKeyDown={handleKeydownIssueContainer}
        role='link'
        aria-label={issue.issueLabel}
      >
        <div className="isdRadioArea">
          <Tooltip
            trigger="hover"
            position="top"
            distance={4}
            id="tooltipDesc"
            appendAfterTarget={true}
            hideTooltipOnBlur={true}
            content={getTooltipContent()?.content}
          >
            {getTooltipContent()?.icon}
          </Tooltip>
        </div>
        <div className="sectionWrapper-container">
        <div className="textArea badge-wrapper ap-mb-spacing-2">
          {issue.auditType === 'auto'? <IconBadge value={'Automated scan'} icon="icon-analysis-outline" type={'info'} /> : <IconBadge value={'Manual review'} icon="icon-survey-checkmark-outline" type={'info'} />}
        </div>
        <div className="sectionWrapper">
        <div className="isdTextArea">
          <div className="textArea">
            <div className="issueLabel inner-label">{issue.issueLabel}</div>
          </div>
          <div className="imgArea" title={issue?.issueSrc}>
            {issue?.issueSrc==='No description provided'? <em className="no-description">{issue?.issueSrc}</em>:issue?.issueSrc}
          </div>
          <div className="acNumberArea">{issue.acNumber}</div>
        </div>
            <div className="tagPart">
              <div className="tagSingle">
                <Badge value={issue.issueLevel} size="small" type={getBadgeType()} className={getTagLevelClsName()} />
              </div>
              {issue.effortLevels?.map((level: string, idx) => (
                <div className="tagSingle" key={`${idx}-${level}`}>
                  <Badge value={level} type={'info'} size="small" className="issueCard" />
                </div>
              ))}
            </div>
        </div>
        </div>
        <div className={classNames("isdThreeDotsArea", { "manual-last-one": isManualLastOne })}>
          <DropdownButton
            ref={threeDropdownBtnRef}
            customTriggerNode={true}
            customTriggerClassName="custom-node"
            dropdownClassName="dpdStyle"
            data={getDropDownContent()}
            onSelect={dropdownSelecthandler}
            prefixTemplate={(item) => <span className={`Appkit4-icon ${item.iconName}`}></span>}
          >
            <span className="Appkit4-icon icon-horizontal-more-outline"></span>
          </DropdownButton>
        </div>
      </div>
      {reopenModalVisible && (
        <CommonModal
          modalContent={reopenModalInfo}
          visible={reopenModalVisible}
          setVisibleFunc={setReopenModalVisible}
        />
      )}
      <div className='manual-page-wrapper'>
        <Manualpage ref={manualPageRef} forShowingEditModal={true}></Manualpage>
      </div>
      <DeleteIssueConfirmation visibleDeleteConfirmation={visibleDeleteConfirmation} setVisibleDeleteConfirmation={setVisibleDeleteConfirmation} handleCancelDelete={handleCancelDelete} handleDelete={handleDelete}></DeleteIssueConfirmation>
    </>
  );
});

export default IssueDashboard;
