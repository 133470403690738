import React, { FC, memo, useMemo, useContext } from 'react';
import './conformancebar.scss';
import { IConformanceBarType } from './types';
import good from '../../assets/icons/good.svg';
import darkGood from '../../assets/icons/dark-good.svg';
import { EntryContext } from '@layout/Entry';

const ConformanceBar: FC<IConformanceBarType> = memo((props) => {
  const { isDarkMode } = useContext(EntryContext);
  const {
    minimumRequiredPercent,
    score,
    barWidth,
    backgroundColorOuter = '#d1d1d1',
    backgroundColorInner = 'red',
    outerHeight = '8px',
  } = props;

  const scoreGap = useMemo(() => {
    if (score > 0) {
      const tempScore = Number(score);
      if (tempScore === 100) {
        return `Congratulations! You have achieved full compliance.`;
      }
      if (tempScore >= 85) {
        return `${100 - Number(score)}% to achieve full compliance.`;
      }

      if (tempScore < 85) {
        return `${85 - Number(score)}% away from the minimum required.`;
      }
    }
    return `${minimumRequiredPercent - 0}% away from the minimum required.`;
  }, [score]);

  const imgSrc = useMemo(() => {
    return isDarkMode ? darkGood : good;
  }, [isDarkMode]);

  return (
    <div className={'conformancebar-container'}>
      <div className={'score-area'}>
        <span>{score ? score : 0}%</span>
        <div className={'score-desc'}>{scoreGap}</div>
      </div>
      <div className={'bar-area'}>
        <div className={'loading-area'}>
          <div
            className={'loading-bar-outer'}
            style={{
              backgroundColor: backgroundColorOuter,
              height: outerHeight,
            }}
          >
            <div
              className={'loading-bar-inner'}
              style={{
                width: `calc(100% * ${score}/100)`,
                backgroundColor: backgroundColorInner,
                height: outerHeight,
              }}
            ></div>
          </div>
          <img
            src={imgSrc}
            style={{ left: `calc(100% * ${minimumRequiredPercent - 0.4}/100)` }}
            alt="minimum required logo"
          />
        </div>
        <div className={'percent-number-area'}>
          <span>0%</span>
          <span
            style={{
              position: 'absolute',
              left: `calc(100% * ${minimumRequiredPercent}/100 - 13px)`,
            }}
          >
            85%
          </span>
          <span>100%</span>
        </div>
      </div>
      <div className={'lengend-area'} style={{ width: '100%' }}>
        <div className={'your-conformance'}>
          <div style={{ backgroundColor: backgroundColorInner }}></div>
          <span>Your conformance ({score ? score : 0}%)</span>
        </div>
        <div className={'minimum-required'}>
          <img src={imgSrc} alt="minimum required logo" />
          <span>Minimum required ({minimumRequiredPercent}%)</span>
        </div>
        <div className={'end-goal'}>
          <div style={{ backgroundColor: Number(score) === 100 ? backgroundColorInner : backgroundColorOuter }}></div>
          <span>End goal (100%)</span>
        </div>
      </div>
    </div>
  );
});

export default ConformanceBar;
