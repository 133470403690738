import React, { useEffect, useMemo, useRef, useState, useCallback, useContext } from 'react';
import './index.scss';
import { Button } from '@appkit4/react-components';
import { Search } from '@appkit4/react-components/search';
import { Input } from '@appkit4/react-components/field';
import ProjectDashboard from './ProjectDashboard';
import FooterPage from '@components/Footer';
import NoProductsSvg from '@assets/icons/no-products.svg';
import NoResultsSvg from '@assets/icons/noSearchResults.svg';
import CommonModal from '@components/modals/CommonModal';
import { Subscription, of } from 'rxjs';
import { catchError, concatMap, take, retry, mergeMap, delay } from 'rxjs/operators';
import { userService } from '@services';
import _ from 'lodash';
import SelectedUser from './SelectedUser.component';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { Switch } from '@appkit4/react-components/switch';
import { axios } from '@services/axios.service';
import LoadingComponent from '@components/Loading';
import { EntryContext } from '@layout/Entry';
import { productService } from '@services';
import AddProductModal from '@components/modals/AddProductModal';
import { useNavigate } from 'react-router-dom';
import { Notification, toaster } from '@appkit4/react-components/notification';
import { encryptData, setCookie, setProductInfoCookie } from '@utils/common';

export const NoResultsPage = (props: any) => {
  const { imgSrc, imgAlt, titleArea, descArea, btnArea } = props;
  return (
    <div className="home-projectContent-noProducts">
      <div className="home-projectContent-noProducts-imgArea">
        <img src={imgSrc} alt={imgAlt} />
      </div>
      <h2 className="home-projectContent-noProducts-titleArea">{titleArea}</h2>
      <div className="home-projectContent-noProducts-descArea">{descArea}</div>
      {btnArea ? <div className="home-projectContent-noProducts-btnArea">{btnArea}</div> : null}
    </div>
  );
};

const Home = (props: any) => {
  const { setScanModalFrom, setMessageResult } = useContext(EntryContext);
  const navigate = useNavigate();
  const { productList } = props;
  const [showAddProductModall, setShowAddProductModal] = React.useState(false);
  const [messageTogglePrivacy, setMessageTogglePrivacy] = useState('');
  const [userSearchList, setUserSearchList] = React.useState<any[]>([]);
  const [searchedProductList, setSearchedProductList] = React.useState<any[]>([]);
  const [showHomeLoading, setShowHomeLoading] = React.useState(false);
  const { user } = React.useContext(EntryContext);

  const [userInputVal, setUserInputVal] = React.useState('');

  const [finalProductList, setFinalProductList] = React.useState<any>(productList);

  const onChange = (value: string, event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>) => {};

  const [formProductNameInputvalue, setFormProductNameInputvalue] = React.useState('');
  const [formPrivateVal, setFormPrivateVal] = React.useState(false);
  const [formProductNameInputError, setFormProductNameInputError] = React.useState(false);
  const [searchInputValue, setSearchInputValue] = React.useState('');
  const [selectedUsers, setSelectedUsers] = React.useState<any[]>([]);

  const searchUserRef = React.useRef<any>(null);
  const searchProductRef = React.useRef<any>(null);
  const addProductRef = React.useRef<Subscription>();
  const handlerSearchRef = useRef<any>();
  const inputRef = useRef<HTMLInputElement>(null);

  const [addProductBtnLoading, setAddProductBtnLoading] = useState(false);

  const [productNameInputErroText, setProductNameInputErrorText] = useState('');

  const [searchProductValue, setSearchProductValue] = useState('');

  const onProductNameInputChange = (value: string, event: any) => {
    setFormProductNameInputvalue(value);
    setFormProductNameInputError(false);
    setProductNameInputErrorText('');
    if (value?.length > 50) {
      setFormProductNameInputError(true);
      setProductNameInputErrorText('Maximum 50 character limit exceeded. Please shorten your entry.');
    }
    // todo 1 去校验是否名字重复
    // setFormProductNameInputError(true);
    // todo 2 是否为空 等等
  };

  const searchUserKeydownHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const value = (event?.target as HTMLInputElement).value;
  };

  useEffect(() => {
    const scanModalFromFromLocalStorage = localStorage.getItem('scanModalFrom');
    if (scanModalFromFromLocalStorage) {
      setScanModalFrom && setScanModalFrom(scanModalFromFromLocalStorage);
    }
  }, [setScanModalFrom]);

  React.useEffect(() => {
    return () => {
      if (searchUserRef.current) {
        searchUserRef.current.unsubscribe();
      }
      if (searchProductRef.current) {
        searchProductRef.current.unsubscribe();
      }
      if (addProductRef.current) {
        addProductRef.current.unsubscribe();
      }
    };
  });

  useEffect(() => {
    if (formProductNameInputError && inputRef.current) {
      inputRef.current.focus();
    }
  }, [formProductNameInputError]);

  const onPrivateToggleChange = (checked: boolean, event: React.SyntheticEvent) => {
    setFormPrivateVal((prev) => !prev);
  };

  useEffect(() => {
    setTimeout(() => {
      setMessageTogglePrivacy(formPrivateVal? 'Privacy set as private. Only team members can access and manage this application.'
         : 'Privacy set as public. Anyone can access and manage this application.');
      // Optionally, clear the message after a short delay to ensure it can be announced again
      setTimeout(() => setMessageTogglePrivacy(''), 200);
    }, 200);
  }, [formPrivateVal])

  const handleSelect = (value: string, item: any, event: React.SyntheticEvent) => {
    let _res: any[] = [];
    const email = item?.email;
    if (selectedUsers?.length === 0) {
      _res =
        email === user?.id
          ? [item]
          : [
              {
                guid: user?.guid,
                email: user?.id,
                formattedName: user?.formattedName,
                firstName: user?.firstName,
                lastName: user?.lastName,
              },
              item,
            ];
    } else {
      const filterRes = selectedUsers.filter((usr) => usr?.email === email);
      _res = filterRes.length === 0 ? [...selectedUsers, item] : [...selectedUsers];
    }

    setSearchInputValue('');

    setSelectedUsers(_res);
  };

  const onAddTeamerSearchChange = async (
    value: string,
    event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (!value) return;

    const res: any = await axios.post(`/user/search`, {
      page: 1,
      size: 10,
      query: value,
      type: 'FULL',
    });

    setUserSearchList(
      res.map((item: any) => ({
        ...item,
        value: item?.email,
        label: item?.formattedName,
      })),
    );
    if (res?.length > 0)
      setMessageResult?.(`${res?.length} results found`);
    else
      setMessageResult?.('No results found');
  };

  const disabledAddProductBtn = () => {
    if (!formProductNameInputvalue) {
      return true;
    }
    if (productNameInputErroText?.length > 50) {
      return true;
    }
    const res = selectedUsers?.filter((ele) => !ele.role);
    return res?.length > 0;
  };

  const getLatestProductInfoRef = useRef<Subscription>();

  const addProductBtnClick = async () => {
    try {
      setAddProductBtnLoading(true);
      const productInfo = {
        name: formProductNameInputvalue,
        isPrivate: formPrivateVal === true ? 1 : -1,
        members: selectedUsers.map((usr) => ({ ...usr, role: usr?.email === user?.id ? 'owner' : usr?.role })),
      };
      const res: any = await axios.post(`/product/v2/create`, productInfo);
      if (res?.isNameExist === 1) {
        setFormProductNameInputError(true);
        setProductNameInputErrorText(
          'Application name already exists. Check if this application already exists or use a different one.',
        );
        setAddProductBtnLoading(false);
      } else {
        setShowAddProductModal(false);
        // setShowHomeLoading(true);
        // setAddProductBtnLoading(false);

        setFormProductNameInputError(false);
        setFormProductNameInputvalue('');
        setFormPrivateVal(false);
        setSearchInputValue('');

        toaster.notify(<Notification title="" message="Application added successfully." status="success" />, {
          position: 'bottomLeft',
          duration: 2000,
          onClose: () => {},
        });

        const res2: any = await axios.get(`/product/info?appId=${res?.appId}`);
        if (res2) {
          setAddProductBtnLoading(false);
          // setCookie(
          //   'productInfo',
          //   JSON.stringify({
          //     productName: res2.name,
          //     lastScanDate: '',
          //     productId: res2.id,
          //   }),
          // );
          setProductInfoCookie({
            productName: res2.name,
            lastScanDate: '',
            productId: res2.id,
            name: res2.name,
            id: res2.id
          });

          navigate(`/v2/dwrapper/board?appId=${res.appId}`);
        }

        // const latestProjects: any = await axios.get(`/product/info/getMemberShips`);
        // setFinalProductList(latestProjects.memberships.map((project: any) => ({ ...project, isAccessible: true })));
        // setShowHomeLoading(false);
      }
    } catch (error) {
      console.log(`/product/v2/create error: ${error}`);
      setShowHomeLoading(false);
      setAddProductBtnLoading(false);
    }
  };

  const getModalContent = {
    title: 'Add application',
    body: (
      <div className="home-modalBody">
        <div className="home-modalBody-productName">
          <Input
            inputRef={inputRef}
            type={'text'}
            title={'Application name'}
            allowClear={true}
            required={true}
            onClick={() => {}}
            onClear={() => {
              setFormProductNameInputError(false);
              setProductNameInputErrorText('');
              setFormProductNameInputvalue('');
            }}
            value={formProductNameInputvalue}
            onChange={onProductNameInputChange}
            error={formProductNameInputError}
            aria-describedby={formProductNameInputError ? 'productNameError' : undefined}
            errorNode={formProductNameInputError && (
              <span
                id='productNameError'
                className="home-modalBody-productName-inputError"
                role='alert'
                aria-live='polite'
              >{productNameInputErroText}</span>
            )}
          ></Input>
        </div>
        <div className="home-modalBody-privacyPart">
          <div className="home-modalBody-privacyPart-title">Privacy</div>
          <div className="home-modalBody-privacyPart-toggle">
            <Switch
              className="home-modalBody-privacyPart-toggle-switch"
              checked={formPrivateVal}
              onChange={onPrivateToggleChange}
            >
              Set as private
            </Switch>
          </div>
          <div className="home-modalBody-privacyPart-desc">
            <span className="Appkit4-icon icon-circle-warning-outline"></span>
            {formPrivateVal === false ? (
              <span>Anyone can access and manage this application.</span>
            ) : (
              <span>Only team members can access and manage this application.</span>
            )}
          </div>
        </div>
        <div className="home-modalBody-addTeamerPart">
          <div className="home-modalBody-addTeamerPart-label">
            <span>Add team members</span>
          </div>
          <div className="home-modalBody-addTeamerPart-search">
            <div className="home-modalBody-addTeamerPart-search-container">
              <Search
                data={userSearchList}
                searchType={'secondary'}
                defaultValue={searchInputValue}
                onSelect={handleSelect}
                onChange={onAddTeamerSearchChange}
                placeholder="Search name"
                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => searchUserKeydownHandler(event)}
              />
            </div>
            {/* <div className="home-modalBody-addTeamerPart-search-divider"></div> */}
            <div className="home-modalBody-addTeamerPart-search-contentContainer">
              {selectedUsers?.map((usr: any, idx: number) => (
                <SelectedUser
                  key={`${idx}-selectedUserPart`}
                  user={usr}
                  setSelectedUsers={setSelectedUsers}
                  selectedUsers={selectedUsers}
                  origin={'home'}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    ),
    footer: (
      <div className="home-modalFooter">
        <div className="home-modalFooter-descArea">
          <span>Required Fields</span>
        </div>
        <div className="home-modalFooter-btnArea">
          <Button onClick={() => setShowAddProductModal(false)} kind="text">
            Cancel
          </Button>
          <Button loading={addProductBtnLoading} disabled={disabledAddProductBtn()} onClick={addProductBtnClick}>
            Add
          </Button>
          <div
          aria-live="polite"
          aria-atomic="true"
          className="sr-only"
        >
          {messageTogglePrivacy}
        </div>
        </div>
      </div>
    ),
  };

  const getIsSearchedProductAccessible = (product: any) => {
    if (user?.isAdmin === 1) {
      return true;
    } else {
      if (finalProductList?.some((p: any) => p?.id === product?.id)) {
        return true;
      }
      if (product?.isPrivate !== 1) {
        return true;
      }
    }
  };

  const handleSearch = async (value: string, event: any) => {
    try {
      setShowHomeLoading(true);
      setUserInputVal(value);

      let res: any;
      if (event?.target?.value) {
        res = await axios.post(`/product/list`, { keyword: event?.target?.value });
      } else {
        res = await axios.get(`/product/info/getMemberShips`);
      }

      const finalRes = res?.productList || res?.memberships;

      setSearchedProductList(
        finalRes.map((product: any) => ({
          ...product,
          isAccessible: getIsSearchedProductAccessible(product),
        })),
      );
      if (finalRes?.length > 0)
        setMessageResult?.(`${finalRes?.length} results found`);
      else
        setMessageResult?.('No results found');
      setShowHomeLoading(false);
    } catch (error) {
      console.log(`handle application search error: ${error}`);
      setShowHomeLoading(false);
    }
  };

  const productSearchKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event?.code.toLowerCase() === 'enter') {
      const val = (event.target as HTMLInputElement)?.value;
      // handleSearch(val);
    }
  };

  const openAddProductModal = () => {
    setSelectedUsers([
      {
        guid: user?.guid,
        email: user?.id,
        formattedName: user?.formattedName,
        firstName: user?.firstName,
        lastName: user?.lastName,
        role: 'owner',
      },
    ]);
    setSearchInputValue('');
    setShowAddProductModal(true);
    setFormProductNameInputvalue('');
    setFormPrivateVal(true);
    setFormProductNameInputError(false);
    setFormProductNameInputvalue('');
  };

  const projectContentElement = React.useMemo(() => {
    if (userInputVal) {
      return searchedProductList.length > 0 ? (
        searchedProductList?.map((product: any, idx: number) => <ProjectDashboard key={`${idx}`} product={product} />)
      ) : (
        <NoResultsPage
          imgSrc={NoResultsSvg}
          imgAlt="no results found logo"
          titleArea="No results found."
          descArea={`Try a different application name.`}
        />
      );
    } else {
      return finalProductList.length > 0 ? (
        finalProductList?.map((product: any, idx: number) => <ProjectDashboard key={`${idx}`} product={product} />)
      ) : (
        <NoResultsPage
          imgSrc={NoProductsSvg}
          imgAlt="no applications logo"
          titleArea="You have no applications."
          descArea={`Begin your team's journey to accessibility. To add your first application, select "Add application".`}
        />
      );
    }
  }, [finalProductList, userInputVal, searchedProductList]);

  handlerSearchRef.current = handleSearch;

  const _fetchProducts = useCallback(
    _.debounce((value, event) => handlerSearchRef.current(value, event), 1500),
    [],
  );

  const onSearchProductChange = (value: string, event: React.SyntheticEvent | React.ChangeEvent<HTMLInputElement>) => {
    setShowHomeLoading(true);
    setSearchProductValue(value);
    _fetchProducts(value, event);
  };

  return (
    <>
      <div className="ap-container home" tabIndex={-1}>
        <div className={`home-content ${(searchedProductList.length === 0 && searchProductValue !== '')? 'noSearchedProducts': ''}`}>
          <div className='home-content-body'>
            <div className="row">
              <div className="home-top">
                <div className="col-8">
                  <div className="home-top-labelTextArea">
                    <h1>
                      <span>Welcome to </span><span>Accessibility Checker</span>
                    </h1>
                    <span className="home-top-labelTextArea-span">
                      Your one-stop shop to enhance your application's accessibility compliance.
                    </span>
                  </div>
                </div>
                <div className="col-4">
                  <div className="home-top-btnArea">
                    <Button kind="primary" icon="icon-plus-outline" onClick={openAddProductModal}>
                      Add application
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="home-center">
                <div className="col-4">
                  <div className="home-center-search">
                    <Search
                      onChange={onSearchProductChange}
                      // onKeyDown={productSearchKeyDown}
                      searchType={'secondary'}
                      placeholder="Search all applications"
                      searchValue={searchProductValue}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className={`home-projectContent ${finalProductList?.length === 0 ? 'noProductsCls' : ''} ${(searchedProductList.length === 0 && searchProductValue !== '')? 'noSearchedProducts': ''}`}>
                {showHomeLoading ? <LoadingComponent className={'home-projectContent-loading'} /> : projectContentElement}
              </div>
            </div>
          </div>
          <div className='home-content-footer'>
            <div className="row">
              <div className="home-footer ap-mt-spacing-7">
                <FooterPage />
              </div>
            </div>
          </div>
        </div>
      </div>
      <CommonModal
        visible={showAddProductModall}
        setVisibleFunc={setShowAddProductModal}
        modalContent={getModalContent}
        footStyle={{ width: '100%' }}
        className="home-addProductModal"
        bodyStyle={{ minHeight: '400px' }}
      />
    </>
  );
};

export default Home;
