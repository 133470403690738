import React, { useState, useRef, useEffect, useMemo, useContext } from 'react';
import './index.scss';
import { Modal } from '@appkit4/react-components/modal';
import { Button } from '@appkit4/react-components/button';
import { Tooltip } from '@appkit4/react-components/tooltip';
import { Switch } from '@appkit4/react-components/switch';
import { Select, Badge } from '@appkit4/react-components';
import { Subscription, of, zip } from 'rxjs';
import { switchMap, concatMap, filter } from 'rxjs/operators';
import { productService } from '@services';
import { debounceTime, distinctUntilChanged, map, take } from 'rxjs/operators';
import moment from 'moment';
import { decryptData, getCookie, getProductInfoCookie, safelyParseJSON } from '@utils/common';
import { EntryContext } from '@layout/Entry';

const EditOtherSettingsModal = (props: any) => {
  const { visible = true, setVisibleFunc, bodyStyle = {}, currentProductInfo, setNeedRefreshFrom, uuid } = props;
  const [messageToggleScheduleVal, setMessageToggleScheduleVal] = useState('');
  const [messageToggleSearchForVal, setMessageToggleSearchForVal] = useState('');
  const [frequencySelectShowVal, setFrequencySelectShowVal] = useState('1');
  const frequencySelectShowValRef = useRef('1');
  const [toggleScheduleVal, setToggleScheduleVal] = React.useState(false);
  const [toggleSearchForVal, setToggleSearchForVal] = React.useState(false);
  const [saveChangesLoading, setSaveChangesLoading] = React.useState(false);
  const toggleScheduleValRef = useRef(false);
  const toggleSearchForValRef = useRef(false);
  const [scheduledDate, setScheduledDate] = React.useState('');

  const v2OtherSettingsSaveChangesRef = useRef<Subscription>();
  const updateScheduleRef = useRef<Subscription>();

  const frequencySelectData = [
    {
      label: 'Weekly on monday',
      value: '1',
    },
    {
      label: 'Bi-weekly on monday',
      value: '2',
    },
    {
      label: 'The first day of a month',
      value: '3',
    },
  ];

  const getNextScheduleDate = (createAt: string, frequency: number): string => {
    if (createAt !== '' && [1, 2, 3].includes(frequency)) {
      const config: { [x: number]: any } = {
        1: [7, 'days', 'isoweek'],
        2: [14, 'days', 'isoweek'],
        3: [1, 'months', 'months'],
      };

      let nextDate = createAt;
      const today = moment().utc().format('YYYY-MM-DD');

      while (moment(nextDate).format('YYYY-MM-DD') <= today) {
        const date = moment(nextDate)
          .add(config[frequency][0], config[frequency][1])
          .startOf(config[frequency][2])
          .format();
        nextDate = moment.utc(date).format();
      }

      return nextDate;
    }

    return '';
  };

  const saveChangesHandler = () => {
    setSaveChangesLoading(true);
    const productId = (getProductInfoCookie()&&safelyParseJSON(getProductInfoCookie()!)?.id)||sessionStorage.getItem('productId');
    const param = {
      productId: String(productId),
      enable: toggleScheduleVal ? 1 : -1,
      frequency: toggleScheduleVal ? Number(frequencySelectShowVal) : -1,
      isCrawl: toggleSearchForVal ? 1 : 0,
    };
    v2OtherSettingsSaveChangesRef.current = productService
      .v2OtherSettingsSaveChanges(param)
      .pipe(take(1))
      .subscribe((res) => {
        if (res?.status?.toLowerCase() === 'success') {
          setSaveChangesLoading(false);
          setVisibleFunc(false);
          setNeedRefreshFrom('editOtherSettingModal');
        }
      });
  };

  useEffect(() => {
    if(toggleScheduleVal) {
      setTimeout(() => {
        setMessageToggleScheduleVal(`Next scan will be on ${moment(scheduledDate).format('LL')}`);
        setMessageToggleSearchForVal('');
        // Optionally, clear the message after a short delay to ensure it can be announced again
        setTimeout(() => setMessageToggleScheduleVal(''), 200);
      }, 200);
    }
  }, [toggleScheduleVal]);
  useEffect(() => {
    if(toggleSearchForVal) {
      setTimeout(() => {
        setMessageToggleSearchForVal('Up to three pages will be searched for each page added');
        setMessageToggleScheduleVal('');
        // Optionally, clear the message after a short delay to ensure it can be announced again
        setTimeout(() => setMessageToggleSearchForVal(''), 200);
      }, 200);
    }
  }, [toggleSearchForVal]);

  useEffect(() => {
    if (visible && currentProductInfo) {
      setToggleScheduleVal(
        currentProductInfo?.scheduleEnable === -1 || !currentProductInfo?.scheduleEnable ? false : true,
      );
      toggleScheduleValRef.current = currentProductInfo?.scheduleEnable === -1 || !currentProductInfo?.scheduleEnable ? false : true;

      if (currentProductInfo?.scheduleDate) {
        setScheduledDate(moment(currentProductInfo?.scheduleDate).format('LL'));
      } else {
        setScheduledDate(getNextScheduleDate(moment().format('YYYY-MM-DD'), 1));
      }

      if (currentProductInfo?.scheduleFrequency) {
        setFrequencySelectShowVal(currentProductInfo?.scheduleFrequency.toString());
        frequencySelectShowValRef.current = currentProductInfo?.scheduleFrequency.toString();
      }
      setToggleSearchForVal(currentProductInfo?.crawlStatus === 1 ? true : false);
      toggleSearchForValRef.current = currentProductInfo?.crawlStatus === 1 ? true : false;
    }

    return () => {
      updateScheduleRef?.current?.unsubscribe();
      v2OtherSettingsSaveChangesRef?.current?.unsubscribe();
    };
  }, [currentProductInfo, uuid]);

  const onFrequencySelectChange = (val: any) => {
    setFrequencySelectShowVal(val);
    const res = getNextScheduleDate(moment().format('YYYY-MM-DD'), Number(val));
    setScheduledDate(res);
  };

  const onToggleScheduleChange = (checked: boolean, event: React.SyntheticEvent) => {
    setToggleScheduleVal((prev) => !prev);
  };
  const onToggleSearchForChange = (checked: boolean, event: React.SyntheticEvent) => {
    setToggleSearchForVal((prev) => !prev);
  };

  const modalContent = {
    title: 'Other settings',
    footer: (
      <>
        <Button
          kind="secondary"
          onClick={() => {
            setVisibleFunc(false);
          }}
        >
          Cancel
        </Button>{' '}
        <Button loading={saveChangesLoading} kind="primary" onClick={() => saveChangesHandler()}
          disabled={toggleScheduleValRef.current===toggleScheduleVal
            &&frequencySelectShowValRef.current===frequencySelectShowVal&&toggleSearchForValRef.current===toggleSearchForVal  
          }
          >
          Save changes
        </Button>
      </>
    ),
    body: (
      <div className="editOtherSettingsModal-body">
        <div className="editOtherSettingsModal-body-autoScansPart">
          <div className={'editOtherSettingsModal-body-autoScansPart-tooltip'}>
            <h3>Automatic scans</h3>
            <Tooltip
              trigger="hover"
              position="top"
              distance={4}
              appendAfterTarget={true}
              hideTooltipOnBlur={true}
              content="To generate automatic regular accessibility reports based on the selected frequency."
            >
              <span
                tabIndex={0}
                role="tooltip"
                aria-label='Info icon To generate automatic regular accessibility reports based on the selected frequency.'
                className={`Appkit4-icon icon-information-fill appkitIcon-color`}
              ></span>
            </Tooltip>
          </div>

          <div className={'editOtherSettingsModal-body-autoScansPart-toggle'}>
            <Switch checked={toggleScheduleVal} onChange={onToggleScheduleChange}>
              Schedule automatic scans
            </Switch>
          </div>

          {toggleScheduleVal ? (
            <>
              <div className={'editOtherSettingsModal-body-autoScansPart-selectPart'}>
                <Select
                  data={frequencySelectData}
                  value={frequencySelectShowVal}
                  searchable={false}
                  defaultValue={frequencySelectShowVal}
                  placeholder=""
                  hideTitleOnInput={true}
                  dropdownAlwaysDown={false}
                  dropdownRenderMode="portal"
                  disabled={!toggleScheduleVal}
                  onSelect={onFrequencySelectChange}
                ></Select>
              </div>

              <div className={'editOtherSettingsModal-body-autoScansPart-nextScanTime'}>
                <div className={'editOtherSettingsModal-body-autoScansPart-nextScanTime-issue'}>
                  <span className={'recomDescContainer'}>
                    <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
                    {scheduledDate && <span>Next scan will be on {moment(scheduledDate).format('LL')}.</span>}
                  </span>
                </div>
              </div>
            </>
          ) : null}
        </div>
        <div className="editOtherSettingsModal-body-autoSearchpart">
          <div className={'editOtherSettingsModal-body-autoSearchpart-tooltip'}>
            <h3>Automatic search of pages</h3>
            <Tooltip
              trigger="hover"
              position="top"
              distance={4}
              appendAfterTarget={true}
              hideTooltipOnBlur={true}
              content="Enable our scanner to search for additional pages, ideal for teams monitoring ongoing accessibility compliance."
            >
              <span
                tabIndex={0}
                role="tooltip"
                aria-label='Info icon Enable our scanner to search for additional pages, ideal for teams monitoring ongoing accessibility compliance.'
                className={`Appkit4-icon icon-information-fill appkitIcon-color`}
              ></span>
            </Tooltip>
          </div>

          <div className={'editOtherSettingsModal-body-autoSearchpart-toggle'}>
            <Switch checked={toggleSearchForVal} onChange={onToggleSearchForChange}>
              Search for additional pages
            </Switch>
          </div>

          {toggleSearchForVal ? (
            <div className={'editOtherSettingsModal-body-autoSearchpart-nextScanTime'}>
              <div className={'editOtherSettingsModal-body-autoScansPart-nextScanTime-issue'}>
                <span className={'recomDescContainer'}>
                  <span className="Appkit4-icon icon-information-outline recoDescIcon"></span>
                  <span>Up to three pages will be searched for each page added.</span>
                </span>
              </div>
            </div>
          ) : null}
        </div>
        {toggleScheduleVal && <div
          aria-live="polite"
          aria-atomic="true"
          className="sr-only"
        >
          {messageToggleScheduleVal}
        </div>}
        {toggleSearchForVal && <div
          aria-live="polite"
          aria-atomic="true"
          className="sr-only"
        >
          {messageToggleSearchForVal}
        </div>}
      </div>
    ),
  };

  return (
    <Modal
      visible={visible}
      title={modalContent.title}
      ariaLabel={modalContent.title}
      onCancel={() => setVisibleFunc(false)}
      header={''}
      icons={''}
      footer={modalContent.footer}
      bodyStyle={Object.assign({ minHeight: '92px' }, bodyStyle)}
      className="editOtherSettingsModal"
      initialFocus={false}
    >
      {modalContent.body}
    </Modal>
  );
  // return (
  //   visible && (
  //     <Modal
  //       visible={visible}
  //       title={modalContent.title}
  //       ariaLabel={modalContent.title}
  //       onCancel={() => setVisibleFunc(false)}
  //       header={''}
  //       icons={''}
  //       footer={modalContent.footer}
  //       bodyStyle={Object.assign({ minHeight: '92px' }, bodyStyle)}
  //       className="editOtherSettingsModal"
  //       // initialFocus={false}
  //     >
  //       {modalContent.body}
  //     </Modal>
  //   )
  // );
};
export default EditOtherSettingsModal;
