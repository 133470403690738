import { Button } from '@appkit4/react-components';
import './issueNotFound.scss';
import imgSrc from '@assets/icons/NotFound.svg';
import { getProductInfoCookie, safelyParseJSON } from '@utils';
import { useNavigate } from 'react-router-dom';
const IssueNotFound = () => {
  const navigate = useNavigate();
  const goToIssues = () => {
    const appId = (getProductInfoCookie() && safelyParseJSON(getProductInfoCookie()!)?.appId);
    navigate(`/v2/dwrapper/issues?appId=${appId}`);
  };
  const onKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      goToIssues();
    }
  };
    return (
      <div className="ap-issue-not-found">
        <div className="ap-issue-not-found-container">
          <img src={imgSrc} alt="Coming Soon" className="ap-issue-not-found-img" />
          <div className="ap-issue-not-found-subject">Issue not found.</div>
          <div className="ap-issue-not-found-description">
          It seems the issue you’re looking for has been deleted or no longer exists.
          </div>
          <div>
            <Button onClick={goToIssues} onKeyDown={onKeyDown} kind="primary">Go to Issues page</Button>
          </div>
        </div>
      </div>
    );
  };
  
  export default IssueNotFound;