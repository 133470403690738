import React, { useEffect, useRef, useState } from 'react';
import { Modal } from '@appkit4/react-components/modal';
import './commonModal.scss';

const CommonModal = (props: any) => {
  const { visible = true, modalContent, setVisibleFunc, className = '', bodyStyle = {}, onClose, maskCloseable=true, onCancel } = props;
  const modalRef = useRef<HTMLDivElement>(null);
  // const [lastInputMethod, setLastInputMethod] = useState<'keyboard' | 'mouse'>('mouse');
  const focusableSelectors = [
    'a[href]',
    'area[href]',
    'input:not([disabled])',
    'select:not([disabled])',
    'textarea:not([disabled])',
    'button:not([disabled])',
    'iframe',
    'object',
    'embed',
    '[tabindex]:not([tabindex="-1"])',
    '[contenteditable]'
  ];

  // const handleEscKey = (event: KeyboardEvent) => {
  //   if (event.key === 'Escape') {
  //     // Add your logic here for when the Esc key is pressed
  //     if(modalRef.current)
  //       setVisibleFunc(false);
  //   }
  // };

  useEffect(() => {
    // Focus the first focusable element in the modal when it is opened for accessibility of close issue and reopen issue
    if (visible === true && (modalContent.title.toLowerCase() === 'close issue' ||
      modalContent.title.toLowerCase() === 'reopen issue')) {
      setTimeout(() => {
        const modalElement = modalRef.current;
        if (modalElement) {
          const focusableElements = modalElement.querySelectorAll(focusableSelectors.join(', '));
          if (focusableElements.length > 0) {
            (focusableElements[0] as HTMLElement)?.focus();
            (focusableElements[0] as HTMLElement).closest('.ap-field-wrapper')?.classList.add('keyboard-focus', 'focus');
          }
        }
      }, 0);
      // const handleKeyUp = () => setLastInputMethod('keyboard');
      // const handleMouseDown = () => setLastInputMethod('mouse');

      // window.addEventListener('keyup', handleKeyUp);
      // window.addEventListener('mousedown', handleMouseDown);

      // return () => {
      //   window.removeEventListener('keyup', handleKeyUp);
      //   window.removeEventListener('mousedown', handleMouseDown);
      // };
    }

    const handleEscKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        setVisibleFunc(false);
        if (onCancel) {
          onCancel();
        } else if (onClose) {
          onClose();
        }
      }
    };

    if(visible) {
      window.addEventListener('keydown', handleEscKeyDown, { capture: true });
      window.addEventListener('keydown', handleTabKey, { capture: true });
    } else {
      window.removeEventListener('keydown', handleEscKeyDown, { capture: true });
      window.removeEventListener('keydown', handleTabKey, { capture: true });
    }
    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleEscKeyDown, { capture: true });
    };
  }, [visible, setVisibleFunc, onCancel, onClose]);

// useEffect(() => {
//   if(lastInputMethod === 'keyboard') {
//     const modalElement = modalRef.current;
//     if (modalElement) {
//       if ((modalContent.title.toLowerCase() === 'close issue' ||
//         modalContent.title.toLowerCase() === 'reopen issue')) {
//         const focusableElements = modalElement.querySelectorAll(focusableSelectors.join(', '));
//         (focusableElements[0] as HTMLElement).closest('.ap-field-wrapper')?.classList.add('keyboard-focus', 'focus');
//       }
//     }
//   }
// }, [lastInputMethod])

  const handleTabKey = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      const modalElement = modalRef.current;
      if (modalElement) {
        const focusableElements = modalElement.querySelectorAll(focusableSelectors.join(', '));
        const firstElement = focusableElements[0] as HTMLElement;
        const lastElement = focusableElements[focusableElements.length - 1] as HTMLElement;

        if (event.shiftKey) {
          // Shift + Tab
          if (document.activeElement === firstElement) {
            event.preventDefault();
            lastElement.focus();
          }
        } else {
          // Tab
          if (document.activeElement === lastElement) {
            event.preventDefault();
            firstElement.focus();
          }
        }
      }
    }
  }
  return (
    <Modal
      visible={visible}
      maskCloseable={maskCloseable}
      title={modalContent.title}
      ariaLabel={modalContent.title}
      onCancel={() => {
        setVisibleFunc(false);
        onCancel?.();
      }}
      modalStyle={{ width: '33.75rem' }}
      // footerStyle={{ paddingTop: '8px', marginTop: '-8px', minHeight: '64px' }}
      header={''}
      icons={''}
      footer={modalContent.footer}
      bodyStyle={Object.assign({ minHeight: '92px' }, bodyStyle)}
      className={className}
      // initialFocus={false}
      onClose={() => {
        onClose && onClose();
      }}
      ref={modalRef}
    >
      {modalContent.body}
    </Modal>
  );
};

export default CommonModal;
