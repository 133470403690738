import { Button, Modal } from "@appkit4/react-components"

export const DeleteIssueConfirmation = ({visibleDeleteConfirmation, setVisibleDeleteConfirmation, handleCancelDelete, handleDelete}: any) => {
    return <Modal
    visible={visibleDeleteConfirmation}
    className='ap-pattern-confirmation-delete'
    title='Delete issue'
    closable
    maskCloseable={false}
    onCancel={handleCancelDelete}
    footer={
      <>
        <Button kind="secondary" onClick={() => setVisibleDeleteConfirmation(false)}>Cancel</Button>
        <Button kind="negative" onClick={handleDelete}>Delete</Button>
      </>
    }
  >
    <div className='ap-pattern-confirmation-message'>
      <p>Are you sure you want to delete this issue?</p>
      <p>This action is irreversible and will remove the selected issue permanently.</p>
    </div>
  </Modal>
}